import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, fade } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
  root: {},
  labelContainer: {
    padding: '5px 0px',
  },
  label: {
    color: 'black',
    fontSize: 17,
    fontWeight: '600',
    fontFamily: 'Source Sans Pro',
  },
  labelSub: {
    color: 'grey',
    fontSize: 15,
    fontFamily: 'Source Sans Pro',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100% !important',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const SpecialRequestInput = ({ onChange, maxValue }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const handleInputChange = (e) => {
    if (maxValue < e.target.value.length) {
      return;
    }
    setValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.labelContainer}
      >
        <Typography className={classes.label}>Special request</Typography>
        <Typography className={classes.labelSub}>Optional</Typography>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <TextareaAutosize
          className={classes.input}
          rowsMin={4}
          onChange={handleInputChange}
          value={value}
          placeholder="e.g. allergies, extra spicy, well done, etc..."
          spellCheck={false}
        />
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.labelContainer}
        >
          <Typography
            className={classes.labelSub}
            style={{ fontStyle: 'italic' }}
          >
            {maxValue - value.length} characters left
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpecialRequestInput;
