import React, { useState, forwardRef, useCallback } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';

// Material Icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// Redux
import { useSelector } from 'react-redux';

// Store
import screenGlobal from '../../../store/global';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: '#212121',
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
    color: 'white',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    // marginLeft: 'auto'
  },
  expand: {
    color: 'white',
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  contentTypography: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
  },
  modifiedTypography: {
    fontSize: 13,
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    textDecoration: 'line-through',
    textDecorationColor: 'red',
    color: '#8e8e8e',
  },
}));

const SnackMessage = forwardRef((props, ref) => {
  // Classes
  const classes = useStyles();

  // Notification State
  const notifications = useSelector(screenGlobal.selectors.getNotifications);

  // Current Norification
  const notification = notifications.filter((obj) => obj.key === props.id);

  const { contentKey, expanded: isDefaultExpanded, payload } = notification[0];

  // SnackBar Hooks
  const { closeSnackbar } = useSnackbar();

  // Expanded State
  const [expanded, setExpanded] = useState(isDefaultExpanded || false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  switch (contentKey) {
    case 'inactive-items-removed':
      return (
        <SnackbarContent ref={ref} className={classes.root}>
          <Card className={classes.card}>
            <CardActions classes={{ root: classes.actionRoot }}>
              <Grid xs={8} container>
                <Typography variant="subtitle2" className={classes.typography}>
                  {props.message}
                </Typography>
              </Grid>
              <Grid
                xs={4}
                container
                direction="row"
                justify="flex-end"
                className={classes.icons}
              >
                <Grid item container xs={4} direction="row">
                  <IconButton
                    aria-label="Show more"
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid>
                <Grid item container xs={4} direction="row">
                  <IconButton
                    className={classes.expand}
                    onClick={handleDismiss}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Paper className={classes.collapse}>
                {payload.map((obj) => {
                  const {
                    menuItem: {
                      item: {
                        name,
                        priceMonetaryFields: { displayString },
                      },
                    },
                  } = obj;
                  return (
                    <Grid container direction="row" justify="space-between">
                      <Typography
                        className={classes.contentTypography}
                        gutterBottom
                      >
                        {name}
                      </Typography>
                      <Typography
                        className={classes.contentTypography}
                        gutterBottom
                      >
                        ${displayString}
                      </Typography>
                    </Grid>
                  );
                })}
              </Paper>
            </Collapse>
          </Card>
        </SnackbarContent>
      );

    case 'items-modified':
      return (
        <SnackbarContent ref={ref} className={classes.root}>
          <Card className={classes.card}>
            <CardActions classes={{ root: classes.actionRoot }}>
              <Grid xs={8} container>
                <Typography variant="subtitle2" className={classes.typography}>
                  {props.message}
                </Typography>
              </Grid>
              <Grid
                xs={4}
                container
                direction="row"
                justify="flex-end"
                className={classes.icons}
              >
                <Grid item container xs={4} direction="row">
                  <IconButton
                    aria-label="Show more"
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Grid>
                <Grid item container xs={4} direction="row">
                  <IconButton
                    className={classes.expand}
                    onClick={handleDismiss}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Paper className={classes.collapse}>
                {payload.map((obj) => {
                  const {
                    affected: {
                      item: {
                        name: affectedName,
                        priceMonetaryFields: {
                          displayString: affectedDisplayString,
                        },
                      },
                    },
                    updated: {
                      item: {
                        name: updatedName,
                        priceMonetaryFields: {
                          displayString: updatedDisplayString,
                        },
                      },
                    },
                  } = obj;
                  return (
                    <Grid container direction="row" justify="space-between">
                      <Grid
                        item
                        xs={8}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Typography className={classes.modifiedTypography}>
                            {affectedName}
                          </Typography>
                          <ArrowDownwardIcon
                            style={{ fontSize: 15, marginLeft: 3 }}
                          />
                        </Grid>

                        <Typography
                          className={classes.contentTypography}
                          gutterBottom
                        >
                          {updatedName}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Typography className={classes.modifiedTypography}>
                            ${affectedDisplayString}
                          </Typography>
                          <ArrowDownwardIcon
                            style={{ fontSize: 15, marginLeft: 3 }}
                          />
                        </Grid>
                        <Typography
                          className={classes.contentTypography}
                          gutterBottom
                        >
                          ${updatedDisplayString}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Paper>
            </Collapse>
          </Card>
        </SnackbarContent>
      );

    default:
      return (
        <SnackbarContent ref={ref} className={classes.root}>
          <Card className={classes.card}>
            <CardActions classes={{ root: classes.actionRoot }}>
              <Typography variant="subtitle2" className={classes.typography}>
                {props.message}
              </Typography>
              <div className={classes.icons}>
                <IconButton className={classes.expand} onClick={handleDismiss}>
                  <CloseIcon />
                </IconButton>
              </div>
            </CardActions>
          </Card>
        </SnackbarContent>
      );
  }
});

export default SnackMessage;
