import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  contactFields: {
    name: {
      label: 'NAME',
      error: false,
      value: '',
      required: true,
      type: 'text'
    },
    email: {
      label: 'EMAIL',
      error: false,
      value: '',
      required: false,
      type: 'email'
    },
    phoneNumber: {
      label: 'PHONE NUMBER',
      error: false,
      value: '',
      required: true,
      type: 'number'
    }
  },
  selectedPickUpTime: null,
  pickUpTimeError: false,
  activeStep: 0,
  storeHours: [],
  infoLoading: false,
  paymentLoading: false,
  errorMessage: '',
  newOrderSummary: '',
  timePickerModalIsOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_FIELDS:
      return {
        ...state,
        contactFields: action.fields
      };

    case actionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.step
      };

    case actionTypes.SET_PICK_UP_TIME:
      return {
        ...state,
        selectedPickUpTime: action.time
      };

    case actionTypes.INFO_VALIDATION:
      return {
        ...state,
        infoLoading: true,
        errorMessage: ''
      };

    case actionTypes.INFO_VALIDATION_FAILED:
      return {
        ...state,
        infoLoading: false,
        contactFields: action.contactFields,
        shippingFields: action.shippingFields,
        errorMessage: action.errorMessage
      };

    case actionTypes.INFO_VALIDATION_SUCCESS:
      return {
        ...state,
        infoLoading: false,
        contactFields: action.contactFields,
        shippingFields: action.shippingFields,
        activeStep: action.nextStep,
        errorMessage: ''
      };

    case actionTypes.PAYMENT_VALIDATION:
      return {
        ...state,
        paymentLoading: true,
        errorMessage: ''
      };

    case actionTypes.PAYMENT_VALIDATION_SUCCESS:
      return {
        ...initialState
      };

    case actionTypes.PAYMENT_VALIDATION_FAILED:
      return {
        ...state,
        paymentLoading: false,
        errorMessage: action.errorMessage
      };

    case actionTypes.RESET_CHECKOUT_TO_INITIAL_STATE:
      return initialState;

    case actionTypes.TOGGLE_TIME_PICKER_MODAL:
      return {
        ...state,
        timePickerModalIsOpen: action.modalAction
      };

    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage
      };

    case actionTypes.SET_PICK_UP_TIME_ERROR:
      return {
        ...state,
        pickUpTimeError: action.error
      };

    case actionTypes.CLEAR_FORM_ERRORS:
      const updatedFields = { ...state.contactFields };
      Object.keys(updatedFields).forEach((key) => {
        updatedFields[key].error = false;
      });
      return {
        ...state,
        contactFields: updatedFields
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'checkout',
  storage: localforage,
  blacklist: [
    'infoLoading',
    'paymentLoading',
    'pickUpTimeError',
    'errorMessage',
    'timePickerModalIsOpen',
    'selectedPickUpTime'
  ]
};

export default persistReducer(persistConfig, reducer);
