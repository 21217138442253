import React from 'react';
import { Grid, Button, makeStyles, withStyles } from '@material-ui/core';
import Slider from '../../../components/home/Slider';
import { Link } from 'react-router-dom';

// Root Imports
import Header from './header';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: { padding: '80px 20px' },
  title: {
    color: 'white',
    fontFamily: 'Cinzel Decorative',
    fontSize: 30,
    paddingBottom: 10
  },
  frameImage: {
    padding: 15,
    width: '80%',
    '@media (max-width:1920px)': {
      width: '90%'
    },
    '@media (max-width:768px)': {
      width: '100%'
    }
  },
  slider: {
    backgroundColor: 'rgba(113, 124, 137, 0.08)',
    padding: '30px 0px 30px 0px',
    borderRadius: 10
  },
  buttonLink: {
    textDecoration: 'none'
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#F4C347',
    borderRadius: 20,
    fontFamily: 'Source Sans Pro',
    fontSize: 11,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#F4C347'
    }
  }
}))(Button);

const Section = (props) => {
  // Styles
  const classes = useStyles();

  //   Props
  const {
    title,
    description,
    subdesc,
    image1,
    image2,
    images,
    buttonTitle,
    btnLink
  } = props;

  return (
    <Grid container className={classes.root}>
      <Header title={title} description={description} subdesc={subdesc} />
      <Grid container alignItems='center' justify='center'>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          xl={8}
          alignItems='center'
          justify='center'
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            alignItems='center'
            justify='center'
          >
            <img className={classes.frameImage} src={image1} alt='image1' />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            alignItems='center'
            justify='center'
          >
            <img className={classes.frameImage} src={image2} alt='image2' />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.slider}>
        <Slider images={images} />
      </Grid>
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ paddingTop: 30 }}
      >
        <Link to={btnLink} className={classes.buttonLink}>
          <ColorButton endIcon={<ArrowForward />}>{buttonTitle}</ColorButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Section;
