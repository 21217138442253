import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

// Firebase
import { db } from '../../firebase';

// Component Imports
import Screen from './index';
import Context from './context';

// Other State
import screenMyOrders from '../../store/myorders';
import screenMenu from '../../store/menu';
import screenOrder from '../../store/order';
import screenAuth from '../../store/auth';
import screenGlobal from '../../store/global';

const useStyles = makeStyles({
  screenRoot: {
    minHeight: '100vh',
    backgroundColor: '#F3F3F3',
    width: '100%'
  }
});

const Wrapper = ({ ...other }) => {
  // Classes
  const classes = useStyles();

  // User
  const { user, handleModifiedOrders, onSetOrders, orders } = other;

  // Unique Ids of data
  const uniqueIds = [...new Set(orders.map((obj) => obj.id))];

  useEffect(() => {
    const unsubscribe = db
      .collection('orders')
      .where('userUid', '==', user.uid)
      .onSnapshot(function (snapshot) {
        let addedProducts = [];
        let modifiedProducts = [];
        let removedProducts = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const product = change.doc.data();
            if (product && product.id && !uniqueIds.includes(product.id)) {
              addedProducts.push(product);
            }
          }
          if (change.type === 'modified') {
            const product = change.doc.data();
            modifiedProducts.push(product);
          }
          if (change.type === 'removed') {
            const product = change.doc.data();
            removedProducts.push(product);
          }
        });
        if (addedProducts.length !== 0) {
          onSetOrders(addedProducts);
        }
        if (modifiedProducts.length !== 0) {
          handleModifiedOrders(modifiedProducts);
        }
      });

    return () => {
      unsubscribe();
      onSetOrders([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Context.Provider value={{ ...other }}>
      <div className={classes.screenRoot}>
        <Screen />
      </div>
    </Context.Provider>
  );
};

const mapState = (state) => ({
  menu: state.menu.menu,
  isSignedIn: state.auth.isSignedIn,
  user: state.auth.user,
  orders: state.myorders.orders,
  tabValue: state.myorders.tabValue
});

const mapDispatch = (dispatch) => ({
  getMenuData: (payload) => {
    dispatch({ type: screenMenu.actions.GET_MENU, payload });
  },
  initCheckout: (history) => {
    dispatch({ type: screenOrder.actions.INIT_CHECKOUT, history });
  },
  handleAuthLogOut: () => {
    dispatch({ type: screenAuth.actions.AUTH_LOGOUT });
    dispatch({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: 'Logged out.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'default'
        }
      }
    });
  },
  onTabValueChanged: (value) => {
    dispatch({ type: screenMyOrders.actions.HANDLE_TAB_CHANGE, value });
  },
  onSetOrders: (orders) => {
    dispatch({ type: screenMyOrders.actions.SET_ORDERS, orders });
  },
  handleModifiedOrders: (orders) => {
    dispatch({ type: screenMyOrders.actions.HANDLE_MODIFIED_ORDERS, orders });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);
