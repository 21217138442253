// import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
// import * as actionTypes from './actions';
// import * as api from '../../api';
// import firebase from '../../firebase';

// function* authStart() {
//   try {
//     const user = yield firebase.auth().currentUser;
//     yield put({
//       type: actionTypes.AUTH_LOGIN,
//       user: {
//         name: user.displayName,
//         email: user.email,
//         photoUrl: user.photoURL,
//         emailVerified: user.emailVerified,
//         uid: user.uid,
//       },
//     });
//   } catch (e) {
//     // handle error
//     yield put({ type: actionTypes.AUTH_FAILED });
//   }
// }

function* mySaga() {
  // yield takeLatest(actionTypes.AUTH_START, authStart);
}

export default mySaga;
