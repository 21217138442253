import { createSelector } from 'reselect';

const checkoutState = (state) => state.checkout;

export const getContactFields = createSelector(
  checkoutState,
  (state) => state.contactFields
);

export const getPickUpTime = createSelector(
  checkoutState,
  (state) => state.selectedPickUpTime
);
