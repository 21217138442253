import React from 'react';
import { connect } from 'react-redux';

// Component Imports
import Order from './Order';
import Context from './context';
import CartDrawer from '../../components/order/CartDrawer';
import InitCheckoutModal from '../../components/order/InitCheckoutModal';

// Store Imports
import screenOrder from '../../store/order';
import screenMenu from '../../store/menu';
import screenAuth from '../../store/auth';
import screenGlobal from '../../store/global';

const Wrapper = ({ ...other }) => {
  // Map To State Dispatch Props
  const { authModalIsOpen } = other;

  return (
    <Context.Provider value={{ ...other }}>
      {authModalIsOpen && <InitCheckoutModal />}
      <CartDrawer />
      <Order />
    </Context.Provider>
  );
};

const mapState = (state) => ({
  cart: state.order.cart,
  menu: state.menu.menu,
  menuList: state.order.menuList,
  modalIsOpen: state.order.modalIsOpen,
  selectedItem: state.order.selectedItem,
  modalErrorArray: state.order.modalErrorArray,
  modalHasError: state.order.modalHasError,
  cartDrawerIsOpen: state.order.cartDrawerIsOpen,
  cartDrawerIsLoading: state.order.cartDrawerIsLoading,
  modalIsLoading: state.order.modalIsLoading,
  authModalIsOpen: state.auth.authModalIsOpen,
  authModalIsLoading: state.auth.authModalIsLoading,
  isSignedIn: state.auth.isSignedIn,
  calendar: state.menu.calendar,
  storeHours: state.global.storeHours,
  storeActions: state.global.storeActions
});

const mapDispatch = (dispatch) => ({
  toggleModal: (payload) => {
    dispatch({ type: screenOrder.actions.TOGGLE_MODAL, payload });
  },
  toggleCartDrawer: (payload) => {
    dispatch({ type: screenOrder.actions.TOGGLE_CART_DRAWER, payload });
  },
  addToCart: (payload) => {
    dispatch({ type: screenOrder.actions.ADD_TO_CART, payload });
  },
  removeFromCart: (item) => {
    dispatch({ type: screenOrder.actions.REMOVE_FROM_CART, item });
  },
  initCheckout: (history) => {
    dispatch({ type: screenOrder.actions.INIT_CHECKOUT, history });
  },
  cancelInitCheckout: () => {
    dispatch({ type: screenAuth.actions.HANDLE_CANCEL_INIT_CHECKOUT });
  },
  getMenuData: (payload) => {
    dispatch({ type: screenMenu.actions.GET_MENU, payload });
  },
  handleAuthStart: (payload) => {
    dispatch({ type: screenAuth.actions.AUTH_START, payload });
  },
  handleAuthLogOut: () => {
    dispatch({ type: screenAuth.actions.AUTH_LOGOUT });
    dispatch({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: 'Logged out.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'default'
        }
      }
    });
  },
  handleCloseAuthModal: () => {
    dispatch({ type: screenAuth.actions.CLOSE_AUTH_MODAL });
  },
  resetOrderStateToInitialState: () => {
    dispatch({ type: screenOrder.actions.RESET_ORDER_STATE_TO_INITIAL_STATE });
  },
  sendSnackBarNotification: (payload) => {
    dispatch({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: payload.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: payload.variant ? payload.variant : 'default'
        }
      }
    });
  },
  onRemoveInactiveItems: (items) => {
    dispatch({ type: screenMenu.actions.HANDLE_REMOVE_INACTIVE_ITEMS, items });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);
