import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import MenuItem from '../MenuItem';

const OrderItems = (props) => {
  const { items } = props;

  return (
    <Fragment>
      {items &&
        items.map((obj) => {
          // Handle Null Objects
          if (!obj) {
            return null;
          }

          // Item
          const { item } = obj;

          // If Item is not active then don't display it
          if (item.isActive === false) {
            return null;
          }

          // Return Item
          return (
            <Grid item xs={12} sm={12} md={12}>
              <MenuItem menuItem={obj} />
            </Grid>
          );
        })}
    </Fragment>
  );
};

export default OrderItems;
