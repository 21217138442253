import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: 75
  },
  categoryName: {
    fontSize: 35,
    padding: '0px 15px',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  },
  menuName: {
    fontSize: 18,
    padding: '0px 15px',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    color: '#F34213',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  categoryDescription: {
    fontSize: 18,
    padding: '0px 15px',
    fontFamily: 'Source Sans Pro',
    color: '#545454'
  },
  sectionHeader: {
    marginBottom: 15
  },
  menuTime: {
    padding: '0px 15px',
    fontSize: 12,
    color: '#545454',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro'
  }
}));

const MenuSection = (props) => {
  const classes = useStyles();

  // Props
  const {
    categoryName,
    menuName,
    children,
    categoryDescription,
    showMenuTime,
    endTime
  } = props;

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item container direction='column' className={classes.sectionHeader}>
        {showMenuTime && endTime && (
          <Typography className={classes.menuTime}>
            <mark>Served until {endTime}</mark>
          </Typography>
        )}
        <Typography className={classes.menuName}>{menuName}</Typography>
        <Typography className={classes.categoryName}>{categoryName}</Typography>
        <Typography className={classes.categoryDescription}>
          {categoryDescription}
        </Typography>
      </Grid>
      <Grid item container direction='row'>
        {children}
      </Grid>
    </Grid>
  );
};

export default MenuSection;
