import React, { useContext, useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  InputBase,
  fade,
  useMediaQuery,
  Typography,
  Dialog
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OrderContext from '../../../screens/order/context';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormInputs from './FormInputs';
import './addItemModal.scss';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    fontSize: 25,
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 5,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogButton = withStyles((theme) => ({
  root: {
    padding: '5px 10px',
    backgroundColor: '#F34213',
    color: 'white',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: 15,
    '&:hover': {
      backgroundColor: '#F34213'
    }
  }
}))(Button);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    textAlign: 'center',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    fontWeight: 'bold',
    width: '30px',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const quantityControlStyles = makeStyles({
  root: {},
  controlButton: {
    height: 25,
    width: 25,
    borderRadius: '50%',
    textAlign: 'center',
    border: '2px solid grey',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    fontSize: 16,
    color: 'black'
  }
});

const QuantityControl = (props) => {
  // styles
  const classes = quantityControlStyles();

  // Control props
  const { quantity, onChange } = props;

  // Handle remove control
  const handleRemove = () => {
    if (quantity <= 1) {
      return onChange(1);
    }
    return onChange(quantity - 1);
  };

  // Handle add control
  const handleAdd = () => {
    if (quantity >= 999) {
      return onChange(999);
    }
    return onChange(quantity + 1);
  };

  // Handle input control
  const handleInputChange = (value) => {
    if (value >= 999) {
      return onChange(999);
    }
    if (value <= 1) {
      return onChange(1);
    }
    return onChange(value);
  };

  return (
    <Grid container direction='row' justify='space-around' alignItems='center'>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        className={classes.controlButton}
        onClick={handleRemove}
      >
        <RemoveIcon className={classes.icon} />
      </Grid>
      <div className={classes.input}>
        <BootstrapInput
          type='-'
          value={quantity}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </div>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        className={classes.controlButton}
        onClick={handleAdd}
      >
        <AddIcon className={classes.icon} />
      </Grid>
    </Grid>
  );
};

const displayTotalItemPrice = (quantity, price, optionsForm) => {
  // quantity: number
  // price: object{ amount: cents:number, displayCost: string }

  // Calculate quantity and unit price
  const { unitAmount } = price;

  let newAmount = Math.round(parseFloat(unitAmount).toFixed(2));

  // Return if no options.
  if (!optionsForm) {
    return parseFloat((newAmount * quantity) / 100).toFixed(2);
  }

  // Calculate options addition
  let addedCost = { unitAmount: 0 };

  // Convert options to object array
  const optionsKeys = Object.keys(optionsForm);
  const optionsArray = optionsKeys.map((key) => {
    return {
      name: key,
      ...optionsForm[key]
    };
  });

  optionsArray.forEach((option) => {
    if (!option.selectedOptions) {
      return;
    }
    switch (option.selectionMode) {
      case 'multi_choice':
        option.selectedOptions.forEach((selectedOption) => {
          if (!selectedOption.selectedOptions) {
            return;
          }
          selectedOption.selectedOptions.forEach((option) => {
            addedCost.unitAmount += option.priceMonetaryFields.unitAmount;
          });
        });
        break;

      default:
        option.selectedOptions.forEach((selectedOption) => {
          addedCost.unitAmount += selectedOption.priceMonetaryFields.unitAmount;
        });
        break;
    }
  });

  return parseFloat(
    ((newAmount + addedCost.unitAmount) * quantity) / 100
  ).toFixed(2);
};

const dialogStyles = makeStyles((theme) => ({
  root: {},
  description: {
    fontSize: 18,
    color: 'grey',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  errorCaption: {
    color: 'red',
    fontSize: 18,
    fontWeight: 'bold',
    position: 'sticky',
    bottom: 0,
    fontFamily: 'Source Sans Pro'
  },
  buttonProgress: {
    color: 'black',
    position: 'relative',
    bottom: 25,
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const AddItemDialog = (props) => {
  const classes = dialogStyles();

  const fullScreen = useMediaQuery('(max-width:500px)');

  // Redux state from context provider.
  const {
    modalIsOpen,
    toggleModal,
    selectedItem,
    addToCart,
    modalErrorArray,
    modalHasError
  } = useContext(OrderContext);

  // Menu item data from selected item.
  const { item, options } = selectedItem;

  // Item details and attributes.
  const {
    name,
    description,
    specialInstructions,
    priceMonetaryFields,
    maxSpecialInstructionsCharacters
  } = item;

  // Local state. Used to validate inputs.
  const [quantity, setQuantity] = useState(1);
  const [optionsForm, setOptionsForm] = useState(null);
  const [specialInstructionsValue, setSpecialInstructions] = useState(null);

  // Reset quantity to 1 on item change.
  useEffect(() => {
    setQuantity(1);
  }, [selectedItem]);

  // Generate a form object to validate when item is added to cart.
  useEffect(() => {
    // If no available options return.
    if (!options) {
      return;
    }

    // Genrate options from from Item options to validate later.
    const newOptionsForm = {};
    options.forEach((option) => {
      newOptionsForm[option.name] = {
        selectionMode: option.selectionMode,
        position: option.position,
        isRequired: option.isRequired,
        minNumOptions: option.minNumOptions,
        maxNumOptions: option.maxNumOptions,
        selectedOptions: option.defaultOptions || null
      };
    });

    // Set new options form in local state.
    setOptionsForm(newOptionsForm);
  }, [selectedItem, options]);

  // Handle form data. Starts as null. Filled in as users inputs data.
  // Accepts option object and option value.
  const handleOptionChange = (option, optionValue) => {
    // Spread options form into a new one to avoide mutation.
    const changedOptionsForm = { ...optionsForm };
    changedOptionsForm[option.name].selectedOptions = optionValue;
    setOptionsForm(changedOptionsForm);
  };

  // Handle close dialog. Set selected item to null. Set quantity back to 1.
  const handleClose = () => {
    toggleModal({ modalAction: false, item: null });
    setQuantity(1);
  };

  // Handle add to cart. Get item and form data. Validate in saga.
  const handleAddToCart = () => {
    // if no options dispatch item data
    if (!options) {
      // Item data as payload. Options as empty object. Assing Quantity
      const payload = {
        item: selectedItem,
        optionsForm: [],
        quantity: quantity
      };

      // Add special instructions if available.
      payload['specialInstructions'] =
        specialInstructionsValue &&
        !!specialInstructionsValue.replace(/\s/g, '')
          ? specialInstructionsValue
          : null;

      // Dispatch saga with payload.
      return addToCart(payload);
    }

    // Convert form/options into array of objects.
    const optionsKeys = Object.keys(optionsForm);
    const optionsArray = optionsKeys.map((key) => {
      return {
        name: key,
        ...optionsForm[key]
      };
    });

    // Item data as payload. Assing Quantity
    const payload = {
      item: selectedItem,
      optionsForm: optionsArray,
      quantity: quantity
    };

    // Add special instructions if available.
    payload['specialInstructions'] =
      specialInstructionsValue && !!specialInstructionsValue.replace(/\s/g, '')
        ? specialInstructionsValue
        : null;

    // Dispatch saga with payload.
    addToCart(payload);
  };

  // Renders forms for item conditionally.
  const itemForms = (option) => {
    switch (option.selectionMode) {
      case 'single_select':
        return (
          <>
            <FormInputs.SingleSelect
              onChange={(value) => handleOptionChange(option, value)}
              isRequired={option.isRequired}
              label={option.name}
              options={option.options}
              error={modalErrorArray.includes(option.name)}
            />
            <br></br>
          </>
        );
      case 'multi_select':
        return (
          <>
            <FormInputs.MultiSelect
              onChange={(value) => handleOptionChange(option, value)}
              isRequired={option.isRequired}
              label={option.name}
              options={option.options}
              maxValue={option.maxNumOptions}
              error={modalErrorArray.includes(option.name)}
            />
            <br></br>
          </>
        );

      case 'multi_choice':
        return (
          <>
            <FormInputs.MultiChoice
              onChange={(value) => handleOptionChange(option, value)}
              isRequired={option.isRequired}
              label={option.name}
              choices={option.choices}
              error={modalErrorArray.includes(option.name)}
            />
            <br></br>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={modalIsOpen}
      id='addItemDialog'
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle onClose={handleClose}>{name}</DialogTitle>
      <DialogContent dividers>
        {description && (
          <>
            <Typography gutterBottom className={classes.description}>
              {description}
            </Typography>
            <br></br>
          </>
        )}
        {options &&
          options.map((option) => {
            return itemForms(option);
          })}
        {specialInstructions && (
          <>
            <FormInputs.SpecialRequest
              onChange={(value) => setSpecialInstructions(value)}
              maxValue={maxSpecialInstructionsCharacters}
            />
            <br></br>
          </>
        )}
        {modalHasError && (
          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            className={classes.errorCaption}
          >
            Please fix highlighed fields
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Grid container direction='row' justify='space-between'>
          <Grid xs={5} item container justify='center' alignItems='center'>
            <QuantityControl
              quantity={quantity}
              onChange={(quantity) => setQuantity(quantity)}
            />
          </Grid>
          <Grid xs item container justify='flex-end' alignItems='center'>
            <DialogButton onClick={handleAddToCart} color='primary'>
              Add to cart - $
              {displayTotalItemPrice(
                quantity,
                priceMonetaryFields,
                optionsForm
              )}
            </DialogButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;
