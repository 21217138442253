import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  tabValue: 0,
  isLoading: false,
  orders: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ORDERS:
      // Orders
      const { orders } = action;
      return {
        ...state,
        orders
      };

    case actionTypes.HANDLE_MODIFIED_ORDERS:
      const modifiedOrdersIds = [
        ...new Set(action.orders.map((order) => order.id))
      ];
      const updatedOrders = state.orders.filter(
        (order) => !modifiedOrdersIds.includes(order.id)
      );
      return {
        ...state,
        orders: [...updatedOrders, ...action.orders]
      };

    case actionTypes.FETCH_ORDERS:
      return {
        ...state,
        isLoading: true,
        orders: []
      };

    case actionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.orders
      };

    case actionTypes.FETCH_ORDERS_FAILED:
      return {
        ...state,
        isLoading: false,
        orders: []
      };

    case actionTypes.HANDLE_TAB_CHANGE:
      return {
        ...state,
        tabValue: action.value
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'myorders',
  storage: localforage,
  blacklist: ['isLoading', 'tabValue']
};

export default persistReducer(persistConfig, reducer);
