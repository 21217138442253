import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, useMediaQuery } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import Context from '../../../screens/order/context';
import SelectButton from './selectButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 75,
    width: '100%',
    position: 'sticky',
    top: 0,
    boxShadow: '0 3px 20px rgba(0,0,0,0.1)',
    backgroundColor: 'white'
  },
  orderButton: {
    minWidth: 100,
    color: 'white',
    backgroundColor: '#F34213',
    fontWeight: 600,
    fontSize: 16,
    fontFamily: 'Alata',
    '&:hover': {
      backgroundColor: '#F34213'
    }
  },
  gridRoot: {
    padding: '0px 15px'
  },
  menuIcon: {
    fontSize: 40
  }
}));

const cartIconStyles = makeStyles({
  root: {
    height: 40,
    width: 40
  },
  icon: {
    color: 'white',
    fontSize: 30,
    marginLeft: -10
  },
  cartNumber: {
    position: 'relative',
    zIndex: 2,
    fontWeight: 600,
    fontSize: 12,
    fontFamily: 'Alata',
    color: '#F34213',
    left: 9
  }
});

const CartIcon = () => {
  const classes = cartIconStyles();
  return (
    <Grid container className={classes.cartIcon}>
      <ShoppingCartIcon className={classes.icon} />
    </Grid>
  );
};

const OrderBar = (props) => {
  const classes = useStyles();
  const mobileDevice = useMediaQuery('(max-width:450px)');

  // Props
  const { currentCategory, scrollNameList, handleDropDownDialogOpen } = props;

  // Map to state props
  const { toggleCartDrawer, cart } = useContext(Context);

  return (
    <Grid container direction="row" justify="center" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        lg={8}
        container
        direction="row"
        justify="center"
        className={classes.gridRoot}
      >
        <Grid
          item
          xs={8}
          md={6}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {mobileDevice ? (
            <MenuIcon
              className={classes.menuIcon}
              onClick={() => handleDropDownDialogOpen(true)}
            />
          ) : (
            <SelectButton
              value={scrollNameList[currentCategory]}
              onClick={() => handleDropDownDialogOpen(true)}
            />
          )}
        </Grid>

        <Grid
          item
          container
          xs={4}
          md={6}
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}
        >
          <Button
            variant="contained"
            size="medium"
            className={classes.orderButton}
            startIcon={<CartIcon />}
            onClick={() => toggleCartDrawer(true)}
          >
            {cart.length}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderBar;
