import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import getIsMenuActive from '../../../utilities/getIsMenuActive';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto'
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  }
}));

export default function PinnedSubheaderList(props) {
  const classes = useStyles();

  //   props
  const { dailySchedule, handleClose, weeklySchedule } = props;

  return (
    <List className={classes.root} subheader={<li />}>
      {dailySchedule &&
        dailySchedule.map((menu) => {
          const { isActive } = getIsMenuActive(
            menu.timeFrame,
            weeklySchedule,
            menu.isAllDay
          );

          if (!isActive) {
            // eslint-disable-next-line array-callback-return
            return;
          }

          return (
            <li key={`section-${menu.value}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader
                  style={{
                    color: '#F34213',
                    fontWeight: 'bold',
                    fontFamily: 'Source Sans Pro',
                    borderBottom: 'solid #E7E7E7 1px',
                    boxShadow: '0 3px 10px rgba(0,0,0,0.1)'
                  }}
                >{`${menu.name}`}</ListSubheader>
                {menu.categories.map((cateogory) => (
                  <a
                    href={`#${cateogory.value}`}
                    style={{
                      textDecoration: 'none',
                      color: 'black',
                      fontWeight: 'bold',
                      fontFamily: 'Source Sans Pro'
                    }}
                  >
                    <ListItem
                      button
                      onClick={handleClose}
                      key={`item-${menu.value}-${cateogory.name}`}
                    >
                      <ListItemText primary={`${cateogory.name}`} />
                    </ListItem>
                  </a>
                ))}
              </ul>
            </li>
          );
        })}
    </List>
  );
}
