import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

// Global Store
import * as screenGlobalActions from './actions';

const initialState = {
  loading: false,
  storeActions: {},
  storeHours: {},
  notifications: [],
  storeSystem: {
    buildVersion: '2.0.0'
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case screenGlobalActions.SET_STORE_ACTIONS:
      return {
        ...state,
        storeActions: action.data
      };

    case screenGlobalActions.SET_STORE_HOURS:
      return {
        ...state,
        storeHours: action.data
      };

    case screenGlobalActions.SET_STORE_SYSTEM:
      return {
        ...state,
        storeSystem: action.data
      };

    case screenGlobalActions.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.notification.options.key,
            ...action.notification
          }
        ]
      };

    case screenGlobalActions.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case screenGlobalActions.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        )
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'global',
  storage: localforage,
  blacklist: [
    'loading',
    'alerts',
    'storeHours',
    'storeActions',
    'notifications'
  ]
};

export default persistReducer(persistConfig, reducer);
