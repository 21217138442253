import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import Context from '../../../screens/order/context';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: 2,
    margin: '15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FCF7F8'
    }
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontSize: 18,
    fontWeight: 'bold'
  },
  price: {
    fontFamily: 'Inconsolata',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#F34213'
  },
  description: {
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    color: 'grey'
  },
  cardContent: {
    minHeight: 100
  }
}));

const CardItem = ({ menuItem, canOrder, sendSnackBarNotification }) => {
  const classes = useStyles();

  const { toggleModal } = useContext(Context);

  const { item, isChoice } = menuItem;

  const { description, priceMonetaryFields, name } = item;

  const handleCardClick = () => {
    if (canOrder) {
      toggleModal({ modalAction: true, menuItem });
    } else {
      sendSnackBarNotification({
        message: 'Online ordering is currently disabled.'
      });
    }
  };

  return (
    <Card className={classes.root} variant='outlined' onClick={handleCardClick}>
      <CardContent>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.cardContent}
        >
          <Grid item container direction='column' justify='space-between'>
            <Typography className={classes.title}>{name}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
          <Grid item container direction='row' justify='space-between'>
            {priceMonetaryFields && !isChoice && (
              <Typography className={classes.price}>
                ${priceMonetaryFields.displayString}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardItem;
