import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Home from "./screens/home/homeWrapper";
import Menu from "./screens/menu/menuWrapper";
import Order from "./screens/order/orderWrapper";
import Checkout from "./screens/checkout/checkoutWrapper";
import HappyHour from "./screens/happyhour/happyHourWrapper";
import About from "./screens/about/wrapper";
import Gallery from "./screens/gallery/wrapper";
import MyOrders from "./screens/myorders/wrapper";

// Snackbar Provider
import { SnackbarProvider } from "notistack";
import CustomSnackBarContent from "./components/global/CustomSnackBarContent";

// Session Wall
import SessionWall from "./globalSessionWall";

// Terms and Pilicies
import PrivacyPolicy from "./screens/Terms/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./screens/Terms/TermsOfService/TermsOfService";

const stripePromise = loadStripe("pk_live_FVA6PTkKOPeKF5tqyaqJ0zxs00JN1sUtgq");

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={() => <Home />} />
      <Route path="/menu" component={() => <Menu />} />
      <Route path="/happyhour" component={() => <HappyHour />} />
      <Route path="/about" component={() => <About />} />
      <Route path="/gallery" component={() => <Gallery />} />
      {/* <Route path='/order' component={() => <Order />} />
      <Route path='/myorders' component={() => <MyOrders />} /> */}
      {/* <Route
        path='/checkout'
        component={() => (
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Checkout stripe={stripe} elements={elements} />
              )}
            </ElementsConsumer>
          </Elements>
        )}
      /> */}
      <Route path="/privacypolicy" component={PrivacyPolicy} />
      <Route path="/termsofservice" component={TermsOfService} />
      <Redirect exact to="/" />
    </Switch>
  );
};
const App = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      content={(key, message) => (
        <CustomSnackBarContent id={key} message={message} />
      )}
    >
      <SessionWall>
        <Routes />
      </SessionWall>
    </SnackbarProvider>
  );
};

export default App;
