import React, { useState, useCallback, useEffect } from 'react';

// Gallery Packages
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

// Material UI
import { Grid } from '@material-ui/core';

// UI Components
import Nav from '../../components/global/Nav';

// Photos Data
import { photos } from './photos';

const GalleryView = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ position: 'sticky', top: 0 }}>
        <Nav
          navItems={[
            { to: '/menu', name: 'MENU' },
            { to: '/happyhour', name: 'HAPPY HOUR' },
            { to: '/gallery', name: 'GALLERY' },
            { to: '/about', name: 'ABOUT' }
          ]}
        />
      </div>

      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default GalleryView;
