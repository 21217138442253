import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 250,
    borderRadius: 5,
    '@media (max-width:400px)': {
      width: '100%',
      height: 'auto'
    }
  }
}));

const Gallery = ({ images }) => {
  const classes = useStyles();

  const responsive = {
    0: { items: 1 },
    770: { items: 2 },
    1024: { items: 3 },
    1920: { items: 4 }
  };

  const handleDragStart = (e) => e.preventDefault();

  const items = images.map((image) => (
    <Grid container justify='center' alignItems='center'>
      <img
        className={classes.image}
        src={image}
        alt='Mongo'
        draggable={false}
        onDragStart={handleDragStart}
      />
    </Grid>
  ));

  return (
    <>
      <AliceCarousel
        disableDotsControls
        disableButtonsControls
        autoPlayInterval={2000}
        infinite
        autoPlay
        mouseTracking
        items={items}
        responsive={responsive}
      />
    </>
  );
};

export default Gallery;
