import * as actions from './actions';
import reducer from './reducer';
import sagas from './sagas';
import * as selectors from './selectors';

export default {
  actions,
  reducer,
  sagas,
  selectors
};
