export const GET_DAILY_SCHEDULE = 'GET_DAILY_SCHEDULE';
export const SET_STORE_ACTIONS = 'SET_STORE_ACTIONS';
export const SET_STORE_HOURS = 'SET_STORE_HOURS';
export const SET_STORE_SYSTEM = 'SET_STORE_SYSTEM';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
