import React from 'react';
import { Button, Dialog, withStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MenuCollectionList from './menuCollectionList';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 5,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogButton = withStyles((theme) => ({
  root: {
    padding: '5px 10px',
    backgroundColor: '#F34213',
    color: 'white',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: 15,
    '&:hover': {
      backgroundColor: '#F34213'
    }
  }
}))(Button);

export default function ResponsiveDialog(props) {
  // props
  const { handleClose, open, calendar, weeklySchedule } = props;

  // Calendar Data
  const { dailySchedule } = calendar;

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={handleClose}
      open={open}
      id='addItemDialog'
    >
      <DialogContent>
        <MenuCollectionList
          dailySchedule={dailySchedule}
          handleClose={handleClose}
          weeklySchedule={weeklySchedule}
        />
      </DialogContent>

      <DialogActions>
        <DialogButton onClick={handleClose} color='primary'>
          CLOSE
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
