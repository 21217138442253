import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  user: null,
  isSignedIn: false,
  orders: [],
  authModalIsOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // AUTH MODAL ACTIONS //
    case actionTypes.OPEN_AUTH_MODAL:
      return {
        ...state,
        authModalIsOpen: true
      };

    case actionTypes.CLOSE_AUTH_MODAL:
      return {
        ...state,
        authModalIsOpen: false
      };

    // AUTH ACTIONS //

    case actionTypes.AUTH_START:
      return {};

    case actionTypes.AUTH_LOGIN:
      return {
        ...state,
        isSignedIn: true,
        user: action.user
      };

    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        isSignedIn: false,
        user: null
      };

    case actionTypes.AUTH_FAILED:
      return {
        ...state,
        authError: true
      };

    case actionTypes.SET_ORDERS:
      return {
        ...state,
        orders: action.orders
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'auth',
  storage: localforage,
  blacklist: ['orders', 'authModalIsOpen']
};

export default persistReducer(persistConfig, reducer);
