import React, { useContext, useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckoutContext from '../../../screens/checkout/context';
import './dialog.scss';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    fontSize: 20,
    fontWeight: '600'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 5,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogButton = withStyles((theme) => ({
  root: {
    padding: '5px 10px',
    backgroundColor: '#F34213',
    color: 'white',
    fontFamily: 'Source Sans Pro',
    fontWeight: 'bold',
    fontSize: 15,
    '&:hover': {
      backgroundColor: '#F34213'
    }
  }
}))(Button);

const dialogStyles = makeStyles((theme) => ({
  description: {
    fontSize: 18,
    color: 'grey'
  },
  errorCaption: {
    color: 'red',
    fontSize: 18,
    fontWeight: 'bold',
    position: 'sticky',
    bottom: 0,
    fontFamily: 'Source Sans Pro'
  },
  formControl: {
    margin: theme.spacing(1)
  }
}));

const timeRangeCreator = (payload) => {
  const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
    const timeValueIsEarlier =
      moment(timeValue, 'h:mmA').diff(moment(endLimit, 'h:mmA')) < 0;
    const timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, 'h:mmA').diff(moment(timeValue, 'h:mmA')) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  };
  var timeValue = payload.beginLimit;
  var lastValue;
  var endLimit = payload.endLimit;
  var step = payload.step || 30;
  var options = [];
  options.push(timeValue);
  while (isEarlierThanEndLimit(timeValue, endLimit, lastValue)) {
    lastValue = timeValue;
    timeValue = moment(timeValue, 'HH:mmA')
      .add(step, 'minutes')
      .format('HH:mmA');
    endLimit = moment(endLimit, 'HH:mmA').format('HH:mmA');
    if (timeValue <= endLimit) {
      timeValue = moment(timeValue, 'h:mmA').format('h:mmA');
      options.push(timeValue);
    }
  }
  return options;
};

const TimePickerDialog = (props) => {
  const classes = dialogStyles();
  // const fullScreen = useMediaQuery('(max-width:500px)');

  // props.
  const { minT, maxT, step } = props;

  // Redux state from context provider.
  const { toggleTimePickerModal, timePickerModalIsOpen, selectPickUpTime } =
    useContext(CheckoutContext);

  // Time range calculator
  const options = timeRangeCreator({ beginLimit: minT, endLimit: maxT, step });

  // Local state. Used to validate inputs.
  const [pickUpTime, setPickUpTime] = useState(minT);

  // Set minT as the default pick up time on the redux store.
  useEffect(() => {
    selectPickUpTime(pickUpTime);
  }, [selectPickUpTime, pickUpTime]);

  // Handle close dialog. Set selected item to null. Set quantity back to 1.
  const handleClose = () => {
    toggleTimePickerModal(false);
  };

  // Handle save time.
  const handleSave = () => {
    toggleTimePickerModal(false);
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      onClose={handleClose}
      open={timePickerModalIsOpen}
      id='timePickerDialog'
    >
      <DialogTitle onClose={handleClose}>Pick Up Time</DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column'>
          <Grid item xs={12} sm={12} container>
            <FormControl
              fullWidth
              variant='outlined'
              color='red'
              className={classes.formControl}
            >
              <InputLabel id='timePicker'>Time</InputLabel>
              <Select
                id='timePickerSelect'
                value={pickUpTime}
                onChange={(e) => setPickUpTime(e.target.value)}
                label='ASAP'
              >
                {options &&
                  options.map((time) => {
                    return (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogButton fullWidth color='primary' onClick={handleSave}>
          SAVE
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default TimePickerDialog;
