import {
  put,
  select,
  takeLatest,
  take,
  fork,
  cancel,
  cancelled
} from 'redux-saga/effects';

import * as actionTypes from './actions';
import * as selectors from './selectors';

// Other Screen State
import * as screenAuthSelectors from '../auth/selectors';
import * as screenAuthActions from '../auth/actions';

// Utilities
import addToCartValidator from '../../utilities/addToCartValidator';

function* addToCart(action) {
  try {
    // Cart ID selector
    const cartId = yield select(selectors.getCartId);

    // Cart selector
    const cart = yield select(selectors.getCart);

    // Deconstruct item and option form data.
    const {
      item: newItem,
      optionsForm,
      specialInstructions,
      quantity
    } = action.payload;

    // Validate options form. If failed put ADD_TO_CART_FAILED:
    // with error array corrsponding to the options.
    const errorArray = [];

    // Check if options form exists and is not empty
    if (optionsForm && optionsForm.length > 0) {
      optionsForm.forEach((option) => {
        // For each option form validate it.
        if (addToCartValidator(option)) {
          errorArray.push(option.name);
        }
      });
    }

    // return a FAILED put with error array.
    if (errorArray.length > 0) {
      return yield put({ type: actionTypes.ADD_TO_CART_FAILED, errorArray });
    }

    // Deconstruct neccesary item data

    // Create a cart item with cart ID and neccesary cart item info.
    const cartItem = {};

    cartItem['menuItem'] = { ...newItem };
    cartItem['cartId'] = cartId;
    cartItem['quantity'] = quantity;

    // Assign special instructions if available
    cartItem['specialInstructions'] = specialInstructions;

    // Assign optionf form if available
    cartItem['optionsForm'] = optionsForm;

    // Create am updated cart with new item.
    const updatedCart = [...cart, cartItem];

    // Successfully put action to update cart with new item.
    yield put({ type: actionTypes.ADD_TO_CART_SUCCESS, cart: updatedCart });
  } catch (e) {
    // handle error
    yield put({ type: actionTypes.ADD_TO_CART_FAILED });
  }
}

function* handleInitCheckout(history) {
  try {
    yield put({ type: screenAuthActions.OPEN_AUTH_MODAL });

    // Will contain user data.
    const authStart = yield take(screenAuthActions.AUTH_START);

    // Here we can use user UID to fetch neccesary user data
    const { payload } = authStart;

    const { user } = payload;

    if (!user) {
      throw Error('No user found when attempting to log in.');
    }

    yield put({ type: screenAuthActions.AUTH_LOGIN, user });

    if (history) {
      history.push('/checkout');
    }

    yield put({ type: screenAuthActions.CLOSE_AUTH_MODAL });
  } catch (e) {
    // handle error
    yield put({ type: screenAuthActions.HANDLE_CANCEL_INIT_CHECKOUT });
  } finally {
    if (yield cancelled()) {
      yield put({ type: screenAuthActions.CLOSE_AUTH_MODAL });
    }
  }
}

function* initCheckout(action) {
  // History
  const { history } = action;

  // Close Cart Drawer
  yield put({ type: actionTypes.TOGGLE_CART_DRAWER, payload: false });

  // isSignedIn selector
  const isSignedIn = yield select(screenAuthSelectors.getIsSignedIn);

  if (isSignedIn) {
    return history.push('/checkout');
  }

  // starts the task in the background
  const task = yield fork(handleInitCheckout, history);

  // wait for the user stop action
  yield take(screenAuthActions.HANDLE_CANCEL_INIT_CHECKOUT);

  yield cancel(task);
}

function* mySaga() {
  yield takeLatest(actionTypes.ADD_TO_CART, addToCart);
  yield takeLatest(actionTypes.INIT_CHECKOUT, initCheckout);
}

export default mySaga;
