import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Card,
  Divider,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import Iframe from 'react-iframe';
import { CardElement } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { Link, Redirect, useHistory } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import MongoLogo from '../../assets/img/mongologo.png';
import { CartItemAnimation } from '../../components/order/CartDrawer';
import '../../components/stripe/CardSelectionStyle.scss';
import Context from './context';
import totalCalculator from '../../utilities/totalCalculator';
import cartitemOptionsRender from './utilites/cartitemOptionsRender';
import TimePickerDialog from '../../components/checkout/TimePicker';
import getPickUpTimeRange from '../../utilities/getPickUpTimeRange';
import getIsMenuActive from '../../utilities/getIsMenuActive';
import getIsStoreOpen from '../../utilities/getIsStoreOpen';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px'
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const displayTotalItemPrice = (quantity, price, optionsForm) => {
  // quantity: number
  // price: object{ unitAmount: cents:number, displayString: string }

  // Calculate quantity and unit price
  const { unitAmount } = price;

  let newAmount = Math.round(parseFloat(unitAmount).toFixed(2));

  // Return if no options.
  if (!optionsForm) {
    return parseFloat((newAmount * quantity) / 100).toFixed(2);
  }

  // Calculate options addition
  let addedCost = { unitAmount: 0 };

  // Convert options to object array
  const optionsKeys = Object.keys(optionsForm);
  const optionsArray = optionsKeys.map((key) => {
    return {
      name: key,
      ...optionsForm[key]
    };
  });

  optionsArray.forEach((option) => {
    if (!option.selectedOptions) {
      return;
    }
    switch (option.selectionMode) {
      case 'multi_choice':
        option.selectedOptions.forEach((selectedOption) => {
          if (!selectedOption.selectedOptions) {
            return;
          }
          selectedOption.selectedOptions.forEach((option) => {
            addedCost.unitAmount += option.priceMonetaryFields.unitAmount;
          });
        });
        break;

      default:
        option.selectedOptions.forEach((selectedOption) => {
          addedCost.unitAmount += selectedOption.priceMonetaryFields.unitAmount;
        });
        break;
    }
  });

  return parseFloat(
    ((newAmount + addedCost.unitAmount) * quantity) / 100
  ).toFixed(2);
};

const useStyles = makeStyles({
  formTitle: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  },
  orderSummaryRoot: {
    padding: '25px 15px 25px 15px'
  },
  orderSummaryText: {
    fontFamily: 'Roboto Mono',
    fontSize: 17,
    fontWeight: '400'
  },
  orderSummaryItems: {
    padding: '0px 10px 20px 10px',
    width: '100%'
  },
  orderSummaryTotalText: {
    fontFamily: 'Roboto Mono',
    fontSize: 17
  },
  orderSummaryTotals: {
    padding: '0px 10px 10px 10px'
  },
  cartItemRoot: {
    width: '100%',
    height: '100%',
    borderBottom: 'solid #E7E7E7 1px',
    padding: '10px 5px'
  },
  image: {
    width: 29,
    height: 25
  },
  cartItemText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: 'bold'
  },
  cartItemTitleText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 14,
    fontWeight: 'bold'
  },
  cartItemSubText: {
    color: '#4D4D4D',
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  cartItemTypeText: {
    color: '#7A8383',
    fontFamily: 'Roboto Mono',
    fontSize: 12,
    whiteSpace: 'nowrap'
  },
  cardText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    textAlign: 'left'
  },
  cardTitle: {
    color: 'grey',
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    textAlign: 'center'
  },
  cardChangeText: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'center',
    cursor: 'pointer'
  },
  backButton: {
    color: 'black',
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    cursor: 'pointer'
  },
  buttonProgress: {
    color: 'black',
    position: 'relative',
    bottom: 25,
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  errorMessage: {
    color: 'red',
    fontFamily: 'Roboto Mono',
    fontSize: 16,
    textAlign: 'center',
    paddingLeft: '10px'
  },
  logo: {
    width: 130,
    '@media (max-width:600px)': {
      width: 90
    }
  },
  promotionalBanner: {
    backgroundColor: '#B5E4FF',
    height: 180,
    width: '100%',
    padding: 20
  },
  promotionalText: {
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    textAlign: 'center'
  },
  promotionalSubText: {
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  orderSuccessBanner: {
    backgroundColor: '#CDFFA3',
    height: 180,
    width: '100%',
    padding: 20
  },
  emptyText: {
    fontFamily: 'Source Sans Pro',
    color: 'black',
    fontWeight: 'bold'
  },
  emptyIcon: {
    fontSize: 65
  }
});

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'black'
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.MuiFilledInput-focused fieldset': {
        borderColor: 'black'
      }
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    }
  }
})(TextField);

const FormComponent = (props) => {
  const { fields, onChange, title } = props;
  const classes = useStyles();
  return (
    <Grid container direction='column' xs={12} sm={6} md={5} lg={4}>
      {title && (
        <Grid item style={{ padding: '10px 15px' }}>
          <Typography className={classes.formTitle}>{title}</Typography>
        </Grid>
      )}
      {Object.keys(fields).map((key) => {
        return (
          <Grid item style={{ padding: '5px 10px' }}>
            <CssTextField
              error={fields[key].error}
              variant='filled'
              size={'small'}
              fullWidth
              placeholder={fields[key].label}
              value={fields[key].value}
              label={fields[key].label}
              inputProps={{
                style: {
                  fontFamily: 'Roboto Mono',
                  padding: fields[key].value ? '15px' : '15px'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: 'none',
                  fontFamily: 'Roboto Mono'
                }
              }}
              onChange={(e) => onChange(e.target.value, key)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const CartItem = ({
  cartItem,
  removeButtonIsDisabled,
  onResetOrderStateToInitialState
}) => {
  // Styles
  const classes = useStyles();

  // Map To State props
  const { removeFromCart } = useContext(Context);

  // Try to catch any isues with buggy data.
  try {
    // Cart Item
    const { menuItem, optionsForm, quantity, specialInstructions } = cartItem;

    const { item, type } = menuItem;

    const { name, priceMonetaryFields } = item;

    return (
      <Grid item container direction='column' className={classes.cartItemRoot}>
        <Grid item container direction='row'>
          <Grid item xs={1}></Grid>
          <Grid
            item
            container
            xs={8}
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            {type && (
              <Typography className={classes.cartItemTypeText}>
                {type.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
        </Grid>

        <Grid item container direction='row'>
          <Grid
            item
            container
            xs={1}
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <Typography className={classes.cartItemText}>{quantity}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={8}
            direction='row'
            justify='flex-start'
            alignItems='center'
            zeroMinWidth
          >
            <Typography className={classes.cartItemTitleText} noWrap>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={removeButtonIsDisabled ? 3 : 2}
            direction='row'
            justify={removeButtonIsDisabled ? 'flex-end' : 'center'}
            alignItems='center'
          >
            <Typography className={classes.cartItemText}>
              $
              {displayTotalItemPrice(
                quantity,
                priceMonetaryFields,
                optionsForm
              )}
            </Typography>
          </Grid>
          {!removeButtonIsDisabled && (
            <Grid
              item
              container
              xs={1}
              direction='row'
              justify='flex-end'
              alignItems='center'
              style={{ cursor: 'pointer' }}
              onClick={() => removeFromCart(cartItem)}
            >
              <CloseIcon style={{ color: '#F34213', fontSize: 20 }} />
            </Grid>
          )}
        </Grid>

        {optionsForm &&
          optionsForm.length > 0 &&
          optionsForm.map((option) => {
            const renderOptions = cartitemOptionsRender(option);

            return (
              renderOptions &&
              renderOptions.length > 0 &&
              renderOptions.map((selectedOption) => {
                return (
                  <Grid
                    item
                    container
                    direction='row'
                    justify='space-evenly'
                    alignItems='center'
                  >
                    <Grid item container xs={1}></Grid>
                    <Grid
                      item
                      xs={8}
                      container
                      direction='column'
                      justify='flex-start'
                    >
                      <Typography className={classes.cartItemSubText}>
                        {selectedOption.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={removeButtonIsDisabled ? 3 : 2}
                      container
                      direction='row'
                      justify={removeButtonIsDisabled ? 'flex-end' : 'center'}
                    >
                      <Typography className={classes.cartItemSubText}>
                        ${selectedOption.priceMonetaryFields.displayString}
                      </Typography>
                    </Grid>
                    {!removeButtonIsDisabled && <Grid item xs={1}></Grid>}
                  </Grid>
                );
              })
            );
          })}

        {specialInstructions && (
          <Grid
            item
            container
            direction='row'
            justify='space-evenly'
            alignItems='center'
          >
            <Grid item container xs={1}></Grid>
            <Grid item xs={8} container direction='column' justify='flex-start'>
              <Typography className={classes.cartItemSubText}>
                Notes: {specialInstructions}
              </Typography>
            </Grid>
            <Grid item container xs={2}></Grid>
            <Grid item container xs={1}></Grid>
          </Grid>
        )}
      </Grid>
    );
  } catch (error) {
    onResetOrderStateToInitialState();
    return null;
  }
};

const OrderSummary = (props) => {
  // Props
  const { expanded, handleClick, paymentValidationLoading } = props;

  // Map To State Props
  const { cart, resetOrderStateToInitialState, paymentLoading } =
    useContext(Context);

  // Styles
  const classes = useStyles();

  // Calcualte summary
  const returnObject = totalCalculator(cart, null, 1.0825);

  const { tax, subTotal, total } = returnObject;

  return (
    <Grid container xs={12} sm={6} md={5} lg={4}>
      <Grid
        container
        direction='row'
        justify='space-between'
        className={classes.orderSummaryRoot}
        onClick={handleClick}
      >
        <Grid item xs={8} container direction='row'>
          <Typography className={classes.orderSummaryText}>
            {expanded ? 'HIDE ORDER SUMMARY' : 'SHOW ORDER SUMMARY'}
          </Typography>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        <Grid item xs={4}>
          <Typography
            className={classes.orderSummaryText}
            style={{ textAlign: 'right' }}
          >
            ${total.displayString}
          </Typography>
        </Grid>
      </Grid>
      {expanded && (
        <Grid item container xs direction='column'>
          <Grid
            item
            xs
            direction='column'
            className={classes.orderSummaryItems}
          >
            {cart.map((item) => {
              return (
                <CartItemAnimation key={item.cartId} item={item}>
                  <CartItem
                    onResetOrderStateToInitialState={
                      resetOrderStateToInitialState
                    }
                    cartItem={item}
                    removeButtonIsDisabled={
                      paymentValidationLoading || paymentLoading
                    }
                  />
                </CartItemAnimation>
              );
            })}
          </Grid>
          <Grid
            item
            container
            direction='row'
            justify='space-between'
            style={{ paddingTop: 10 }}
            className={classes.orderSummaryTotals}
          >
            <Grid item xs={4} container direction='row'>
              <Typography className={classes.orderSummaryTotalText}>
                SUBTOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${subTotal.displayString}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            justify='space-between'
            className={classes.orderSummaryTotals}
          >
            <Grid item xs={4} container direction='row'>
              <Typography className={classes.orderSummaryTotalText}>
                TAXES
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${tax.displayString}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            justify='space-between'
            className={classes.orderSummaryTotals}
            style={{ paddingBottom: 35 }}
          >
            <Grid item xs={4} container direction='row'>
              <Typography className={classes.orderSummaryTotalText}>
                TOTAL
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.orderSummaryTotalText}
                style={{ textAlign: 'right' }}
              >
                ${total.displayString}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#F34213',
    fontFamily: 'Roboto Mono',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F34213'
    }
  }
}))(Button);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#212121'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#212121'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#EAEAF0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#784af4'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#212121'
  },
  completed: {
    color: '#212121',
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const Checkout = () => {
  // Map To State Props
  const {
    cart,
    contactFields,
    setContactFields,
    setActiveStep,
    activeStep,
    onInfoValidation,
    infoLoading,
    shippingLoading,
    paymentLoading,
    stripe,
    elements,
    errorMessage,
    onPaymentValidation,
    newOrderSummary,
    onResetCheckoutToInitialState,
    toggleTimePickerModal,
    selectedPickUpTime,
    selectPickUpTime,
    pickUpTimeError,
    isSignedIn,
    onSetErrorMessage,
    storeActions,
    storeHours,
    onClearErrors,
    onRemoveInactiveItems,
    calendar
  } = useContext(Context);

  // Styles
  const classes = useStyles();

  // History
  const history = useHistory();

  // Local State
  const [expanded, setExpanded] = useState(false);

  const [errorCaption, setErrorCaption] = useState(errorMessage);

  const [paymentValidationLoading, setPaymentValidationLoading] =
    useState(false);

  const [promoCode, setPromoCode] = useState('');

  const handlePromoCode = (value) => {
    const code = value.toUpperCase().replace(/\s/g, '');
    setPromoCode(code);
  };

  // Function to calculate steps
  const steps = getSteps();

  // Store Hours
  const { weeklySchedule } = storeHours;

  // Check if store is open.
  const isStoreOpen = getIsStoreOpen(weeklySchedule);

  // Store Actions
  const { canOrder } = storeActions;

  const handleNext = async () => {
    // Check if store is open or disabled.
    if (canOrder === false || isStoreOpen === false) {
      setActiveStep(0);
      return <Redirect to='/order' />;
    }

    if (activeStep === 0) {
      // Validate contact info
      onInfoValidation(activeStep);
    } else if (activeStep === 1) {
      setPaymentValidationLoading(true);
      // Validate Payment
      if (!stripe || !elements) {
        setPaymentValidationLoading(false);
        setErrorCaption('UNKWON ERROR. PLEASE TRY AGAIN.');
        return;
      }
      // Grabbing card element
      const cardElement = elements.getElement(CardElement);

      // Show error, comeplete card element
      if (!cardElement._complete) {
        setPaymentValidationLoading(false);
        setErrorCaption('COMPLETE PAYMENT');
        return;
      }

      setErrorCaption('');
      onSetErrorMessage('');
      // Generate source with card element.
      const { error, source } = await stripe.createSource(cardElement);

      // Handle source error
      if (error) {
        setPaymentValidationLoading(false);
        setErrorCaption(error.message);
        return;
      }

      onPaymentValidation({ activeStep, source, promoCode, history });
      setPaymentValidationLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setErrorCaption('');
      onSetErrorMessage('');
      onClearErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If active step is on payment or success order then open order summary by default
  useEffect(() => {
    if (activeStep === 1 || activeStep === 2) {
      setExpanded(true);
    }
  }, [activeStep]);

  // Scroll to top on step change.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // If there is an error message show it.
  useEffect(() => {
    setErrorCaption(errorMessage);
  }, [errorMessage]);

  // If WeeklySchedule Changes reset pickUpTime
  useEffect(() => {
    if (selectedPickUpTime) {
      selectPickUpTime(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklySchedule]);

  // Check if cart items are active
  useEffect(() => {
    // Check for inactive items.

    // Calendar & Store Hours
    const { dailySchedule } = calendar;
    const { weeklySchedule } = storeHours;

    // Inactive Items
    const inactiveItems = [];

    cart.length > 0 &&
      dailySchedule &&
      weeklySchedule &&
      cart.forEach((cartItem) => {
        // Cart Item
        const {
          menuItem: { schedule: { value } } = {
            schedule: { value: '' }
          }
        } = cartItem;

        if (!value) {
          return;
        }

        // Cart Item's Menu
        const { timeFrame, isAllDay } = dailySchedule.filter(
          (obj) => obj.value === value
        )[0];

        const { isActive } = getIsMenuActive(
          timeFrame,
          weeklySchedule,
          isAllDay
        );

        if (isActive === false) {
          inactiveItems.push(cartItem);
        }
      });

    if (inactiveItems.length > 0) {
      onRemoveInactiveItems(inactiveItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, storeHours]);

  // handle the back button. Goes back to previous step.
  const handleBack = () => {
    setErrorCaption('');
    onSetErrorMessage('');
    setActiveStep(activeStep - 1);
  };

  // Handles contact information change
  const onContactChange = (value, key) => {
    const updatedFields = { ...contactFields };
    updatedFields[key] = { ...updatedFields[key], value };
    setContactFields(updatedFields);
  };

  // Renders out different steps that are required for this site.
  function getSteps() {
    return ['CONTACT', 'PAYMENT'];
  }

  // Returns to order if online ordering is disabled
  if (!canOrder || !isStoreOpen) {
    setActiveStep(0);
    return <Redirect to='/order' />;
  }

  // Returns to order page if cart is empty on all steps.
  if (
    !isSignedIn ||
    (cart.length === 0 && (activeStep === 0 || activeStep === 1))
  ) {
    setActiveStep(0);
    return <Redirect to='/order' />;
  }

  // Calculate  Minimum Time to order, Maximum time to order and time step.
  const [minT, maxT, step] = getPickUpTimeRange(weeklySchedule);

  // Gets the content to render for each step conditionally.
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <FormComponent
              title='CONTACT'
              fields={contactFields}
              onChange={onContactChange}
            />
            <OrderSummary
              handleClick={() => setExpanded(!expanded)}
              expanded={expanded}
            />
          </React.Fragment>
        );

      case 1:
        return (
          <>
            <Grid
              container
              direction='column'
              className={classes.promotionalBanner}
              justify='space-around'
              alignitems='center'
            >
              <Grid
                item
                container
                direction='column'
                justify='center'
                alignitems='center'
              >
                <Typography className={classes.promotionalText}>
                  $5.00 off with order subtotal over $30.00.
                </Typography>
                <Typography className={classes.promotionalSubText}>
                  Use promo code: OVER30
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction='column'
                justify='center'
                alignitems='center'
              >
                <Typography className={classes.promotionalText}>
                  $15.00 off with order subtotal over $60.00.
                </Typography>
                <Typography className={classes.promotionalSubText}>
                  Use promo code: OVER60
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={6}
              md={5}
              lg={4}
              direction='column'
              justify='center'
              style={{ padding: '15px' }}
              spacing={2}
            >
              <Typography
                className={classes.formTitle}
                style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
              >
                PICK UP LOCATION
              </Typography>
              <Iframe
                url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6941.527017492216!2d-95.39468478277061!3d29.5523806842058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5040e6094884c991!2sMongo!5e0!3m2!1sen!2sus!4v1568854422064!5m2!1sen!2sus'
                width='100%'
                height='250'
                frameborder='0'
                allowFullScreen={false}
                style={{ border: '2px solid black' }}
              />
              <Typography
                className={classes.formTitle}
                style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
              >
                CONTACT INFO
              </Typography>
              <Card variant='outlined'>
                <Grid
                  container
                  direction='column'
                  justify='center'
                  style={{ padding: 10 }}
                  spacing={1}
                >
                  <Grid item container direction='row' justify='space-between'>
                    <Typography className={classes.cardTitle}>
                      Contact
                    </Typography>
                    <Typography
                      className={classes.cardChangeText}
                      onClick={() => {
                        setErrorCaption('');
                        setActiveStep(0);
                      }}
                    >
                      Change
                    </Typography>
                  </Grid>
                  <Grid item container direction='row'>
                    <Typography className={classes.cardText}>
                      {contactFields['name'].value}
                    </Typography>
                  </Grid>
                  <Divider style={{ margin: '10px 0px' }} />
                  <Grid item container direction='row' justify='space-between'>
                    <Typography
                      className={classes.cardTitle}
                      style={{ color: pickUpTimeError ? 'red' : 'grey' }}
                    >
                      Pickup Time
                    </Typography>
                    {/* <Typography
                      className={classes.cardChangeText}
                      onClick={() => toggleTimePickerModal(true)}
                    >
                      Change
                    </Typography> */}
                  </Grid>

                  <Grid item container direction='row'>
                    <Typography className={classes.cardText}>ASAP</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <OrderSummary
              handleClick={() => setExpanded(!expanded)}
              expanded={expanded}
              paymentValidationLoading={paymentValidationLoading}
            />
            <Grid
              container
              xs={12}
              sm={6}
              md={5}
              lg={4}
              direction='column'
              justify='center'
              style={{ padding: 10 }}
              spacing={2}
            >
              <Typography
                className={classes.formTitle}
                style={{ textAlign: 'left', padding: '0px 0px 15px 0px' }}
              >
                PROMOTIONAL CODE
              </Typography>

              <CssTextField
                // error={fields[key].error}
                variant='filled'
                size={'small'}
                fullWidth
                placeholder={'PROMO CODE'}
                value={promoCode}
                label={false}
                inputProps={{
                  style: {
                    fontFamily: 'Roboto Mono',
                    padding: promoCode ? '15px' : '15px'
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    display: 'none',
                    fontFamily: 'Roboto Mono'
                  }
                }}
                onChange={(e) => handlePromoCode(e.target.value)}
              />

              <Typography
                className={classes.formTitle}
                style={{ textAlign: 'left', padding: '25px 0px 15px 0px' }}
              >
                PAYMENT
              </Typography>
              <Grid item style={{ padding: '0px 0px 20px 0px' }}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </Grid>
            </Grid>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{ paddingTop: 25, paddingBottom: 25 }}
    >
      {activeStep === 2 ? (
        <Link to='/' onClick={() => onResetCheckoutToInitialState()}>
          <img src={MongoLogo} alt='Mongo' className={classes.logo} />
        </Link>
      ) : (
        <Link to='/'>
          <img src={MongoLogo} alt='Mongo' className={classes.logo} />
        </Link>
      )}

      <Grid
        container
        xs={12}
        sm={6}
        md={5}
        lg={4}
        direction='column'
        justify='center'
      >
        <Stepper
          alternativeLabel
          style={{ padding: '5px 5px 25px 5px' }}
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step style={{ padding: '0px 0px' }} key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <Typography style={{ fontFamily: 'Roboto Mono', fontSize: 15 }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid container direction='column' justify='center' alignItems='center'>
        {getStepContent(activeStep)}
        <Grid
          container
          xs={12}
          sm={6}
          md={5}
          lg={4}
          direction='column'
          justify='center'
          style={{ padding: '5px 10px 10px 10px' }}
        >
          {errorCaption && (
            <Grid
              item
              container
              direction='row'
              justify='center'
              alignItems='center'
              style={{ padding: '0px 0px 10px 0px' }}
            >
              <ErrorIcon style={{ color: 'red' }} />
              <Typography className={classes.errorMessage}>
                {errorCaption}
              </Typography>
            </Grid>
          )}
          {activeStep === 2 && (
            <ColorButton
              fullWidth
              variant='contained'
              size='large'
              onClick={() => onResetCheckoutToInitialState()}
              style={{ marginBottom: 5 }}
            >
              OK
            </ColorButton>
          )}

          {activeStep < 2 && (
            <ColorButton
              fullWidth
              disabled={
                infoLoading ||
                shippingLoading ||
                paymentLoading ||
                paymentValidationLoading
              }
              variant='contained'
              size='large'
              onClick={handleNext}
              style={{ marginBottom: 5 }}
            >
              {activeStep === steps.length - 1 ? 'PAY NOW' : 'CONTINUE'}
            </ColorButton>
          )}
          {(infoLoading ||
            shippingLoading ||
            paymentLoading ||
            paymentValidationLoading) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {activeStep < 2 && activeStep !== 0 && (
            <Button
              disabled={infoLoading || shippingLoading || paymentLoading}
              size='large'
              onClick={handleBack}
              // startIcon={<LeftArrowIcon />}
              // endIcon={<span></span>}
              className={classes.backButton}
            >
              {'<'} GO BACK
            </Button>
          )}
          {activeStep === 0 && (
            <Link to='/order' style={{ textDecoration: 'none', width: '100%' }}>
              <Button
                size='large'
                fullWidth
                // startIcon={<LeftArrowIcon />}
                // endIcon={<span></span>}
                className={classes.backButton}
                onClick={() => {
                  setErrorCaption('');
                  onSetErrorMessage('');
                }}
              >
                {'<'} BACK TO ORDER
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>

      <TimePickerDialog minT={minT} maxT={maxT} step={step} />
    </Grid>
  );
};

export default Checkout;
