/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Offline detector
import { Detector } from 'react-detect-offline';

// Notifier
import Notifier from './Notifier';

// Lodash
import _ from 'lodash';

// Firebase
import { db } from '../firebase';

// Component Imports
import Context from './context';
import screenGlobal from '../store/global';
import screenMenu from '../store/menu';

// App Loader
const loader = document.querySelector('.loaderZ');
const body = document.querySelector('body');

const SessionWall = ({ ...other }) => {
  // Hide App Loader
  const hideLoader = () => loader.classList.add('loader--hide');
  const enableScrolling = () => body.classList.remove('scroll-disabled');

  // Map To State Dispatch Props
  const {
    menu,
    onSetMenuCalendar,
    onSetMenu,
    handleModifiedMenu,
    onSetStoreActions,
    onSetStoreHours,
    storeHours,
    storeActions,
    storeSystem,
    children,
    onSetStoreSystem,
    sendSnackBarNotification
  } = other;

  // Hide Loader when essential data has been fetched
  // #TODO143 Handle and show error on loader if data fetch was unsuccessful
  useEffect(() => {
    if (
      !_.isEmpty(storeHours) &&
      !_.isEmpty(storeActions) &&
      !_.isEmpty(menu)
    ) {
      hideLoader();
      enableScrolling();
    }
  }, [storeHours, storeActions, menu]);

  // // Unique Ids of data
  const uniqueIds = [...new Set(menu.map((obj) => obj.id))];

  // Live menu data.
  useEffect(() => {
    const unsubscribe = db
      .collection('menu')
      .doc('data')
      .collection('items')
      .onSnapshot(function (snapshot) {
        let addedProducts = [];
        let modifiedProducts = [];
        let removedProducts = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const product = change.doc.data();
            if (product && product.id && !uniqueIds.includes(product.id)) {
              addedProducts.push(product);
            }
          }
          if (change.type === 'modified') {
            const product = change.doc.data();
            modifiedProducts.push(product);
          }
          if (change.type === 'removed') {
            const product = change.doc.data();
            removedProducts.push(product);
          }
        });
        if (addedProducts.length !== 0) {
          onSetMenu(addedProducts);
        }
        if (modifiedProducts.length !== 0) {
          handleModifiedMenu(modifiedProducts);
        }
      });

    return () => {
      unsubscribe();
      onSetMenu([]);
    };
  }, []);

  // Live Menu Calendar Data.
  useEffect(() => {
    const unsubscribe = db
      .collection('menu')
      .doc('calendar')
      .onSnapshot(
        (doc) => {
          onSetMenuCalendar(doc.data() || {});
        },
        (error) => {
          // Handle Error
          console.log('onSetMenuCalendar error', error);
        }
      );

    return () => {
      unsubscribe();
      onSetMenuCalendar({});
    };
  }, []);

  // Live Store Actions Data.
  useEffect(() => {
    const unsubscribe = db
      .collection('store')
      .doc('actions')
      .onSnapshot(function (doc) {
        onSetStoreActions(doc.data() || {});
      });

    return () => {
      unsubscribe();
      onSetStoreActions({});
    };
  }, []);

  // Live Store Hours Data.
  useEffect(() => {
    const unsubscribe = db
      .collection('store')
      .doc('hours')
      .onSnapshot(function (doc) {
        onSetStoreHours(doc.data() || {});
      });

    return () => {
      unsubscribe();
      onSetStoreHours({});
    };
  }, []);

  // Live Store System Data.
  useEffect(() => {
    const unsubscribe = db
      .collection('store')
      .doc('system')
      .onSnapshot(function (doc) {
        const { buildVersion } = storeSystem;

        const { webapp } = doc.data();
        if (webapp.buildVersion === buildVersion) {
          // Later
        } else {
          // Later
        }
        onSetStoreSystem(webapp);
      });

    return () => {
      unsubscribe();
      onSetStoreSystem({});
    };
  }, []);

  // Handle Connection Change
  const handleConnectionChange = (isOnline) => {
    // TODO: Fix this bug
    // console.log('isOnline', isOnline);
    // if ((isOnline !== undefined || isOnline !== null) && isOnline === false) {
    //   sendSnackBarNotification({
    //     message: 'You are offline. Please check your internet connection.',
    //     options: {
    //       variant: 'error'
    //     }
    //   });
    // }
    if (isOnline === true) {
      sendSnackBarNotification({
        message: 'You are back online! Please refresh the page if neccesary.',
        options: {
          variant: 'success'
        }
      });
    }
  };

  return (
    <Context.Provider value={{ ...other }}>
      <Notifier />
      <Detector
        onChange={(isOnline) => handleConnectionChange(isOnline)}
        render={() => {
          return null;
        }}
      />
      {children}
    </Context.Provider>
  );
};

const mapState = (state) => ({
  menu: state.menu.menu,
  storeActions: state.global.storeActions,
  storeHours: state.global.storeHours,
  storeSystem: state.global.storeSystem
});

const mapDispatch = (dispatch) => ({
  onSetMenu: (menu) => {
    dispatch({ type: screenMenu.actions.SET_MENU, menu });
  },
  handleModifiedMenu: (menu) => {
    dispatch({ type: screenMenu.actions.HANDLE_MODIFIED_MENU, menu });
  },
  onSetMenuCalendar: (data) => {
    dispatch({ type: screenMenu.actions.SET_CALENDAR, data });
  },
  onSetStoreActions: (data) => {
    dispatch({ type: screenGlobal.actions.SET_STORE_ACTIONS, data });
  },
  onSetStoreHours: (data) => {
    dispatch({ type: screenGlobal.actions.SET_STORE_HOURS, data });
  },
  onSetStoreSystem: (data) => {
    dispatch({ type: screenGlobal.actions.SET_STORE_SYSTEM, data });
  },
  sendSnackBarNotification: (payload) => {
    dispatch({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: payload.message,
        options: {
          key: new Date().getTime() + Math.random(),
          ...payload.options
        }
      }
    });
  }
});

export default connect(mapState, mapDispatch)(SessionWall);
