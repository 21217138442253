var cartitemOptionsRender = (option) => {
  // Function that returns a list of renderable options that correspond to a cart item.

  // Start with an empty array. Push any available option to thwe array.\

  /* 

    Render items will follow this format:

    {
        "name",
        "priceMonetaryfields",
    }
    
    */

  const renderItems = [];

  // Pushes an renderable option for each option case
  switch (option.selectionMode) {
    case 'single_select':
      // If selected options is falsy then return
      if (!option.selectedOptions || option.selectedOptions.length === 0) {
        return;
      }

      //  In this scenario the slected options are already in the desire format.
      renderItems.push(...option.selectedOptions);

      break;

    case 'multi_select':
      // If selected options is falsy then return
      if (!option.selectedOptions || option.selectedOptions.length === 0) {
        return;
      }

      //  In this scenario the slected options are already in the desire format.
      renderItems.push(...option.selectedOptions);

      break;

    case 'multi_choice':
      // Multiple choice. Each choice has options.
      // Go through every choice selection and find selected options.

      // If selected options is falsy then return
      if (!option.selectedOptions || option.selectedOptions.length === 0) {
        return;
      }

      const choiceOptions = option.selectedOptions;

      choiceOptions.forEach((option) => {
        if (!option.selectedOptions) {
          return;
        }
        if (option.selectedOptions) {
          return renderItems.push(...option.selectedOptions);
        }
      });

      break;

    default:
      break;
  }

  return renderItems;
};

export default cartitemOptionsRender;
