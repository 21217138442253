import wings from '../../assets/img/home/wings.jpg';
import jalapeno from '../../assets/img/home/jalapeno.jpg';
import csr from '../../assets/img/home/csr.jpg';
import greenbeans from '../../assets/img/home/greenbeans.jpg';
import gyoza from '../../assets/img/home/gyoza.jpg';
import shrimptempura from '../../assets/img/home/shrimtempura.jpg';
import sushi from '../../assets/img/home/sushi.jpg';
import cajun from '../../assets/img/home/cajun.jpg';
import nemo from '../../assets/img/home/nemo.jpg';
import sunset from '../../assets/img/home/sunset.jpg';
import reddragon from '../../assets/img/home/reddragon.jpg';
import yt from '../../assets/img/home/yt.jpg';
import california from '../../assets/img/food/california.jpg';
import drinkVERT from '../../assets/img/food/drinkVERT.jpg';
import HabLobster from '../../assets/img/food/HabLobster.jpg';
import habMignon from '../../assets/img/food/habMignon.jpg';
import habSalmon from '../../assets/img/food/habsalmon.jpg';
import mango from '../../assets/img/food/mango.jpg';
import milkteaVERT from '../../assets/img/food/milkteaVERT.jpg';
import mongo from '../../assets/img/food/mongo.jpg';
import mongomangoVERT from '../../assets/img/food/mongomangoVERT.jpg';
import rainbow from '../../assets/img/food/rainbow.jpg';
import watermelonVERT from '../../assets/img/food/watermelonVERT.jpg';

export const photos = [
  {
    src: wings,
    width: 3,
    height: 2,
    title: 'Chicken Wings'
  },

  {
    src: shrimptempura,
    width: 3,
    height: 2,
    title: 'Shrimp Tempura'
  },

  {
    src: HabLobster,
    width: 3,
    height: 2,
    title: 'Lopbster Tail & Shrimp'
  },
  {
    src: greenbeans,
    width: 3,
    height: 2,
    title: 'Crispy Green Beans'
  },
  {
    src: csr,
    width: 3,
    height: 2,
    title: 'Crispy Spring Rolls'
  },
  {
    src: habMignon,
    width: 3,
    height: 2,
    title: 'Hibachi Filet Mignon'
  },
  {
    src: gyoza,
    width: 3,
    height: 2,
    title: 'Gyoza'
  },
  {
    src: mongomangoVERT,
    width: 2,
    height: 3,
    title: 'Mongo Mango'
  },

  {
    src: jalapeno,
    width: 3,
    height: 2,
    title: 'Stuffed Jalapeno'
  },
  {
    src: habSalmon,
    width: 3,
    height: 2,
    title: 'Hibachi Salmon'
  },
  {
    src: milkteaVERT,
    width: 2,
    height: 3,
    title: 'Milk Tea'
  },

  {
    src: cajun,
    width: 3,
    height: 2,
    title: 'Cajun Roll'
  },

  {
    src: reddragon,
    width: 3,
    height: 2,
    title: 'Red Dragon Roll'
  },
  {
    src: sunset,
    width: 3,
    height: 2,
    title: 'Sunset Roll'
  },
  {
    src: yt,
    width: 3,
    height: 2,
    title: 'Yuzu Yellow Tail'
  },
  {
    src: drinkVERT,
    width: 2,
    height: 3,
    title: 'Mojito'
  },

  {
    src: mango,
    width: 3,
    height: 2,
    title: 'Mango Smoothie'
  },
  {
    src: sushi,
    width: 3,
    height: 2,
    title: 'Sushi'
  },
  {
    src: nemo,
    width: 3,
    height: 2,
    title: 'Nemo Roll'
  },
  {
    src: watermelonVERT,
    width: 2,
    height: 3,
    title: 'Watermelon Smoothie'
  },
  {
    src: mongo,
    width: 3,
    height: 2,
    title: 'Mongo Roll'
  },
  {
    src: california,
    width: 3,
    height: 2,
    title: 'California Roll'
  },
  {
    src: rainbow,
    width: 3,
    height: 2,
    title: 'Rainbow Roll'
  }
];
