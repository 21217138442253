import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";

// Ant Tabs
const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8'
    position: "relative",
    zIndex: 99999,
  },
  indicator: {
    backgroundColor: "#F34213",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 120,
    fontWeight: theme.typography.fontWeightRegular,
    margin: "0px 20px",
    fontFamily: "Source Sans Pro",
    "&:hover": {
      color: "#F34213",
      opacity: 1,
    },
    "&$selected": {
      color: "#F34213",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#F34213",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 75,
    width: "100%",
    position: "sticky",
    top: 0,
    boxShadow: "0 3px 20px rgba(0,0,0,0.1)",
    backgroundColor: "white",
  },
  orderButton: {
    minWidth: 100,
    color: "white",
    backgroundColor: "#F34213",
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "Alata",
    "&:hover": {
      backgroundColor: "#F34213",
    },
  },
  gridRoot: {
    padding: "0px 15px",
  },
  menuIcon: {
    fontSize: 40,
  },
}));

const OrderBar = (props) => {
  // Styles
  const classes = useStyles();

  // Mobile Query
  const { tabValue, onTabValueChanged } = props;

  return (
    <Grid container direction="row" justify="center" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        lg={8}
        container
        direction="row"
        justify="center"
        className={classes.gridRoot}
      >
        <Grid
          item
          xs
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <AntTabs
            value={tabValue}
            onChange={(e, value) => onTabValueChanged(value)}
            aria-label="ant example"
          >
            <AntTab label="Active Orders" />
            <AntTab label="History" />
          </AntTabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderBar;
