import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// Mongolian SVG's
const MongolianSVGs = () => {
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid
        xs={3}
        md={1}
        item
        container
        direction='row'
        justify='center'
        alignItems='center'
      >
        <svg
          version='1.0'
          xmlns='http://www.w3.org/2000/svg'
          style={{
            width: 20
          }}
          viewBox='0 0 700.000000 754.000000'
          preserveAspectRatio='xMidYMid meet'
        >
          <metadata>
            Created by potrace 1.12, written by Peter Selinger 2001-2015
          </metadata>
          <g
            transform='translate(0.000000,754.000000) scale(0.100000,-0.100000)'
            fill='#F4C347'
            stroke='none'
          >
            <path
              d='M3690 6744 c0 -5 0 -481 0 -1059 l0 -1050 955 -3 c875 -2 955 -1 955
14 0 19 -109 243 -127 262 -7 7 -13 22 -13 33 0 10 -4 19 -10 19 -5 0 -17 15
-26 33 -69 133 -355 451 -514 569 -30 23 -59 47 -63 55 -4 7 -12 13 -17 13 -5
0 -45 24 -87 52 -99 67 -364 201 -375 190 -5 -5 -7 -130 -6 -278 l3 -269 165
-5 c140 -4 171 -8 195 -24 54 -34 305 -295 305 -316 0 -20 -5 -20 -507 -18
l-508 3 -3 693 -2 694 57 -5 c75 -6 142 -22 156 -36 6 -6 23 -11 38 -11 15 0
40 -7 55 -15 16 -8 37 -15 46 -15 9 0 20 -4 23 -10 3 -5 16 -10 28 -10 12 0
35 -7 51 -15 15 -8 34 -15 41 -15 7 0 18 -7 25 -15 7 -8 19 -15 27 -15 21 0
157 -65 209 -99 25 -17 49 -31 54 -31 5 0 36 -18 69 -40 33 -22 63 -40 67 -40
3 0 20 -11 38 -25 61 -50 141 -111 169 -128 40 -26 347 -335 398 -401 91 -118
130 -172 144 -201 9 -16 31 -50 50 -75 18 -25 34 -51 34 -59 1 -8 7 -22 15
-30 25 -29 116 -214 116 -236 0 -13 7 -28 15 -35 8 -7 15 -20 15 -29 0 -9 4
-21 9 -26 5 -6 19 -41 31 -80 13 -38 27 -74 32 -79 4 -6 8 -24 8 -41 0 -17 5
-36 12 -43 14 -14 30 -92 32 -162 l1 -55 -2575 0 -2575 0 -3 35 c-3 33 5 74
30 145 5 17 14 46 18 65 4 19 14 51 21 70 7 19 23 67 37 105 23 67 64 163 83
195 5 8 12 22 15 30 3 8 10 22 15 30 4 8 24 47 43 85 19 39 47 85 61 104 14
19 25 40 25 46 0 6 11 22 25 35 14 13 25 29 25 35 0 6 11 22 25 35 14 13 25
29 25 36 0 6 19 34 43 60 23 27 55 66 71 87 81 110 387 386 528 477 34 23 65
44 68 49 3 4 12 11 20 14 8 4 38 20 65 37 103 61 394 200 417 200 9 0 29 7 44
15 16 8 37 15 46 15 9 0 20 5 23 10 3 6 21 10 38 10 18 0 41 7 51 15 11 8 36
15 56 15 20 0 41 5 47 11 5 5 34 9 64 7 l54 -3 1 -675 c1 -402 -2 -682 -7
-692 -9 -17 -44 -18 -519 -18 -503 0 -510 0 -510 20 0 23 264 291 313 318 25
13 66 18 192 22 l160 5 3 273 c2 239 0 272 -13 272 -19 0 -203 -88 -210 -101
-4 -5 -15 -9 -25 -9 -10 0 -20 -3 -22 -8 -4 -10 -102 -72 -115 -72 -5 0 -20
-11 -33 -25 -13 -14 -29 -25 -35 -25 -7 0 -20 -10 -31 -21 -10 -12 -44 -38
-74 -59 -68 -47 -303 -284 -363 -365 -24 -33 -51 -66 -60 -74 -9 -7 -17 -19
-17 -26 0 -7 -11 -23 -25 -36 -13 -13 -25 -28 -25 -33 0 -4 -17 -36 -37 -70
-59 -96 -62 -100 -68 -116 -4 -8 -10 -22 -15 -30 -5 -8 -17 -31 -25 -50 -9
-19 -24 -50 -32 -68 -11 -21 -13 -36 -7 -42 7 -7 351 -9 960 -8 l949 3 3 1048
c2 833 0 1049 -10 1052 -20 7 -225 -12 -318 -30 -47 -9 -103 -18 -125 -21 -22
-3 -54 -12 -72 -20 -17 -8 -45 -14 -61 -14 -17 0 -34 -4 -37 -10 -3 -5 -16
-10 -28 -10 -12 0 -35 -7 -51 -15 -15 -8 -38 -15 -50 -15 -11 0 -30 -7 -40
-15 -11 -8 -28 -15 -38 -15 -10 0 -41 -11 -70 -25 -28 -14 -55 -25 -60 -25 -8
0 -168 -73 -198 -91 -8 -4 -44 -23 -80 -41 -36 -17 -81 -43 -100 -57 -43 -31
-65 -46 -106 -66 -17 -9 -42 -27 -55 -41 -12 -13 -26 -24 -31 -24 -10 0 -106
-72 -211 -159 -110 -89 -236 -212 -275 -266 -19 -27 -59 -75 -87 -105 -29 -30
-62 -72 -74 -93 -13 -20 -30 -43 -39 -50 -9 -6 -25 -29 -36 -49 -10 -20 -25
-39 -32 -42 -8 -3 -14 -14 -14 -24 0 -10 -11 -29 -25 -42 -14 -13 -25 -32 -25
-42 0 -10 -4 -18 -9 -18 -5 0 -13 -10 -17 -22 -4 -13 -24 -49 -45 -81 -22 -32
-39 -62 -39 -67 0 -4 -11 -28 -25 -51 -14 -24 -25 -50 -25 -57 0 -7 -18 -50
-40 -94 -22 -45 -40 -88 -40 -96 0 -8 -6 -26 -14 -40 -17 -34 -62 -175 -71
-225 -4 -20 -11 -40 -16 -43 -5 -3 -9 -22 -9 -42 -1 -20 -7 -48 -15 -62 -8
-14 -14 -44 -15 -68 0 -23 -4 -50 -10 -60 -12 -21 -32 -185 -34 -274 l-1 -68
2960 0 2960 0 -3 72 c-2 40 -7 93 -12 118 -11 56 -28 158 -39 230 -4 30 -13
68 -19 83 -7 16 -12 41 -12 57 0 16 -4 31 -9 35 -6 3 -13 26 -17 50 -3 25 -10
48 -15 51 -5 3 -9 20 -9 38 0 19 -7 39 -15 46 -8 7 -15 18 -15 25 0 7 -14 48
-31 91 -41 106 -43 111 -72 174 -14 30 -28 62 -31 70 -4 8 -15 29 -26 45 -11
17 -22 36 -25 43 -16 39 -25 55 -60 107 -32 49 -45 69 -54 90 -5 11 -25 40
-45 64 -20 23 -36 49 -36 56 0 7 -4 15 -10 17 -5 2 -24 24 -42 49 -100 141
-340 399 -423 454 -27 18 -68 51 -90 74 -21 22 -43 41 -49 41 -5 0 -31 18 -57
40 -26 22 -50 40 -54 40 -3 0 -21 11 -39 25 -18 14 -35 25 -38 25 -3 0 -33 18
-66 40 -63 42 -307 168 -402 208 -30 13 -70 30 -88 38 -18 8 -41 14 -50 14
-10 0 -22 4 -28 8 -13 12 -93 40 -137 48 -20 3 -40 10 -43 15 -3 5 -20 9 -37
9 -18 1 -43 7 -57 15 -14 8 -42 14 -63 15 -21 0 -47 5 -58 11 -25 13 -131 28
-264 38 -55 4 -108 11 -117 15 -10 5 -18 4 -18 0z'
            ></path>
            <path
              d='M543 3505 c3 -44 13 -125 22 -180 34 -206 31 -193 72 -345 25 -93 35
-124 49 -158 8 -18 14 -40 14 -50 0 -9 7 -26 15 -36 8 -11 15 -27 15 -36 0 -9
11 -40 25 -68 14 -29 25 -57 25 -64 0 -16 89 -196 100 -203 6 -4 10 -13 10
-22 0 -9 8 -26 19 -37 10 -12 29 -41 42 -66 13 -25 34 -59 46 -76 13 -17 23
-34 23 -39 0 -4 14 -23 30 -42 17 -18 30 -39 30 -44 0 -6 11 -20 25 -31 14
-11 25 -25 25 -32 0 -7 29 -46 65 -87 36 -40 65 -78 65 -84 0 -16 253 -260
350 -338 133 -106 187 -147 195 -147 4 0 23 -13 42 -30 18 -16 38 -30 44 -30
5 0 19 -9 31 -20 26 -24 124 -75 413 -216 28 -13 54 -24 59 -24 4 0 22 -6 39
-14 18 -8 55 -22 82 -31 28 -9 58 -21 67 -26 9 -5 35 -12 58 -15 22 -4 43 -11
46 -15 3 -5 20 -9 37 -9 18 0 41 -6 52 -14 11 -7 40 -16 65 -19 25 -3 65 -10
90 -15 57 -13 216 -37 365 -55 20 -2 20 4 20 1058 l0 1060 -957 3 c-929 2
-958 2 -958 -17 0 -10 22 -60 50 -112 27 -52 54 -104 59 -116 6 -11 15 -24 20
-28 6 -3 11 -13 11 -22 0 -8 11 -28 25 -45 14 -16 25 -35 25 -42 0 -7 11 -23
25 -36 14 -13 25 -31 25 -39 0 -9 18 -33 40 -55 22 -21 40 -44 40 -50 0 -17
302 -320 367 -367 32 -23 62 -48 66 -56 4 -7 13 -13 18 -13 6 0 38 -20 72 -44
34 -24 94 -61 132 -81 64 -34 97 -51 207 -106 20 -11 42 -19 48 -19 7 0 9 84
8 273 l-3 272 -159 3 c-152 2 -161 4 -192 27 -63 48 -314 309 -314 327 0 17
25 18 510 18 493 0 510 -1 520 -19 11 -22 15 -1197 3 -1315 l-6 -68 -53 5
c-30 3 -68 10 -86 15 -18 6 -60 18 -93 27 -33 9 -75 23 -93 31 -18 8 -39 14
-48 14 -8 0 -37 11 -64 25 -27 14 -54 25 -60 25 -23 0 -261 117 -328 162 -24
15 -46 28 -51 28 -4 0 -18 11 -31 25 -13 14 -31 25 -41 25 -9 0 -23 7 -30 16
-7 9 -36 31 -64 50 -105 72 -187 144 -341 302 -87 90 -163 171 -168 180 -5 9
-21 31 -36 47 -29 31 -63 78 -127 175 -19 30 -44 67 -54 82 -11 15 -19 34 -19
42 0 8 -4 17 -9 20 -8 5 -91 170 -113 226 -6 14 -14 32 -19 40 -5 8 -12 22
-15 30 -3 8 -13 33 -21 55 -8 22 -19 48 -24 57 -5 10 -9 28 -9 41 0 13 -7 32
-15 43 -8 10 -15 27 -15 38 0 10 -7 39 -15 65 -30 98 -37 128 -33 156 l3 30
2578 3 2579 2 -5 -57 c-9 -89 -18 -136 -33 -158 -8 -11 -14 -29 -14 -40 -1
-22 -33 -122 -44 -132 -3 -3 -6 -16 -6 -28 0 -12 -7 -30 -15 -41 -8 -10 -15
-25 -15 -33 0 -18 -133 -288 -165 -334 -14 -21 -25 -41 -25 -44 0 -3 -11 -21
-25 -39 -14 -18 -25 -37 -25 -41 0 -5 -13 -22 -30 -38 -16 -16 -30 -36 -30
-45 0 -9 -18 -34 -40 -55 -22 -21 -40 -44 -40 -50 0 -18 -367 -385 -440 -440
-36 -27 -67 -52 -70 -55 -7 -8 -178 -120 -183 -120 -3 0 -32 -18 -65 -40 -33
-22 -66 -40 -75 -40 -8 0 -20 -7 -27 -15 -7 -8 -16 -15 -21 -15 -5 0 -47 -18
-93 -40 -46 -22 -87 -40 -92 -40 -5 0 -31 -11 -59 -25 -27 -13 -57 -25 -65
-25 -8 0 -28 -7 -44 -15 -15 -8 -38 -15 -50 -15 -12 0 -27 -5 -32 -10 -6 -6
-33 -15 -60 -20 -27 -5 -72 -15 -100 -21 -93 -22 -84 -90 -82 693 l3 693 508
3 507 2 0 -21 c0 -19 -166 -194 -292 -307 l-48 -42 -165 0 -165 0 0 -280 c0
-154 3 -280 7 -280 3 0 14 6 22 13 9 8 32 19 51 26 52 19 83 32 100 42 8 5 25
13 37 19 12 5 45 25 72 45 28 19 57 35 64 35 8 0 22 9 32 20 11 12 36 31 57
42 21 12 38 25 38 29 0 4 17 18 38 30 86 53 375 342 457 459 46 66 123 182
138 210 60 108 128 253 125 268 -3 16 -57 17 -956 17 l-952 0 0 -1059 c0 -582
2 -1061 4 -1063 2 -3 14 0 25 6 11 7 71 16 133 21 136 11 223 24 261 39 16 6
45 11 66 11 20 0 41 4 47 10 10 10 79 28 146 37 21 3 42 11 49 19 6 8 23 14
37 14 14 0 33 6 41 13 9 7 34 19 56 26 48 16 101 38 137 57 14 8 35 14 46 14
12 0 24 5 27 11 4 5 17 14 28 20 12 5 29 13 37 18 8 5 29 15 45 22 17 7 37 18
45 25 8 6 40 25 70 41 30 16 81 47 114 68 32 22 70 47 85 55 14 8 28 18 31 22
3 4 29 24 57 45 145 107 211 164 346 300 81 82 147 154 147 160 0 6 24 38 53
71 50 58 99 124 123 164 6 10 21 29 33 42 11 12 21 26 21 31 0 4 19 38 42 74
51 80 208 388 208 407 0 8 11 37 25 66 14 28 25 62 25 74 0 12 7 27 15 34 8 7
15 25 15 40 0 14 3 30 7 34 8 8 29 83 42 151 4 22 13 50 19 62 7 12 12 41 12
65 0 24 5 49 11 55 6 6 13 46 16 89 3 44 9 86 13 94 5 8 9 55 11 103 l3 87
-2963 3 -2963 2 5 -80z'
            ></path>
          </g>
        </svg>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: 75
  },
  categoryName: {
    textAlign: 'center',
    color: 'white',
    fontSize: 40,
    padding: '0px 15px',
    fontWeight: 'bold',
    fontFamily: 'Cinzel Decorative',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28
    }
  },
  menuName: {
    fontSize: 18,
    padding: '0px 15px',
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    color: '#F4C347',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    }
  },
  categoryDescription: {
    textAlign: 'center',
    fontSize: 18,
    padding: '10px 15px',
    fontFamily: 'Source Sans Pro',
    color: 'grey'
  },
  sectionHeader: {
    marginBottom: 15
  }
}));

const MenuSection = (props) => {
  const classes = useStyles();

  // Props
  const { categoryName, children, categoryDescription } = props;

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item container direction='column' className={classes.sectionHeader}>
        <Typography className={classes.categoryName}>{categoryName}</Typography>
        <MongolianSVGs />
        <Typography className={classes.categoryDescription}>
          {categoryDescription}
        </Typography>
      </Grid>
      <Grid item container direction='row'>
        {children}
      </Grid>
    </Grid>
  );
};

export default MenuSection;
