// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA2sTpupVfhEaSsH0ostW6kXatCFbxaX-0',
  authDomain: 'lumos-webapp-mongo.firebaseapp.com',
  databaseURL: 'https://lumos-webapp-mongo.firebaseio.com',
  projectId: 'lumos-webapp-mongo',
  storageBucket: 'lumos-webapp-mongo.appspot.com',
  messagingSenderId: '719392552933',
  appId: '1:719392552933:web:231895237dc23738'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default firebase;

export { db };
