import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

// Component Imports
import Screen from './index';
import Context from './context';
import screenMenu from '../../store/menu';

const useStyles = makeStyles({
  screenRoot: {
    backgroundColor: 'black',
    height: '100%'
  }
});

const Wrapper = ({ ...other }) => {
  // Classes
  const classes = useStyles();

  return (
    <Context.Provider value={{ ...other }}>
      <div className={classes.screenRoot}>
        <Screen />
      </div>
    </Context.Provider>
  );
};

const mapState = (state) => ({
  menu: state.menu.menu,
  storeHours: state.global.storeHours
});

const mapDispatch = (dispatch) => ({
  getMenuData: (payload) => {
    dispatch({ type: screenMenu.actions.GET_MENU, payload });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);
