const affectedCartItemsInspector = (affectedItems) => {
  // Items to remove.
  const itemsToRemove = [];

  // Items affected to alert.
  const itemsToAlert = [];

  // Go through the affected items.
  affectedItems.forEach((obj) => {
    // Check each important attribute against each other.
    const { affectedItem: affected, updatedItem: updated, cartItem } = obj;

    // Important Attributes To Check

    // isActive
    if (affected.item.isActive !== updated.item.isActive) {
      // Remove this item from cart.
      itemsToRemove.push(cartItem);
    }

    // Price Monetary Fields
    if (
      affected.item.priceMonetaryFields.unitAmount !==
      updated.item.priceMonetaryFields.unitAmount
    ) {
      // Add this to alert the change. With the attribute name.
      itemsToAlert.push({
        affected,
        updated,
        cartItem,
        difference: 'priceMonetaryFields'
      });
    }

    // Name
    if (affected.item.name !== updated.item.name) {
      // Add this to alert the change. With the attribute name.
      itemsToAlert.push({ affected, updated, cartItem, difference: 'name' });
    }
  });

  return { itemsToRemove, itemsToAlert };
};

export default affectedCartItemsInspector;
