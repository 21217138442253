const addToCartValidator = (option) => {
  // Return true if has error and false if no error.
  switch (option.selectionMode) {
    case 'single_select':
      // No error for options that are not required
      if (!option.isRequired) {
        return false;
      }

      // If selected options is falsy then return true
      if (!option.selectedOptions) {
        return true;
      }

      // If selected option does not meet the minimum conditions return true
      if (option.selectedOptions.length !== option.minNumOptions) {
        return true;
      }
      return false;

    case 'multi_select':
      // No error for options that are not required
      if (!option.isRequired) {
        return false;
      }

      // If selected options is falsy then return true
      if (!option.selectedOptions) {
        return true;
      }

      // If selected option does not meet the minimum conditions return true
      if (option.selectedOptions.length !== option.minNumOptions) {
        return true;
      }
      return false;

    case 'multi_choice':
      // Multiple choice. Each choice has options.
      // Go through every choice selection and validate.

      // If selected options is falsy then return true
      if (!option.selectedOptions) {
        return true;
      }

      // Find how many required options and check for selected value
      const errorArray = [];
      const choiceOptions = option.selectedOptions;
      choiceOptions.forEach((option) => {
        if (!option.isRequired) {
          return;
        }
        if (!option.selectedOptions) {
          return errorArray.push(option.name);
        }
        if (option.selectedOptions.length !== option.minNumOptions) {
          return errorArray.push(option.name);
        }
      });

      // If there were errors return true
      if (errorArray.length > 0) {
        return true;
      }

      return false;
    default:
      return;
  }
};

export default addToCartValidator;
