import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AccountCircleIcon
        style={{ color: 'white', fontSize: 25, fontFamily: 'Source Sans Pro' }}
        aria-controls='account-menu'
        aria-haspopup='true'
        onClick={handleClick}
      />
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to='/myorders'
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem>My Orders</MenuItem>
        </Link>

        <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
