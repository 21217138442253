import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import mongologo from "../../assets/img/mongologo.png";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#212121",
  },
  logo: {
    height: 85,
    "@media (max-width:600px)": {
      height: 75,
    },
  },
  navLink: {
    flex: 1,
    textAlign: "center",
    height: 35,
    borderRight: "solid 1px #000500",
    borderTop: "solid 1px #000500",
    fontFamily: "Lato",
    fontWeight: 300,
    fontSize: 12,
    textDecoration: "none",
    color: "#b5b5b5",
  },
  orderButton: {
    color: "black",
    backgroundColor: "#F4C347",
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "Alata",
    "&:hover": {
      backgroundColor: "#D9B048",
    },
    "@media (max-width:600px)": {
      fontSize: 14,
    },
  },
  orderLink: {
    textDecoration: "none",
  },
});

const Nav = (props) => {
  const classes = useStyles();
  const { navItems } = props;
  return (
    <Grid className={classes.root}>
      <Grid item>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          <Grid item xs container direction="row" justify="center">
            <Link to="/">
              <img
                src={mongologo}
                className={classes.logo}
                alt="Sapporo Houston"
              />
            </Link>
          </Grid>
          <Grid item xs container direction="row" justify="center">
            <a
              href="https://order.toasttab.com/online/mongopearland"
              className={classes.orderLink}
            >
              <Button
                variant="contained"
                size="medium"
                className={classes.orderButton}
              >
                ORDER ONLINE
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item container direction="row" justify="space-around">
          {navItems.map((navItem) => (
            <NavLink
              key={navItem.to}
              to={navItem.to}
              activeStyle={{
                fontWeight: 400,
                fontSize: 12,
                color: "#FFFFFF",
                borderBottom: "solid 2px #F4C347",
              }}
              className={classes.navLink}
            >
              <p>{navItem.name}</p>
            </NavLink>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Nav;
