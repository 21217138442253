import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slide, useScrollTrigger } from '@material-ui/core';

// Context
import Context from './context';

// UI Components
import Nav from '../../components/global/Nav';
import MenuSection from '../../components/menu/MenuSection';
import MenuItems from '../../components/menu/MenuItems';

// Image Imports. Change these later
import image1 from '../../assets/img/home/beerwne.jpg';
import image2 from '../../assets/img/home/frozenmarg.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000500',
    height: '100%'
  },
  rootContainer: {
    marginBottom: 25
  },
  activeClassName: {
    color: 'blue',
    fontSize: 25
  },
  logo: {
    height: 65,
    width: 65,
    backgroundColor: 'red'
  },
  sectionRoot: {
    width: '100%',
    paddingTop: 85,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50
    }
  },
  image: {
    padding: 15,
    width: '80%',
    '@media (max-width:1920px)': {
      width: '90%'
    },
    '@media (max-width:768px)': {
      width: '100%'
    }
  },
  imageRoot: {
    padding: 15,
    width: '100%'
  },
  imagesContainer: {
    marginTop: 40
  }
}));

// Hide on scroll
const HideOnScroll = (props) => {
  // Props
  const { children, window } = props;

  // Trigger
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

const HappyHour = (props) => {
  // Classes
  const classes = useStyles();

  // Props
  const { menu } = useContext(Context);

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <div style={{ position: 'sticky', top: 0 }}>
          <Nav
            navItems={[
              { to: '/menu', name: 'MENU' },
              { to: '/happyhour', name: 'HAPPY HOUR' },
              { to: '/gallery', name: 'GALLERY' },
              { to: '/about', name: 'ABOUT' }
            ]}
          />
        </div>
      </HideOnScroll>
      <Grid
        container
        direction='row'
        justify='center'
        className={classes.imagesContainer}
      >
        <Grid
          className={classes.rootContainer}
          container
          direction='row'
          xs={12}
          sm={12}
          md={10}
          lg={8}
        >
          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            alignItems="center"
            justify="center"
            className={classes.imageRoot}
          >
            <img src={image1} alt="Happy Hour" className={classes.image} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            alignItems="center"
            justify="center"
            className={classes.imageRoot}
          >
            <img src={image2} alt="Happy Hour" className={classes.image} />
          </Grid> */}

          <section id={'happyHourDrinks'} className={classes.sectionRoot}>
            <MenuSection
              key={'happyHourDrinks'}
              categoryName={'Happy Hour Drinks'}
              menuName={'Happy Hour Menu'}
            >
              <MenuItems
                items={
                  menu &&
                  menu.filter((obj) => obj.type.value === 'happyHourDrinks')
                }
              />
            </MenuSection>
          </section>

          <section id={'hotAppetizers'} className={classes.sectionRoot}>
            <MenuSection
              key={'hotAppetizers'}
              categoryName={'Hot Appetizers'}
              menuName={'Happy Hour Menu'}
            >
              <MenuItems
                items={
                  menu &&
                  menu.filter((obj) => obj.type.value === 'hotAppetizers')
                }
              />
            </MenuSection>
          </section>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default HappyHour;
