import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  menu: [],
  calendar: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU:
      return {
        ...state,
        menu: action.menu
      };

    case actionTypes.SET_CALENDAR:
      return {
        ...state,
        calendar: action.data
      };

    case actionTypes.GET_MENU_FAILED:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          snackbar: true,
          message: 'Failed to retrieve menu. Please try again.'
        }
      };

    case actionTypes.SET_ITEMS_TO_REMOVE:
      return {
        ...state,
        menu: [...state.itemsToRemove, ...action.items]
      };

    case actionTypes.SET_ITEMS_TO_ALERT:
      return {
        ...state,
        menu: [...state.itemsToAlert, ...action.items]
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'menu',
  storage: localforage,
  blacklist: ['calendar', 'menu']
};

export default persistReducer(persistConfig, reducer);
