import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(to top, #f2f7f7 0%, #eeeeee 100%);',
    borderRadius: 5,
    maxWidth: 300,
    cursor: 'pointer',
    height: '80%',
    padding: 5
  },
  selectedValue: {
    fontSize: 18,
    fontFamily: 'Source Sans Pro',
    fontWeight: '600',
    whiteSpace: 'nowrap'
  },
  label: {
    fontSize: 12,
    color: '#F34213',
    fontFamily: 'Source Sans Pro'
  },
  icon: {
    fontSize: 25,
    color: '#F34213'
  },
  dropDownIcon: {
    fontSize: 25,
    position: 'relative',
    right: 10
  }
});

const SelectButton = (props) => {
  const classes = useStyles();

  const { value, onClick } = props;

  return (
    <Grid
      container
      direcion='row'
      alignItems='center'
      className={classes.root}
      onClick={onClick}
    >
      <Grid item xs={2} container direction='column' style={{ padding: 5 }}>
        <RestaurantMenuIcon className={classes.icon} />
      </Grid>
      <Grid item xs={9} container direction='column' style={{ padding: 5 }}>
        <Typography className={classes.selectedValue}>{value}</Typography>
      </Grid>
      <Grid item xs={1} container direction='column'>
        <ArrowDropDownIcon className={classes.dropDownIcon} />
      </Grid>
    </Grid>
  );
};

export default SelectButton;
