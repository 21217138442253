import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";

// Context
import Context from "./context";

// UI Components
import Nav from "../../components/global/Nav";
import Bar from "../../components/myorders/Bar";
import OrderItem from "../../components/myorders/OrderItem";

import { ArrowBack, Fastfood } from "@material-ui/icons";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F34213",

    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#F34213",
    },
    "& p": {
      color: "white",
      fontFamily: "Source Sans Pro",
      fontSize: 17,
      fontWeight: "bold",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: { padding: 20, height: "100%", width: "100%" },
  emptyText: {
    fontFamily: "Source Sans Pro",
    color: "black",
    fontWeight: "bold",
  },
  emptyIcon: {
    fontSize: 65,
  },
  promotionalBanner: {
    backgroundColor: "#B5FFB5",
    height: 120,
    width: "100%",
    padding: 20,
  },
  promotionalText: {
    fontFamily: "Source Sans Pro",
    fontSize: 17,
    textAlign: "center",
  },
  promotionalSubText: {
    fontFamily: "Source Sans Pro",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const NoOrdersData = ({ classes, history, height, message }) => {
  return (
    <Grid
      style={{ height: height }}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          color: "black",
          fontFamily: "Source Sans Pro",
          fontSize: 13,
        }}
      >
        <Fastfood className={classes.emptyIcon} />
        <Typography className={classes.emptyText}>
          {message ? message : "YOU HAVE NO ORDERS"}
        </Typography>
        <ColorButton
          variant="text"
          size="small"
          onClick={() => history.push("/order")}
        >
          <Grid
            container
            direcion="row"
            alignItems="center"
            justify="space-between"
          >
            <ArrowBack
              style={{
                fontSize: 20,
                color: "white",
                fontWeight: "bold",
                marginRight: 10,
              }}
            />
            <Typography>ORDER NOW</Typography>
          </Grid>
        </ColorButton>
      </Grid>
    </Grid>
  );
};

const Screen = (props) => {
  // Classes
  const classes = useStyles();

  // Map To state props
  const {
    handleAuthLogOut,
    initCheckout,
    isSignedIn,
    tabValue,
    onTabValueChanged,
    orders,
    isLoading,
  } = useContext(Context);

  // History
  // Router History
  const history = useHistory();

  // Handle if there is no user.
  if (!isSignedIn) {
    return <Redirect to="/order" />;
  }

  if ((!orders || orders.length === 0) && !isLoading) {
    return (
      <>
        <div style={{ position: "absolute", width: "100%" }}>
          <Nav
            navItems={[
              { to: "/menu", name: "MENU" },
              { to: "/happyhour", name: "HAPPY HOUR" },
              { to: "/gallery", name: "GALLERY" },
              { to: "/about", name: "ABOUT" },
            ]}
            handleAuthLogOut={handleAuthLogOut}
            isSignedIn={isSignedIn}
            initCheckout={() => initCheckout(false)}
          />
        </div>

        <NoOrdersData history={history} classes={classes} height="100vh" />
      </>
    );
  }

  // On Going Orders
  const onGoingOrders = orders.filter(
    (obj) => obj.status.value === "incomplete"
  );

  return (
    <React.Fragment>
      <Nav
        navItems={[
          { to: "/menu", name: "MENU" },
          { to: "/happyhour", name: "HAPPY HOUR" },
          { to: "/gallery", name: "GALLERY" },
          { to: "/about", name: "ABOUT" },
        ]}
        handleAuthLogOut={handleAuthLogOut}
        isSignedIn={isSignedIn}
        initCheckout={() => initCheckout(false)}
      />
      <Bar tabValue={tabValue} onTabValueChanged={onTabValueChanged} />
      <Grid container justify="center" alignItems="center" direction="row">
        {tabValue === 0 && onGoingOrders.length !== 0 && (
          <Grid
            container
            direction="column"
            className={classes.promotionalBanner}
            justify="space-around"
            alignitems="center"
          >
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignitems="center"
            >
              <Typography className={classes.promotionalText}>
                Thank you for ordering with us!
              </Typography>
              <Typography className={classes.promotionalSubText}>
                We are getting your{" "}
                {onGoingOrders && onGoingOrders.length > 1 ? "orders" : "order"}{" "}
                ready!
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          container
          xs={12}
          md={10}
          lg={8}
          xl={5}
          direction="column"
          className={classes.root}
          justify="center"
          alignItems="center"
        >
          {tabValue === 0 && onGoingOrders.length === 0 && (
            <NoOrdersData
              history={history}
              classes={classes}
              height="70vh"
              message={"NO ACTIVE ORDERS"}
            />
          )}
          {orders &&
            !isLoading &&
            orders.length > 0 &&
            // eslint-disable-next-line array-callback-return
            orders.map((orderObj) => {
              // Order Status
              const { status } = orderObj;

              if (tabValue === 0 && status && status.value === "incomplete") {
                return <OrderItem order={orderObj} />;
              }

              if (tabValue === 1 && status && status.value !== "incomplete") {
                return <OrderItem order={orderObj} />;
              }
            })}
          {isLoading && (
            <>
              <OrderItem isSkeleton />
              <OrderItem isSkeleton />
              <OrderItem isSkeleton />
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Screen;
