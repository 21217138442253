const totalItemPrice = (quantity, price, optionsForm) => {
  // quantity: number
  // price: object{ unitAmount: cents:number, displayString: string }

  // Calculate quantity and unit price
  const { unitAmount } = price;

  let newAmount = Math.round(parseFloat(unitAmount).toFixed(2));

  // Return if no options.
  if (!optionsForm) {
    return parseFloat((newAmount * quantity) / 100).toFixed(2);
  }

  // Calculate options addition
  let addedCost = { unitAmount: 0 };

  // Convert options to object array
  const optionsKeys = Object.keys(optionsForm);
  const optionsArray = optionsKeys.map((key) => {
    return {
      name: key,
      ...optionsForm[key]
    };
  });

  optionsArray.forEach((option) => {
    if (!option.selectedOptions) {
      return;
    }
    switch (option.selectionMode) {
      case 'multi_choice':
        option.selectedOptions.forEach((selectedOption) => {
          if (!selectedOption.selectedOptions) {
            return;
          }
          selectedOption.selectedOptions.forEach((option) => {
            addedCost.unitAmount += option.priceMonetaryFields.unitAmount;
          });
        });
        break;

      default:
        option.selectedOptions.forEach((selectedOption) => {
          addedCost.unitAmount += selectedOption.priceMonetaryFields.unitAmount;
        });
        break;
    }
  });

  return parseFloat(
    ((newAmount + addedCost.unitAmount) * quantity) / 100
  ).toFixed(2);
};

const totalCalculator = (cart, discount, taxRate) => {
  let subTotal = 0.0;

  try {
    cart.forEach((cartItem) => {
      const { menuItem } = cartItem;
      const { item } = menuItem;
      parseFloat(
        (subTotal += parseFloat(
          totalItemPrice(
            cartItem.quantity,
            item.priceMonetaryFields,
            cartItem.optionsForm
          )
        ))
      );
    });
  } catch (error) {
    subTotal = 0;
  }

  // Subtotal
  let subTotalAmount = Math.round(parseFloat(subTotal * 100).toFixed(2));

  // If discount.
  if (discount) {
    // Desconstruct discount object.
    const { discountPriceMonetaryFields, requiredAmount } = discount;

    // Validate Discount with subtotal.

    if (requiredAmount.unitAmount > subTotalAmount) {
      return {
        hasError: true,
        error: `Order subtotal ($${parseFloat(subTotalAmount / 100).toFixed(
          2
        )}) is less than the required subtotal ($${
          requiredAmount.displayString
        }).`
      };
    } else {
      subTotalAmount = subTotalAmount - discountPriceMonetaryFields.unitAmount;
    }
  }

  // Subtotal
  const displaySubTotal = parseFloat(subTotalAmount / 100).toFixed(2);

  const total = Math.round(parseFloat(subTotalAmount * taxRate));

  const displayTotal = parseFloat(total / 100).toFixed(2);

  const taxAmount = Math.round(
    parseFloat(subTotalAmount * parseFloat(taxRate - 1.0))
  );

  const displayTax = parseFloat(taxAmount / 100).toFixed(2);

  return {
    total: {
      displayString: displayTotal,
      unitAmount: total
    },
    subTotal: {
      displayString: displaySubTotal,
      unitAmount: subTotalAmount
    },
    tax: {
      displayString: displayTax,
      unitAmount: taxAmount
    }
  };
};

export default totalCalculator;
