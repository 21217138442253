import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import FormInputs from '../FormInputs';

const useStyles = makeStyles((theme) => ({
  root: {},
  labelContainer: {
    padding: '5px 0px'
  },
  label: {
    color: 'black',
    fontSize: 17,
    fontWeight: '600',
    fontFamily: 'Source Sans Pro'
  },
  labelSub: {
    color: 'grey',
    fontSize: 15,
    fontFamily: 'Source Sans Pro'
  },
  formControlRadio: {
    fontSize: 15,
    fontFamily: 'Source Sans Pro',
    padding: '5px 0px'
  },
  errorSub: {
    color: 'red',
    fontSize: 15,
    fontFamily: 'Source Sans Pro'
  },
  choiceContainer: {
    backgroundColor: '#F6F6F6',
    borderRadius: 5,
    marginBottom: 15,
    padding: 10
  }
}));

const MultiChoice = ({
  onChange,
  isRequired,
  label,
  choices,
  error,
  timeoutError
}) => {
  const classes = useStyles();
  const [optionsForm, setOptionsForm] = useState(null);
  const inputRef = React.createRef(null);
  // Generate a form object to validate when item is added to cart.
  useEffect(() => {
    // If no available options return.
    if (!choices) {
      return;
    }

    // Genrate options from from Item options to validate later.
    const newOptionsForm = {};
    choices.forEach((option) => {
      newOptionsForm[option.name] = {
        isRequired: option.isRequired,
        minNumOptions: option.minNumOptions,
        maxNumOptions: option.maxNumOptions,
        selectedOptions: option.defaultOptions || null
      };
    });

    // Set new options form in local state.
    setOptionsForm(newOptionsForm);
  }, [choices]);

  // Handle form data. Starts as null. Filled in as users inputs data.
  // Accepts option object and option value.
  const handleOptionChange = (option, optionValue) => {
    // Spread options form into a new one to avoide mutation.
    const changedOptionsForm = { ...optionsForm };
    changedOptionsForm[option.name].selectedOptions = optionValue;
    setOptionsForm(changedOptionsForm);

    // Convert form into array of objects.
    const optionsKeys = Object.keys(changedOptionsForm);
    const optionsArray = optionsKeys.map((key) => {
      return {
        name: key,
        ...changedOptionsForm[key]
      };
    });

    onChange(optionsArray);
  };

  // Renders forms for item conditionally.
  const choiceForms = (choice) => {
    switch (choice.selectionMode) {
      case 'single_select':
        return (
          <>
            <FormInputs.SingleSelect
              onChange={(value) => handleOptionChange(choice, value)}
              isRequired={choice.isRequired}
              label={choice.name}
              options={choice.options}
              maxValue={choice.maxNumOptions}
            />
            <br></br>
          </>
        );
      case 'multi_select':
        return (
          <>
            <FormInputs.MultiSelect
              onChange={(value) => handleOptionChange(choice, value)}
              isRequired={choice.isRequired}
              label={choice.name}
              options={choice.options}
              maxValue={choice.maxNumOptions}
            />
            <br></br>
          </>
        );
      default:
        return null;
    }
  };

  // Scroll into view if there is an error. TODO
  // useEffect(() => {
  //   if (error) {
  //     inputRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start'
  //     });
  //   }
  // }, [error, inputRef]);

  return (
    <Grid container direction='column' ref={inputRef}>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={classes.labelContainer}
      >
        <Typography className={classes.label}>{label}</Typography>
        {error && (
          <Typography className={classes.errorSub}>
            Select {choices.length} choices
          </Typography>
        )}
      </Grid>
      <Grid container direction='row' justify='flex-start' alignItems='center'>
        {choices.map((choice) => {
          return (
            <Grid
              item
              xs={12}
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              className={classes.choiceContainer}
            >
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.labelContainer}
              >
                {choiceForms(choice)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MultiChoice;
