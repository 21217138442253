import React from 'react';
import { connect } from 'react-redux';
import Checkout from './Checkout';
import Context from './context';
import screenCheckout from '../../store/checkout';
import screenOrder from '../../store/order';
import screenMenu from '../../store/menu';

const Wrapper = ({ ...other }) => {
  const { stripe, elements } = other;
  return (
    <Context.Provider value={{ ...other, stripe, elements }}>
      <Checkout />
    </Context.Provider>
  );
};

const mapState = (state) => ({
  cart: state.order.cart,
  contactFields: state.checkout.contactFields,
  shippingFields: state.checkout.shippingFields,
  activeStep: state.checkout.activeStep,
  shippingOptions: state.checkout.shippingOptions,
  selectedShipping: state.checkout.selectedShipping,
  infoLoading: state.checkout.infoLoading,
  shippingLoading: state.checkout.shippingLoading,
  paymentLoading: state.checkout.paymentLoading,
  shippingOptionError: state.checkout.shippingOptionError,
  errorMessage: state.checkout.errorMessage,
  newOrderSummary: state.checkout.newOrderSummary,
  timePickerModalIsOpen: state.checkout.timePickerModalIsOpen,
  selectedPickUpTime: state.checkout.selectedPickUpTime,
  pickUpTimeError: state.checkout.pickUpTimeError,
  isSignedIn: state.auth.isSignedIn,
  storeHours: state.global.storeHours,
  storeActions: state.global.storeActions,
  calendar: state.menu.calendar
});

const mapDispatch = (dispatch) => ({
  removeFromCart: (item) => {
    dispatch({ type: screenOrder.actions.REMOVE_FROM_CART, item });
  },
  setContactFields: (fields) => {
    dispatch({ type: screenCheckout.actions.SET_CONTACT_FIELDS, fields });
  },
  setShippingFields: (fields) => {
    dispatch({ type: screenCheckout.actions.SET_SHIPPING_FIELDS, fields });
  },
  setActiveStep: (step) => {
    dispatch({ type: screenCheckout.actions.SET_ACTIVE_STEP, step });
  },
  setShippingOption: (option) => {
    dispatch({ type: screenCheckout.actions.SET_SHIPPING_OPTION, option });
  },
  onInfoValidation: (step) => {
    dispatch({ type: screenCheckout.actions.INFO_VALIDATION, step });
  },
  onShippingValidation: (step) => {
    dispatch({ type: screenCheckout.actions.SHIPPING_VALIDATION, step });
  },
  onPaymentValidation: (payload) => {
    dispatch({ type: screenCheckout.actions.PAYMENT_VALIDATION, payload });
  },
  onResetProductInfo: () => {
    dispatch({ type: screenOrder.actions.RESET_PRODUCT_INFO });
  },
  onResetCheckoutToInitialState: () => {
    dispatch({ type: screenCheckout.actions.RESET_CHECKOUT_TO_INITIAL_STATE });
  },
  toggleTimePickerModal: (modalAction) => {
    dispatch({
      type: screenCheckout.actions.TOGGLE_TIME_PICKER_MODAL,
      modalAction
    });
  },
  selectPickUpTime: (time) => {
    dispatch({
      type: screenCheckout.actions.SET_PICK_UP_TIME,
      time
    });
  },
  onSetErrorMessage: (message) => {
    dispatch({ type: screenCheckout.actions.SET_ERROR_MESSAGE, message });
  },
  resetOrderStateToInitialState: () => {
    dispatch({ type: screenOrder.actions.RESET_ORDER_STATE_TO_INITIAL_STATE });
  },
  onClearErrors: () => {
    dispatch({ type: screenCheckout.actions.CLEAR_FORM_ERRORS });
  },
  onRemoveInactiveItems: (items) => {
    dispatch({ type: screenMenu.actions.HANDLE_REMOVE_INACTIVE_ITEMS, items });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);
