import React from 'react';
import { Grid, useScrollTrigger, Slide } from '@material-ui/core';

// UI Imports
import Nav from '../../../components/global/Nav';

// Hide on scroll
const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const PrivacyPolicy = (props) => {
  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <div style={{ position: 'sticky', top: 0 }}>
          <Nav
            navItems={[
              { to: '/menu', name: 'MENU' },
              { to: '/happyhour', name: 'HAPPY HOUR' },
              { to: '/gallery', name: 'GALLERY' },
              { to: '/about', name: 'ABOUT' }
            ]}
          />
        </div>
      </HideOnScroll>
      <Grid
        container
        style={{ padding: '50px', fontFamily: 'Source Sans Pro' }}
      >
        <p>PRIVACY POLICY</p> <br></br>
        <p>Last updated February 09, 2021 </p> <br></br>
        <p>
          Thank you for choosing to be part of our community at Mongo . We are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about our policy, or
          our practices with regards to your personal information, please
          contact us at Mongo. When you visit our and use our services, you
          trust us with your personal information. We take your privacy very
          seriously. In this privacy policy, we describe our privacy policy. We
          seek to explain to you in the clearest way possible what information
          we collect, how we use it and what rights you have in relation to it.
          We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy policy that you do
          not agree with, please discontinue use of our and our services. This
          privacy policy applies to all information collected through our and/or
          any related services, sales, marketing or events (we refer to them
          collectively in this privacy policy as the "Services"). Please read
          this privacy policy carefully as it will help you make informed
          decisions about sharing your personal information with us.
        </p>{' '}
        <br></br>{' '}
        <p>
          TABLE OF CONTENTS <br></br> 1. WHAT INFORMATION DO WE COLLECT?{' '}
          <br></br>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE? <br></br>3.
          DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? <br></br>4. HOW DO
          WE HANDLE YOUR SOCIAL LOGINS? <br></br>5. IS YOUR INFORMATION
          TRANSFERRED INTERNATIONALLY? <br></br>6. WHAT IS OUR STANCE ON
          THIRD-PARTY WEBSITES? <br></br>7. HOW LONG DO WE KEEP YOUR
          INFORMATION? <br></br>
          8. DO WE COLLECT INFORMATION FROM MINORS? <br></br>9. WHAT ARE YOUR
          PRIVACY RIGHTS?
          <br></br> 10. CONTROLS FOR DO-NOT-TRACK FEATURES <br></br> 11. DO WE
          MAKE UPDATES TO THIS POLICY?
          <br></br>12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?{' '}
        </p>{' '}
        <br></br>
        <p>
          {' '}
          1. WHAT INFORMATION DO WE COLLECT? <br></br>Personal information you
          disclose to us In Short: We collect personal information that you
          provide to us such as name, address, contact information, passwords
          and security data, and payment information. We collect personal
          information that you voluntarily provide to us when registering at the
          expressing an interest in obtaining information about us or our
          products and services, when participating in activities on the (such
          as posting messages in our online forums or entering competitions,
          contests or giveaways) or otherwise contacting us. The personal
          information that we collect depends on the context of your
          interactions with us and the , the choices you make and the products
          and features you use. The personal information we collect can include
          the following: Name and Contact Data. We collect your first and last
          name, email address, postal address, phone number, and other similar
          contact data. Credentials. We collect passwords, password hints, and
          similar security information used for authentication and account
          access. Social Media Login Data. We provide you with the option to
          register using social media account details, like your Facebook,
          Twitter or other social media account. If you choose to register in
          this way, we will collect the Information described in the section
          called "HOW DO WE HANDLE YOUR SOCIAL LOGINS " below. All personal
          information that you provide to us must be true, complete and
          accurate, and you must notify us of any changes to such personal
          information. Information automatically collected In Short: Some
          information – such as IP address and/or browser and device
          characteristics – is collected automatically when you visit our . We
          automatically collect certain information when you visit, use or
          navigate the . This information does not reveal your specific identity
          (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when
          you use our and other technical information. This information is
          primarily needed to maintain the security and operation of our , and
          for our internal analytics and reporting purposes. Like many
          businesses, we also collect information through cookies and similar
          technologies.{' '}
        </p>{' '}
        <br></br>{' '}
        <p>
          2. WILL YOUR INFORMATION BE SHARED WITH ANYONE? <br></br>In Short: We
          only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations. We may process or share data based on the
          following legal basis: Consent: We may process your data if you have
          given us specific consent to use your personal information in a
          specific purpose. Legitimate Interests: We may process your data when
          it is reasonably necessary to achieve our legitimate business
          interests. Performance of a Contract: Where we have entered into a
          contract with you, we may process your personal information to fulfill
          the terms of our contract. Legal Obligations: We may disclose your
          information where we are legally required to do so in order to comply
          with applicable law, governmental requests, a judicial proceeding,
          court order, or legal process, such as in response to a court order or
          a subpoena (including in response to public authorities to meet
          national security or law enforcement requirements). Vital Interests:
          We may disclose your information where we believe it is necessary to
          investigate, prevent, or take action regarding potential violations of
          our policies, suspected fraud, situations involving potential threats
          to the safety of any person and illegal activities, or as evidence in
          litigation in which we are involved. More specifically, we may need to
          process your data or share your personal information in the following
          situations: Vendors, Consultants and Other Third-Party Service
          Providers. We may share your data with third party vendors, service
          providers, contractors or agents who perform services for us or on our
          behalf and require access to such information to do that work.
          Examples include: payment processing, data analysis, email delivery,
          hosting services, customer service and marketing efforts. We may allow
          selected third parties to use tracking technology on the , which will
          enable them to collect data about how you interact with the over time.
          This information may be used to, among other things, analyze and track
          data, determine the popularity of certain content and better
          understand online activity. Unless described in this Policy, we do not
          share, sell, rent or trade any of your information with third parties
          for their promotional purposes. Business Transfers. We may share or
          transfer your information in connection with, or during negotiations
          of, any merger, sale of company assets, financing, or acquisition of
          all or a portion of our business to another company. Third-Party
          Advertisers. We may use third-party advertising companies to serve ads
          when you visit the . These companies may use information about your
          visits to our Website(s) and other websites that are contained in web
          cookies and other tracking technologies in order to provide
          advertisements about goods and services of interest to you.
          Affiliates. We may share your information with our affiliates, in
          which case we will require those affiliates to honor this privacy
          policy. Affiliates include our parent company and any subsidiaries,
          joint venture partners or other companies that we control or that are
          under common control with us. Business Partners. We may share your
          information with our business partners to offer you certain products,
          services or promotions. Other Users. When you share personal
          information or otherwise interact with public areas of the , such
          personal information may be viewed by all users and may be publicly
          distributed outside the in perpetuity. If you interact with other
          users of our and register through a social network (such as Facebook),
          your contacts on the social network will see your name, profile photo,
          and descriptions of your activity. Similarly, other users will be able
          to view descriptions of your activity, communicate with you within our
          , and view your profile.{' '}
        </p>{' '}
        <p>
          3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? <br></br>In
          Short: We may use cookies and other tracking technologies to collect
          and store your information. We may use cookies and similar tracking
          technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies
          and how you can refuse certain cookies is set out in our Cookie
          Policy.
        </p>{' '}
        <p>
          4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? <br></br>In Short: If you
          choose to register or log in to our services using a social media
          account, we may have access to certain information about you. Our
          offer you the ability to register and login using your third party
          social media account details (like your Facebook or Twitter logins).
          Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          Information we receive may vary depending on the social media provider
          concerned, but will often include your name, e-mail address, friends
          list, profile picture as well as other information you choose to make
          public. We will use the information we receive only for the purposes
          that are described in this privacy policy or that are otherwise made
          clear to you on the . Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third
          party social media provider. We recommend that you review their
          privacy policy to understand how they collect, use and share your
          personal information, and how you can set your privacy preferences on
          their sites and apps.
        </p>{' '}
        <p>
          5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? <br></br>In Short:
          We may transfer, store, and process your information in countries
          other than your own. Our servers are located in. If you are accessing
          our from outside, please be aware that your information may be
          transferred to, stored, and processed by us in our facilities and by
          those third parties with whom we may share your personal information
          (see " WILL YOUR INFORMATION BE SHARED WITH ANYONE? " above), in and
          other countries. If you are a resident in the European Economic Area,
          then these countries may not have data protection or other laws as
          comprehensive as those in your country. We will however take all
          necessary measures to protect your personal information in accordance
          with this privacy policy and applicable law.
        </p>
        <p>
          6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?<br></br> In Short: We
          are not responsible for the safety of any information that you share
          with third-party providers who advertise, but are not affiliated with,
          our websites. The may contain advertisements from third parties that
          are not affiliated with us and which may link to other websites,
          online services or mobile applications. We cannot guarantee the safety
          and privacy of data you provide to any third parties. Any data
          collected by third parties is not covered by this privacy policy. We
          are not responsible for the content or privacy and security practices
          and policies of any third parties, including other websites, services
          or applications that may be linked to or from the . You should review
          the policies of such third parties and contact them directly to
          respond to your questions.
        </p>{' '}
        <p>
          7. HOW LONG DO WE KEEP YOUR INFORMATION? <br></br>In Short: We keep
          your information for as long as necessary to fulfill the purposes
          outlined in this privacy policy unless otherwise required by law. We
          will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than your
          account usage . When we have no ongoing legitimate business need to
          process your personal information, we will either delete or anonymize
          it, or, if this is not possible (for example, because your personal
          information has been stored in backup archives), then we will securely
          store your personal information and isolate it from any further
          processing until deletion is possible.
        </p>{' '}
        <p>
          8. DO WE COLLECT INFORMATION FROM MINORS? <br></br>In Short: We do not
          knowingly collect data from or market to children under 18 years of
          age. We do not knowingly solicit data from or market to children under
          18 years of age. By using the , you represent that you are at least 18
          or that you are the parent or guardian of such a minor and consent to
          such minor dependent’s use of the .{' '}
        </p>{' '}
        <p>
          {' '}
          9. WHAT ARE YOUR PRIVACY RIGHTS? <br></br> In Short: You may review,
          change, or terminate your account at any time. If you are resident in
          the European Economic Area and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          Account Information If you would at any time like to review or change
          the information in your account or terminate your account, you can:
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements. Opting out of
          email marketing: You can unsubscribe from our marketing email list at
          any time by clicking on the unsubscribe link in the emails that we
          send or by contacting us using the details provided below. You will
          then be removed from the marketing email list – however, we will still
          need to send you service-related emails that are necessary for the
          administration and use of your account. To otherwise opt-out, you may:
        </p>{' '}
        <p>
          10. CONTROLS FOR DO-NOT-TRACK FEATURES <br></br> Most web browsers and
          some mobile operating systems and mobile applications include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected. No uniform technology standard for
          recognizing and implementing DNT signals has been finalized. As such,
          we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be
          tracked online. If a standard for online tracking is adopted that we
          must follow in the future, we will inform you about that practice in a
          revised version of this privacy policy.
        </p>{' '}
        <p>
          11. DO WE MAKE UPDATES TO THIS POLICY? <br></br>In Short: Yes, we will
          update this policy as necessary to stay compliant with relevant laws.
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </p>{' '}
        <p>
          12. HOW CAN YOU CONTACT US ABOUT THIS POLICY? <br></br> If you have
          questions or comments about this policy, you may email us at
          mongopearland@gmail.com. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
          DATA WE COLLECT FROM YOU? Based on the laws of some countries, you may
          have the right to request access to the personal information we
          collect from you, change that information, or delete it in some
          circumstances. To request to review, update, or delete your personal
          information, please submit a request form by clicking here. We will
          respond to your request within 30 days.
        </p>
      </Grid>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
