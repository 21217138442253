import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  menuList: [],
  cart: [],
  cartId: 1,
  modalIsOpen: false,
  modalIsLoading: false,
  selectedItem: null,
  modalHasError: false,
  modalErrorArray: [],
  cartDrawerIsOpen: false,
  cartDrawerIsLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CART:
      return {
        ...state,
        cart: action.cart
      };

    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        modalIsLoading: true,
        modalHasError: false,
        modalErrorArray: []
      };

    case actionTypes.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        modalIsOpen: false,
        modalIsLoading: false,
        modalHasError: false,
        modalErrorArray: [],
        cart: action.cart,
        cartId: state.cartId + 1
      };

    case actionTypes.ADD_TO_CART_FAILED:
      return {
        ...state,
        modalIsLoading: false,
        modalHasError: true,
        modalErrorArray: action.errorArray
      };

    case actionTypes.REMOVE_FROM_CART:
      const { item: cartItem } = action;

      const prevCart = [...state.cart];

      const newCart = prevCart.filter(
        (item) => item.cartId !== cartItem.cartId
      );

      return {
        ...state,
        cart: newCart
      };

    case actionTypes.RESET_ORDER_STATE_TO_INITIAL_STATE:
      return initialState;

    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        menuList: [...state.menuList, ...action.products]
      };

    case actionTypes.HANDLE_MODIFIED_PRODUCTS:
      const modifiedIds = [
        ...new Set(action.products.map((product) => product.docId))
      ];
      const updatedProducts = state.menuList.filter(
        (product) => !modifiedIds.includes(product.docId)
      );
      return {
        ...state,
        menuList: [...updatedProducts, ...action.products]
      };

    case actionTypes.RESET_PRODUCT_INFO:
      return {
        ...state,
        menuList: []
      };

    case actionTypes.TOGGLE_MODAL:
      const { modalAction, menuItem } = action.payload;
      if (modalAction === false) {
        return {
          ...state,
          modalIsOpen: false,
          selectedItem: null,
          modalIsLoading: false,
          modalHasError: false,
          modalErrorArray: []
        };
      }
      return {
        ...state,
        modalIsOpen: modalAction,
        selectedItem: menuItem
      };

    case actionTypes.TOGGLE_CART_DRAWER:
      const drawerAction = action.payload;
      if (drawerAction === false) {
        return {
          ...state,
          cartDrawerIsOpen: false,
          cartDrawerIsLoading: false
        };
      }
      return {
        ...state,
        cartDrawerIsOpen: drawerAction
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'order',
  storage: localforage,
  blacklist: [
    'menuList',
    'cart',
    'cartId',
    'modalIsOpen',
    'selectedItem',
    'modalIsLoading',
    'modalHasError',
    'modalErrorArray',
    'cartDrawerIsOpen',
    'cartDrawerIsLoading'
  ]
};

export default persistReducer(persistConfig, reducer);
