import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton } from '@material-ui/core';
import { NavLink, Link } from 'react-router-dom';
import MongoLogo from '../../assets/img/mongologo.png';
import AccountMenu from '../order/AccountMenu';

// Material UI icons
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#212121'
  },
  logo: {
    height: 120,
    '@media (max-width:600px)': {
      height: 80
    }
  },
  navLink: {
    flex: 1,
    textAlign: 'center',
    height: 35,
    borderRight: 'solid 1px #000500',
    borderTop: 'solid 1px #000500',
    fontFamily: 'Lato',
    fontWeight: 300,
    fontSize: 12,
    textDecoration: 'none',
    color: '#b5b5b5'
  },
  orderButton: {
    color: 'black',
    backgroundColor: '#000000',
    fontWeight: 600,
    fontSize: 16,
    fontFamily: 'Alata',
    '@media (max-width:600px)': {
      fontSize: 14
    }
  },
  orderLink: {
    textDecoration: 'none'
  },
  loginButton: {
    color: 'white',
    fontSize: 17,
    '@media (max-width:600px)': {
      fontSize: 14
    }
  }
});

const Nav = (props) => {
  // Styles
  const classes = useStyles();

  // Props
  const { navItems, handleAuthLogOut, isSignedIn, initCheckout } = props;

  return (
    <Grid containerdirection="column" className={classes.root}>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        style={{ padding: 5 }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={8}
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          <Grid item xs container direction="row" justify="center"></Grid>
          <Grid item xs container direction="row" justify="center">
            <Link to="/">
              <img
                src={MongoLogo}
                className={classes.logo}
                alt="Sapporo Houston"
              />
            </Link>
          </Grid>
          <Grid item xs container direction="row" justify="flex-end">
            {isSignedIn ? (
              <IconButton className="accountIcon" color="inherit">
                <AccountMenu handleLogout={handleAuthLogOut} />
              </IconButton>
            ) : (
              <Button
                onClick={initCheckout}
                endIcon={<AccountBoxIcon style={{ color: 'white' }} />}
                className={classes.loginButton}
              >
                Login
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="space-around">
          {navItems.map((navItem) => (
            <NavLink
              key={navItem.to}
              to={navItem.to}
              activeStyle={{
                fontWeight: 400,
                fontSize: 12,
                color: '#FFFFFF',
                borderBottom: 'solid 2px #F4C346'
              }}
              className={classes.navLink}
            >
              <p>{navItem.name}</p>
            </NavLink>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Nav;
