import moment from 'moment';

export default function getIsMenuActive(timeFrame, weeklySchedule, isAllDay) {
  /* 
    0-6 Moment days
    0 - Sunday
    6 - Saturday

    All times are exported in h:mmA format (12 HOUR FORMAT)
  */

  if (!weeklySchedule) {
    return { isActive: false };
  }

  if (isAllDay) {
    return { isActive: true };
  }

  //   Current time
  const currentTime = moment();

  //   Current day in moment number.
  const currentDay = moment().day();

  //   Current date in moment number.
  const currentDate = moment().date();

  //   Start and End of menu time frame
  const { start, end } = timeFrame;

  //   Start time for menu.
  const startTime = moment(
    `${
      start === 'open' ? weeklySchedule[currentDay].open : start
    },${currentDate}`,
    'h:mmA,DD'
  );

  //   End time for menu.
  const endTime = moment(
    `${
      end === 'close' ? weeklySchedule[currentDay].close : end
    },${currentDate}`,
    'h:mmA,DD'
  );

  const isBefore = moment(currentTime).isBefore(endTime);
  const isAfter = moment(currentTime).isAfter(startTime);

  const isActive = isBefore && isAfter;

  return {
    isActive,
    endTime: endTime.format('ha'),
    startTime: startTime.format('ha')
  };
}
