import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Slide,
  useScrollTrigger,
  Typography,
  CircularProgress
} from '@material-ui/core';
import Iframe from 'react-iframe';

// Context
import Context from './context';

// Material Icons
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

// UI Components
import Nav from '../../components/global/Nav';

const useStyles = makeStyles((theme) => ({
  root: { padding: '25px 10px' },
  infoSectionHeader: {
    borderBottom: 'solid 1px #2E2E2E'
  },
  infoSectionTitle: {
    color: '#F3B943',
    fontSize: 35,
    fontWeight: 'bold',
    fontFamily: 'Cinzel Decorative',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  },
  infoSectionText: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 17
    }
  },
  infoSectionChildren: {
    margin: '10px 0px'
  },
  socialIcon: {
    color: 'white',
    fontSize: 60,
    margin: 25
  }
}));

// Hide on scroll
const HideOnScroll = (props) => {
  // Props
  const { children, window } = props;

  // Trigger
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const InfoSection = (props) => {
  // Styles
  const classes = useStyles();

  // Props
  const { title, children } = props;

  return (
    <Grid
      item
      xs={12}
      sm={8}
      ms={6}
      lg={6}
      xl={4}
      container
      direction="column"
      className={classes.root}
    >
      <Grid
        item
        container
        direction="row"
        className={classes.infoSectionHeader}
        justify="flex-start"
      >
        <Typography className={classes.infoSectionTitle}>{title}</Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        className={classes.infoSectionChildren}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const Screen = (props) => {
  // Classes
  const classes = useStyles();

  // Props
  const { storeHours } = useContext(Context);

  // Weekly Schedule
  const { weeklySchedule } = storeHours;

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HideOnScroll {...props}>
        <div style={{ position: 'sticky', top: 0 }}>
          <Nav
            navItems={[
              { to: '/menu', name: 'MENU' },
              { to: '/happyhour', name: 'HAPPY HOUR' },
              { to: '/gallery', name: 'GALLERY' },
              { to: '/about', name: 'ABOUT' }
            ]}
          />
        </div>
      </HideOnScroll>
      <Grid container direction="column" justify="center" alignItems="center">
        <InfoSection title="Hours">
          {weeklySchedule ? (
            Object.keys(weeklySchedule).map((key) => {
              return (
                <Grid
                  key={key}
                  item
                  container
                  direction="row"
                  justify="space-between"
                >
                  <Grid xs item container>
                    <Typography className={classes.infoSectionText}>
                      {weeklySchedule[key].day}
                    </Typography>
                  </Grid>
                  <Grid xs item container direction="row" justify="flex-end">
                    <Typography className={classes.infoSectionText}>
                      {weeklySchedule[key].open} - {weeklySchedule[key].close}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{ padding: 45 }}
            >
              <CircularProgress style={{ color: 'white' }} />
            </Grid>
          )}
        </InfoSection>

        <InfoSection title="Location">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6941.527017492216!2d-95.39468478277061!3d29.5523806842058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5040e6094884c991!2sMongo!5e0!3m2!1sen!2sus!4v1568854422064!5m2!1sen!2sus"
            width="100%"
            height="250"
            frameborder="0"
            allowFullScreen={false}
            style={{ border: '2px solid black' }}
          />
          <Typography className={classes.infoSectionText} align="right">
            11200 Broadway St #400, Pearland, TX 77584
          </Typography>
        </InfoSection>

        <InfoSection title="Stay In Touch">
          <Typography className={classes.infoSectionText} align="left">
            Questions or concerns? Please let us know how we can improve. We
            would love to hear from you.
          </Typography>
          <br></br>
          <Typography className={classes.infoSectionText} align="left">
            <small>Contact Number:</small> (713) 340-1288
          </Typography>
          <br></br>
          <Typography className={classes.infoSectionText} align="left">
            <small>Email:</small> mongopearland@gmail.com
          </Typography>
        </InfoSection>

        <InfoSection title="Follow Us">
          <Typography className={classes.infoSectionText} align="left">
            Follow our Social Media to stay up to date on events and seasonal
            deals!
          </Typography>

          <Grid container direction="row" justify="space-around">
            <a
              target="_blank"
              href="https://www.instagram.com/mongorestaurant/"
              rel="noopener noreferrer"
            >
              <InstagramIcon className={classes.socialIcon} />
            </a>

            <a
              target="_blank"
              href="https://www.facebook.com/MongoRestaurant/"
              rel="noopener noreferrer"
            >
              <FacebookIcon className={classes.socialIcon} />
            </a>
          </Grid>
        </InfoSection>
      </Grid>
    </>
  );
};

export default Screen;
