export const SET_CONTACT_FIELDS = 'SET_CONTACT_FIELDS';
export const SET_SHIPPING_FIELDS = 'SET_SHIPPING_FIELDS';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_PICK_UP_TIME = 'SET_PICK_UP_TIME';
export const INFO_VALIDATION = 'INFO_VALIDATION';
export const INFO_VALIDATION_FAILED = 'INFO_VALIDATION_FAILED';
export const INFO_VALIDATION_SUCCESS = 'INFO_VALIDATION_SUCCESS';
export const SHIPPING_VALIDATION = 'SHIPPING_VALIDATION';
export const SHIPPING_VALIDATION_FAILED = 'SHIPPING_VALIDATION_FAILED';
export const SHIPPING_VALIDATION_SUCCESS = 'SHIPPING_VALIDATION_SUCCESS';
export const PAYMENT_VALIDATION = 'PAYMENT_VALIDATION';
export const PAYMENT_VALIDATION_FAILED = 'PAYMENT_VALIDATION_FAILED';
export const PAYMENT_VALIDATION_SUCCESS = 'PAYMENT_VALIDATION_SUCCESS';
export const RESET_CHECKOUT_TO_INITIAL_STATE =
  'RESET_CHECKOUT_TO_INITIAL_STATE';
export const TOGGLE_TIME_PICKER_MODAL = 'TOGGLE_TIME_PICKER_MODAL';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_PICK_UP_TIME_ERROR = 'SET_PICK_UP_TIME_ERROR';
export const CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS';
