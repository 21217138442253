import moment from 'moment';

export default function getTimeMinMax(schedule) {
  /* 
    0-6 Moment days
    0 - Sunday
    6 - Saturday

    All times are exported in h:mmA format (12 HOUR FORMAT)
  */

  if (!schedule) {
    return [null, null, null];
  }

  //   Current time
  const currentTime = moment();

  //   Current day in moment number.
  const currentDay = moment().day();

  //   Current date in moment number.
  const currentDate = moment().date();

  //   Opening time for restuarant.
  const openingTime = moment(
    `${schedule[currentDay].open},${currentDate}`,
    'h:mmA,DD'
  );

  //   Closing time for restuarant.
  // TODO: Easy way to fix the issue for now but have to clean later
  const closingTime = moment(
    `${schedule[currentDay].close},${currentDate}`,
    'h:mmA,DD'
  );

  const isBefore = moment(currentTime).isBefore(closingTime);
  const isAfter = moment(currentTime).isAfter(openingTime);

  const isOpen = isBefore && isAfter;

  const diff = closingTime.diff(currentTime, 'minutes');

  let minT;
  let maxT;
  let step = 20;

  // Max time
  maxT = moment(closingTime, 'h:mmA,DD')
    .subtract(15, 'minutes')
    .format('h:mmA');

  if (!isOpen) {
    minT = '11:30AM';
    return [minT, maxT, step];
  }

  if (diff < 35) {
    return [maxT, maxT, step];
  }

  minT = currentTime.add(19, 'minutes').format('h:mmA');

  return [minT, maxT, step];

  // let last2 = moment().format('HH mm A');

  // last2 = last2.split(' ')[1];

  // last2 = parseFloat(last2);

  // let p = last2 / parseFloat(step);

  // p = p.toFixed(2);

  // p = p.toString();

  // p = p.split('.');

  // p = parseFloat('.' + p[1]);

  // if (p === 0) {
  //   minT = currentTime.add(step, 'minutes').format('h:mmA');

  //   return [minT, maxT, step];
  // }

  // if (p <= 0.43 && p !== 0) {
  //   let x = parseFloat(currentTime.format('mm'));

  //   let y = parseFloat(currentTime.format('mm'));

  //   x = Math.ceil(x / step) * step;

  //   let diff = x - y;

  //   minT = moment().add(diff, 'minutes');

  //   minT = minT.format('h:mmA');

  //   return [minT, maxT, step];
  // }

  // if (p > 0.43) {
  //   let x = parseFloat(currentTime.format('mm'));

  //   let y = parseFloat(currentTime.format('mm'));

  //   x = Math.ceil(x / step) * step + step;

  //   let diff = x - y;

  //   minT = moment().add(diff, 'minutes');

  //   minT = minT.format('h:mmA');

  //   return [minT, maxT, step];
  // }
}
