// OLD
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CREATE = 'AUTH_CREATE';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_START = 'AUTH_START';
export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_FAILED = 'SET_ORDERS';

// NEW
export const HANDLE_INIT_CHECKOUT = 'HANDLE_INIT_CHECKOUT';
export const HANDLE_CANCEL_INIT_CHECKOUT = 'HANDLE_CANCEL_INIT_CHECKOUT';
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
