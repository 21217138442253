import React, { useContext, useState, useEffect } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// UI Components
import OrderNav from '../../components/global/OrderNav';
import OrderItems from '../../components/order/OrderItems';
import OrderContext from './context';
import OrderBar from '../../components/order/OrderBar';
import MenuSection from '../../components/order/MenuSection';
import AddItemModal from '../../components/order/AddItemModal';
import Scrollspy from 'react-scrollspy';
import DropdownDialog from '../../components/order/OrderBar/dropdownDialog';

// Utilities
import getIsMenuActive from '../../utilities/getIsMenuActive';
import getIsStoreOpen from '../../utilities/getIsStoreOpen';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  sectionRoot: {
    width: '100%',
    paddingTop: 85,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80
    }
  }
}));

const Order = (props) => {
  const classes = useStyles();

  //   Map to state props
  const {
    selectedItem,
    menu,
    handleAuthLogOut,
    isSignedIn,
    initCheckout,
    calendar,
    storeHours,
    storeActions,
    sendSnackBarNotification,
    modalIsOpen
  } = useContext(OrderContext);

  // Calendar Data
  const { dailySchedule } = calendar;

  // Store Hours
  const { weeklySchedule } = storeHours;

  // Store Actions
  const { canOrder } = storeActions;

  // Check if store is open.
  const isStoreOpen = getIsStoreOpen(weeklySchedule);

  // state
  const [currentCategory, setCurrentCategory] = useState('');
  const [dropdownDialogIsOpen, setDropDownDialogOpen] = useState(false);

  // Scroll Spy
  const scrollValueList = [];
  const scrollNameList = {};

  dailySchedule &&
    dailySchedule.map((menuObj) => {
      const { timeFrame, categories, isAllDay } = menuObj;

      const isActive = getIsMenuActive(timeFrame, weeklySchedule, isAllDay);

      if (!isActive) {
        // eslint-disable-next-line array-callback-return
        return;
      }
      // eslint-disable-next-line array-callback-return
      return categories.map((categoryObj) => {
        const { value: categoryValue, name: categoryName } = categoryObj;
        scrollValueList.push(categoryValue);
        scrollNameList[categoryValue] = categoryName;
      });
    });

  // Set initial value
  useEffect(() => {
    if (!currentCategory) {
      setCurrentCategory(scrollValueList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailySchedule]);

  //Store disabled notifcation
  useEffect(() => {
    if (canOrder === false) {
      sendSnackBarNotification({
        message:
          'Our apologies. We are currently not available to take online orders.'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canOrder]);

  // Store closed notification
  useEffect(() => {
    if (weeklySchedule && isStoreOpen === false) {
      sendSnackBarNotification({
        message: 'Our apologies. We are currently closed.'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStoreOpen, weeklySchedule]);

  return (
    <React.Fragment>
      <DropdownDialog
        open={dropdownDialogIsOpen}
        scrollNameList={scrollNameList}
        scrollValueList={scrollValueList}
        handleClose={() => setDropDownDialogOpen(false)}
        calendar={calendar}
        weeklySchedule={weeklySchedule}
      />
      <OrderNav
        navItems={[
          { to: '/menu', name: 'MENU' },
          { to: '/gallery', name: 'GALLERY' },
          { to: '/about', name: 'ABOUT' }
        ]}
        handleAuthLogOut={handleAuthLogOut}
        isSignedIn={isSignedIn}
        initCheckout={() => initCheckout(false)}
      />
      <OrderBar
        currentCategory={currentCategory}
        scrollValueList={scrollValueList}
        scrollNameList={scrollNameList}
        handleDropDownDialogOpen={() => setDropDownDialogOpen(true)}
      />
      <Scrollspy
        style={{ width: 0, height: 0, position: 'absolute' }}
        items={scrollValueList}
        currentClassName='is-current'
        onUpdate={(x) => {
          if (x && x.id) {
            setCurrentCategory(x.id);
          }
        }}
      />
      <Grid container direction='row' justify='center'>
        <Grid item container direction='row' xs={12} sm={12} md={10} lg={8}>
          {dailySchedule &&
            dailySchedule.map((menuObj) => {
              // Desconstruct dailySchedule
              const {
                name: menuName,
                categories,
                isAllDay,
                timeFrame
              } = menuObj;

              const { isActive, endTime, startTime } = getIsMenuActive(
                timeFrame,
                weeklySchedule,
                isAllDay
              );

              // eslint-disable-next-line array-callback-return
              return categories.map((categoryObj) => {
                // Desconstruct category
                const {
                  value: categoryValue,
                  name: categoryName,
                  description: categoryDescription
                } = categoryObj;

                // Filter menu items based on category
                const items =
                  menu &&
                  menu.filter((obj) => obj.type.value === categoryValue);

                // Sorting items by price.
                const sortedItems = items.sort((a, b) => {
                  return (
                    a.item.priceMonetaryFields.unitAmount -
                    b.item.priceMonetaryFields.unitAmount
                  );
                });

                if (isActive) {
                  return (
                    <section
                      id={categoryValue}
                      className={classes.sectionRoot}
                      key={categoryValue}
                    >
                      <MenuSection
                        key={categoryValue}
                        categoryName={categoryName}
                        menuName={menuName}
                        categoryDescription={categoryDescription}
                        showMenuTime={!isAllDay}
                        isActive={isActive}
                        endTime={endTime}
                        startTime={startTime}
                      >
                        <OrderItems
                          items={sortedItems}
                          canOrder={canOrder && isStoreOpen}
                          sendSnackBarNotification={sendSnackBarNotification}
                        />
                      </MenuSection>
                    </section>
                  );
                }
              });
            })}
        </Grid>
      </Grid>

      {selectedItem && modalIsOpen && <AddItemModal />}
    </React.Fragment>
  );
};

export default Order;
