import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import CardItem from '../ItemCard/card';

const OrderItems = (props) => {
  const { items, canOrder, sendSnackBarNotification } = props;

  return (
    <Fragment>
      {items.map((obj) => {
        // Handle Null Objects
        if (!obj) {
          return null;
        }

        // Item
        const { item } = obj;

        // If Item is not active then don't display it
        if (item.isActive === false) {
          return null;
        }

        // Return Item
        return (
          <Grid item xs={12} sm={6} md={6}>
            <CardItem
              menuItem={obj}
              canOrder={canOrder}
              sendSnackBarNotification={sendSnackBarNotification}
            />
          </Grid>
        );
      })}
    </Fragment>
  );
};

export default OrderItems;
