import SpecialRequest from './specialRequestInput';
import SingleSelect from './singleSelect';
import MultiSelect from './multiSelect';
import MultiChoice from './multiChoice';

export default {
  SpecialRequest,
  SingleSelect,
  MultiSelect,
  MultiChoice
};
