import React from 'react';
import { motion } from 'framer-motion';

export const FadeInAnimation = (props) => {
  const { children, duration, start, end } = props;
  return (
    <motion.div
      initial={{ opacity: start || 0 }}
      animate={{ opacity: end || 1 }}
      transition={{ duration: duration || 1 }}
    >
      {children}
    </motion.div>
  );
};

export const HeaderTextAnimation = (props) => {
  const { children } = props;
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1.2 }}
      transition={{ duration: 1.4 }}
    >
      {children}
    </motion.div>
  );
};

export default {
  HeaderTextAnimation,
  FadeInAnimation
};
