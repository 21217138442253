import React, { useContext, useEffect, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Grid,
  Typography,
  Button,
  IconButton
} from '@material-ui/core';
import { motion } from 'framer-motion';
import Context from '../../../screens/order/context';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import cartitemOptionsRender from './utilities/cartItemOptionsRender';
import totalCalculator from '../../../utilities/totalCalculator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import './drawer.scss';

// Utilities
import getIsMenuActive from '../../../utilities/getIsMenuActive';

const displayTotalItemPrice = (quantity, price, optionsForm) => {
  // quantity: number
  // price: object{ unitAmount: cents:number, displayString: string }

  // Calculate quantity and unit price
  const { unitAmount } = price;

  let newAmount = Math.round(parseFloat(unitAmount).toFixed(2));

  // Return if no options.
  if (!optionsForm) {
    return parseFloat((newAmount * quantity) / 100).toFixed(2);
  }

  // Calculate options addition
  let addedCost = { unitAmount: 0 };

  // Convert options to object array
  const optionsKeys = Object.keys(optionsForm);
  const optionsArray = optionsKeys.map((key) => {
    return {
      name: key,
      ...optionsForm[key]
    };
  });

  optionsArray.forEach((option) => {
    if (!option.selectedOptions) {
      return;
    }
    switch (option.selectionMode) {
      case 'multi_choice':
        option.selectedOptions.forEach((selectedOption) => {
          if (!selectedOption.selectedOptions) {
            return;
          }
          selectedOption.selectedOptions.forEach((option) => {
            addedCost.unitAmount += option.priceMonetaryFields.unitAmount;
          });
        });
        break;

      default:
        option.selectedOptions.forEach((selectedOption) => {
          addedCost.unitAmount += selectedOption.priceMonetaryFields.unitAmount;
        });
        break;
    }
  });

  return parseFloat(
    ((newAmount + addedCost.unitAmount) * quantity) / 100
  ).toFixed(2);
};

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: 'black',
    fontFamily: 'Source Sans Pro',
    fontSize: 20,
    fontWeight: 'bold',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      fontSize: 17
    }
  },
  locationHeadText: {
    color: 'black',
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    fontWeight: 'bold',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  locationSubText: {
    color: 'black',
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  cartItemRoot: {
    minHeight: 75,
    borderBottom: 'solid #E7E7E7 1px',
    padding: '20px 10px'
  },
  image: {
    width: 29,
    height: 25
  },
  cartTitleText: {
    color: 'black',
    fontFamily: 'Source Sans Pro',
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  cartItemText: {
    color: 'black',
    fontFamily: 'Source Sans Pro',
    fontSize: 17,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  cartItemSubText: {
    color: '#8E8E8E',
    fontFamily: 'Source Sans Pro',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13
    }
  },
  checkoutButton: {
    margin: 20,
    fontFamily: 'Source Sans Pro',
    color: 'black'
  },
  cartItemContainer: {
    overflowY: 'scroll',
    width: '100%'
  },
  emptyText: {
    fontFamily: 'Source Sans Pro',
    color: 'black',
    fontWeight: 'bold'
  },
  emptyIcon: {
    fontSize: 65
  }
}));

const Header = (props) => {
  const { location } = props;
  const classes = useStyles();
  const { toggleCartDrawer, cart } = useContext(Context);
  return (
    <Grid item container direction="column" style={{ padding: 5 }}>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ padding: '5px 10px 10px 0px' }}
      >
        <IconButton
          aria-label="close"
          color="grey"
          onClick={() => toggleCartDrawer(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.titleText}>
          {cart.length} Item(s)
        </Typography>
      </Grid>
      {location && (
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ padding: '5px 10px 0px 10px' }}
        >
          <Grid item container direction="row" justify="flex-start" xs={2}>
            <LocationOnIcon style={{ color: '#F34213', fontSize: 30 }} />
          </Grid>
          <Grid item xs container direction="column" alignItems="flex-end">
            <Typography className={classes.locationHeadText}>
              Pearland Town Center
            </Typography>
            <Typography className={classes.locationSubText}>
              11200 Broadway St, ste 400
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const CartItem = ({ cartItem }) => {
  // Styles
  const classes = useStyles();

  // Map to State Props
  const { removeFromCart, resetOrderStateToInitialState } = useContext(Context);

  // Try to restructure menu, this can handle old data issues.
  try {
    // Menu Item
    const { menuItem, optionsForm, specialInstructions, quantity } = cartItem;

    const { item, type } = menuItem;

    const { name, priceMonetaryFields } = item;

    return (
      <Grid container direction="row" className={classes.cartItemRoot}>
        <Grid
          item
          xs
          container
          direction="column"
          justify="center"
          // className={classes.cartItemRoot}
        >
          <Grid
            item
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid
              item
              xs={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography
                className={classes.cartItemText}
                style={{ paddingLeft: 15 }}
              >
                {quantity}
              </Typography>
            </Grid>
            <Grid
              item
              xs
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography
                className={classes.cartItemText}
                style={{ paddingRight: 15 }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.cartItemText}>
                $
                {displayTotalItemPrice(
                  quantity,
                  priceMonetaryFields,
                  optionsForm
                )}
              </Typography>
            </Grid>
          </Grid>

          {/* Type of item. Sushi and Sashimi or ALL*/}
          {type && (type.value === 'sashimi' || type.value === 'sushi') && (
            <Grid
              item
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs={2}></Grid>
              <Grid item xs container direction="column" justify="flex-start">
                <Typography className={classes.cartItemSubText}>
                  {type.name}
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          )}

          {optionsForm &&
            optionsForm.length > 0 &&
            optionsForm.map((option) => {
              // Display render options if any for each item.
              const renderOptions = cartitemOptionsRender(option);

              return (
                renderOptions &&
                renderOptions.length > 0 &&
                renderOptions.map((selectedOption) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                    >
                      <Grid item xs={2}></Grid>
                      <Grid
                        item
                        xs
                        container
                        direction="column"
                        justify="flex-start"
                      >
                        <Typography className={classes.cartItemSubText}>
                          {selectedOption.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        container
                        direction="column"
                        justify="flex-start"
                      >
                        <Typography className={classes.cartItemSubText}>
                          ${selectedOption.priceMonetaryFields.displayString}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              );
            })}

          {specialInstructions && (
            <Grid
              item
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs={2}></Grid>
              <Grid item xs container direction="column" justify="flex-start">
                <Typography className={classes.cartItemSubText}>
                  Notes: {specialInstructions}
                </Typography>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={2}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ cursor: 'pointer', marginRight: 10 }}
          onClick={() => removeFromCart(cartItem)}
        >
          <CloseIcon style={{ color: '#E6AEBF', fontSize: 19 }} />
        </Grid>
      </Grid>
    );
  } catch (error) {
    resetOrderStateToInitialState();
    return null;
  }
};

export const CartItemAnimation = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 1 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.7 }}
  >
    {children}
  </motion.div>
);

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#F34213',

    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F34213'
    },
    '& p': {
      color: 'white',
      fontFamily: 'Source Sans Pro',
      fontSize: 17,
      fontWeight: 'bold'
    }
  }
}))(Button);

export default function TemporaryDrawer() {
  // Styles
  const classes = useStyles();

  // Props
  const {
    cartDrawerIsOpen,
    toggleCartDrawer,
    cart,
    initCheckout,
    calendar,
    storeHours,
    onRemoveInactiveItems
  } = useContext(Context);

  // Cart, discount, taxRate
  const returnObject = useMemo(() => {
    return totalCalculator(cart, null, 1.0825);
  }, [cart]);

  const { subTotal } = returnObject;

  // Router History
  const history = useHistory();

  // Handle Init Checkout
  const handleInitCheckout = () => {
    initCheckout(history);
  };

  // Check if cart items are active
  useEffect(() => {
    // Check for inactive items.

    // Calendar & Store Hours
    const { dailySchedule } = calendar;
    const { weeklySchedule } = storeHours;

    // Inactive Items
    const inactiveItems = [];

    cart.length > 0 &&
      dailySchedule &&
      weeklySchedule &&
      cart.forEach((cartItem) => {
        // Cart Item
        const {
          menuItem: { schedule: { value = '' } } = {
            schedule: { value: '' }
          }
        } = cartItem;

        if (!value) {
          return;
        }

        // Cart Item's Menu
        const { timeFrame, isAllDay } = dailySchedule.filter(
          (obj) => obj.value === value
        )[0];

        const { isActive } = getIsMenuActive(
          timeFrame,
          weeklySchedule,
          isAllDay
        );

        if (isActive === false) {
          inactiveItems.push(cartItem);
        }
      });

    if (inactiveItems.length > 0) {
      onRemoveInactiveItems(inactiveItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, storeHours]);

  if (cart.length === 0) {
    return (
      <Drawer
        anchor="right"
        open={cartDrawerIsOpen}
        onClose={() => toggleCartDrawer(false)}
      >
        <Grid container direction="column" style={{ height: '100%' }}>
          <Header location={false} />
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs
            style={{
              color: 'black',
              fontFamily: 'Source Sans Pro',
              fontSize: 13
            }}
          >
            <FastfoodIcon className={classes.emptyIcon} />
            <Typography className={classes.emptyText}>
              YOUR CART IS EMPTY
            </Typography>
            <ColorButton
              variant="text"
              size="small"
              onClick={() => toggleCartDrawer(false)}
            >
              <Grid
                container
                direcion="row"
                alignItems="center"
                justify="space-between"
              >
                <ArrowBackIcon
                  style={{
                    fontSize: 20,
                    color: 'white',
                    fontWeight: 'bold',
                    marginRight: 10
                  }}
                />
                <Typography>ORDER NOW</Typography>
              </Grid>
            </ColorButton>
          </Grid>
        </Grid>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={cartDrawerIsOpen}
      onClose={() => toggleCartDrawer(false)}
    >
      <Grid container xs direction="column">
        <Grid item style={{ borderBottom: 'solid #E7E7E7 1px' }}>
          <Header location={true} />
        </Grid>
        <Grid
          item
          xs
          direction="column"
          id={'cartItemContainer'}
          className={classes.cartItemContainer}
        >
          {cart.map((item) => {
            return (
              <CartItemAnimation key={item.cartId} item={item}>
                <CartItem cartItem={item} />
              </CartItemAnimation>
            );
          })}
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            borderTop: 'solid #E7E7E7 1px',
            width: '100%',
            textDecoration: 'none',
            padding: 10
          }}
        >
          <ColorButton
            fullWidth
            variant="contained"
            size="large"
            onClick={() => handleInitCheckout()}
          >
            <Grid container direcion="row" justify="space-between">
              <Typography>CHECKOUT</Typography>
              <Typography>${subTotal.displayString}</Typography>
            </Grid>
          </ColorButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
