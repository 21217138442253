import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Grid,
  Card,
  useMediaQuery,
  Typography,
  CircularProgress,
} from "@material-ui/core";

// Material UI Lab
import { Skeleton } from "@material-ui/lab";

// Material Icons
import { CheckCircle, KeyboardReturn } from "@material-ui/icons";

// Utilities
import cartitemOptionsRender from "../../../screens/checkout/utilites/cartitemOptionsRender";

// UI Component
import { CartItemAnimation } from "../../order/CartDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0,
    marginBottom: 15,
    borderRadius: 0,
    fontFamily: "Source Sans Pro",
  },
  cardHeader: {
    height: 70,
    width: "100%",
    padding: 10,
  },
  headerOrderId: {
    color: "#F34213",
    fontSize: 14,
    overflow: "hidden",
  },
  headerDate: {
    color: "#AEACAC",
    fontSize: 11,
  },
  statusText: {
    color: "black",
    fontSize: 16,
    marginLeft: 5,
    fontWeight: "bold",
  },
  statusIcon: {
    fontSize: 20,
  },
  cardContent: {
    width: "100%",
  },
  cardFooter: {
    backgroundColor: "#F9F9F9",
    minHeight: 55,
    width: "100%",
    padding: 10,
  },
  orderSummaryRoot: {
    padding: "25px 15px 25px 15px",
  },
  orderSummaryText: {
    fontFamily: "Roboto Mono",
    fontSize: 17,
    fontWeight: "400",
  },
  orderSummaryItems: {
    padding: "0px 10px 20px 10px",
  },
  cartItemRoot: {
    width: "100%",
    height: "100%",
    borderBottom: "solid #E7E7E7 1px",
    padding: "10px 5px",
  },
  cartItemText: {
    color: "black",
    fontFamily: "Roboto Mono",
    fontSize: 13,
    fontWeight: "bold",
  },
  cartItemTitleText: {
    color: "black",
    fontFamily: "Roboto Mono",
    fontSize: 13,
    fontWeight: "bold",
  },
  cartItemSubText: {
    color: "#4D4D4D",
    fontFamily: "Roboto Mono",
    fontSize: 11,
  },
  cartItemTypeText: {
    color: "#7A8383",
    fontFamily: "Roboto Mono",
    fontSize: 11,
    whiteSpace: "nowrap",
  },
  totalAmount: {
    fontSize: 14,
    color: "black",
  },
  totalText: {
    color: "#AEACAC",
    fontSize: 11,
    marginRight: 10,
  },
  discountAmount: {
    fontSize: 14,
    color: "green",
  },
  discountText: {
    color: "#AEACAC",
    fontSize: 11,
    marginRight: 10,
  },
}));

const displayTotalItemPrice = (quantity, price, optionsForm) => {
  // quantity: number
  // price: object{ unitAmount: cents:number, displayString: string }

  // Calculate quantity and unit price
  const { unitAmount } = price;

  let newAmount = Math.round(parseFloat(unitAmount).toFixed(2));

  // Return if no options.
  if (!optionsForm) {
    return parseFloat((newAmount * quantity) / 100).toFixed(2);
  }

  // Calculate options addition
  let addedCost = { unitAmount: 0 };

  // Convert options to object array
  const optionsKeys = Object.keys(optionsForm);
  const optionsArray = optionsKeys.map((key) => {
    return {
      name: key,
      ...optionsForm[key],
    };
  });

  optionsArray.forEach((option) => {
    if (!option.selectedOptions) {
      return;
    }
    switch (option.selectionMode) {
      case "multi_choice":
        option.selectedOptions.forEach((selectedOption) => {
          if (!selectedOption.selectedOptions) {
            return;
          }
          selectedOption.selectedOptions.forEach((option) => {
            addedCost.unitAmount += option.priceMonetaryFields.unitAmount;
          });
        });
        break;

      default:
        option.selectedOptions.forEach((selectedOption) => {
          addedCost.unitAmount += selectedOption.priceMonetaryFields.unitAmount;
        });
        break;
    }
  });

  return parseFloat(
    ((newAmount + addedCost.unitAmount) * quantity) / 100
  ).toFixed(2);
};

export const CartItem = ({ cartItem }) => {
  // Styles
  const classes = useStyles();

  // Try to catch any isues with buggy data.
  // Cart Item
  const { menuItem, optionsForm, quantity, specialInstructions } = cartItem;

  const { item, type } = menuItem;

  const { name, priceMonetaryFields } = item;

  return (
    <Grid item container direction="column" className={classes.cartItemRoot}>
      <Grid item container direction="row">
        <Grid item xs={1}></Grid>
        <Grid
          item
          container
          xs={8}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {type && (
            <Typography className={classes.cartItemTypeText}>
              {type.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      <Grid item container direction="row">
        <Grid
          item
          container
          xs={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography className={classes.cartItemText}>{quantity}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={8}
          direction="row"
          justify="flex-start"
          alignItems="center"
          zeroMinWidth
        >
          <Typography className={classes.cartItemTitleText} noWrap>
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          direction="row"
          justify={"flex-end"}
          alignItems="center"
        >
          <Typography className={classes.cartItemText}>
            ${displayTotalItemPrice(quantity, priceMonetaryFields, optionsForm)}
          </Typography>
        </Grid>
      </Grid>

      {optionsForm &&
        optionsForm.length > 0 &&
        optionsForm.map((option) => {
          const renderOptions = cartitemOptionsRender(option);

          return (
            renderOptions &&
            renderOptions.length > 0 &&
            renderOptions.map((selectedOption) => {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={1}></Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="column"
                    justify="flex-start"
                  >
                    <Typography className={classes.cartItemSubText}>
                      {selectedOption.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    direction="row"
                    justify={"flex-end"}
                  >
                    <Typography className={classes.cartItemSubText}>
                      ${selectedOption.priceMonetaryFields.displayString}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })
          );
        })}

      {specialInstructions && (
        <Grid
          item
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={8} container direction="column" justify="flex-start">
            <Typography className={classes.cartItemSubText}>
              Notes: {specialInstructions}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      )}
    </Grid>
  );
};

const OrderSummary = (props) => {
  // Map To State Props
  const { cart } = props;

  // Styles
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.orderSummaryItems}>
      {cart.map((item) => {
        return (
          <CartItemAnimation key={item.cartId} item={item}>
            <CartItem cartItem={item} removeButtonIsDisabled />
          </CartItemAnimation>
        );
      })}
    </Grid>
  );
};

const OrderItem = (props) => {
  // Styles
  const classes = useStyles();

  // Mobile Query
  const mobileDevice = useMediaQuery("(max-width:450px)");

  // Props
  const { order, isSkeleton } = props;

  // Skeleton Config.
  if (isSkeleton) {
    return (
      <>
        <Skeleton height={250} width="100%" style={{ padding: 0, margin: 0 }} />
        <Skeleton height={25} width="100%" style={{ padding: 0, margin: 0 }} />
      </>
    );
  }

  if (!order) {
    return null;
  }

  const orderIcons = {
    incomplete: (
      <CircularProgress style={{ color: "green", width: 25, height: 25 }} />
    ),
    completed: (
      <CheckCircle className={classes.statusIcon} style={{ color: "green" }} />
    ),
    refunded: (
      <KeyboardReturn className={classes.statusIcon} style={{ color: "red" }} />
    ),
  };

  // Desconstruct Order
  const { cart, createdAt, id, paymentAmount, pickUpTime, status, isComplete } =
    order;

  // Payment Amounts
  const { total, discount } = paymentAmount;

  return (
    <Card className={classes.root}>
      <Grid container className={classes.cardHeader}>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ paddingBottom: 10 }}
        >
          <Typography noWrap className={classes.headerOrderId}>{`Order #${
            id.substring(0, mobileDevice ? 6 : 30) +
            `${mobileDevice ? "..." : ""}`
          }`}</Typography>
          <Typography className={classes.headerDate}>
            {status.value === "incomplete"
              ? "Order received on"
              : "Order completed on"}{" "}
            {moment(createdAt.seconds * 1000).format("MMMM DD, YYYY")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            xs
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {orderIcons[status.value]}
            <Typography className={classes.statusText}>
              {status.value === "incomplete" ||
              status.displayString === "Incomplete"
                ? "In Progress"
                : status.displayString}
            </Typography>
          </Grid>
          <Grid xs item container direction="row" justify="flex-end">
            {status.value === "incomplete" ||
              (status.displayString === "Incomplete" && (
                <Typography className={classes.statusText}>
                  Ready at {pickUpTime}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.cardContent}>
        <OrderSummary cart={cart} />
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.cardFooter}
      >
        {discount && discount.code && (
          <Grid
            style={{ height: "100%", width: "100%" }}
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid
              style={{ height: "100%", width: "100%" }}
              xs={6}
              md={9}
              item
              direction="row"
              container
              justify="center"
              alignItems="center"
            ></Grid>
            <Grid
              style={{ height: "100%", width: "100%" }}
              xs={3}
              md={2}
              item
              direction="row"
              container
              justify="flex-end"
              alignItems="center"
            >
              <Typography noWrap className={classes.discountText}>
                Discount
              </Typography>
            </Grid>
            <Grid
              style={{ height: "100%", width: "100%" }}
              xs={3}
              md={1}
              item
              direction="row"
              container
              justify="center"
              alignItems="center"
            >
              <Typography noWrap className={classes.discountAmount}>
                -${discount.discountPriceMonetaryFields.displayString}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          style={{ height: "100%", width: "100%" }}
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            style={{ height: "100%", width: "100%" }}
            xs={7}
            md={10}
            item
            direction="row"
            container
            justify="center"
            alignItems="center"
          ></Grid>
          <Grid
            style={{ height: "100%", width: "100%" }}
            xs={2}
            md={1}
            item
            direction="row"
            container
            justify="flex-end"
            alignItems="center"
          >
            <Typography noWrap className={classes.totalText}>
              Total
            </Typography>
          </Grid>
          <Grid
            style={{ height: "100%", width: "100%" }}
            xs={3}
            md={1}
            item
            direction="row"
            container
            justify="center"
            alignItems="center"
          >
            <Typography noWrap className={classes.totalAmount}>
              ${total.displayString}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OrderItem;
