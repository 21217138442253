import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import * as api from '../../api';
import firebase from '../../firebase';
import screenGlobal from '../global';

function* authStart() {
  try {
    const user = yield firebase.auth().currentUser;

    yield put({
      type: actionTypes.AUTH_LOGIN,
      user: {
        name: user.displayName,
        email: user.email,
        photoUrl: user.photoURL,
        emailVerified: user.emailVerified,
        uid: user.uid
      }
    });

    yield put({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: 'Logged in as ' + user.displayName,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'default'
        }
      }
    });
  } catch (e) {
    // handle error
    yield put({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: 'Failed to login: ' + e.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error'
        }
      }
    });
    yield put({ type: actionTypes.AUTH_FAILED });
  }
}

function* setOrders(action) {
  yield put({
    type: screenGlobal.actions.BEGIN_ACTION
  });
  try {
    const orders = yield api.getOrders(action.uid);

    yield put({ type: actionTypes.SET_ORDERS, orders });
  } catch (e) {
    // handle error
    yield put({
      type: screenGlobal.actions.OPEN_SNACKBAR,
      message: 'Failed to get orders. Please try again.'
    });
    yield put({ type: actionTypes.SET_ORDERS_FAILED });
  } finally {
    yield put({
      type: screenGlobal.actions.FINISH_ACTION
    });
  }
}

function* mySaga() {
  yield takeLatest(actionTypes.AUTH_START, authStart);
  yield takeLatest(actionTypes.GET_ORDERS, setOrders);
}

export default mySaga;
