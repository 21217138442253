import { put, takeLatest, select, delay } from "redux-saga/effects";
import axios from "axios";
import moment from "moment";
import * as actionTypes from "./actions";
import * as selectors from "./selectors";

// Order Store
import * as screenMyOrdersActions from "../myorders/actions";

// Order Store
import * as screenOrderActions from "../order/actions";
import * as screenOrderSelectors from "../order/selectors";

// Auth Store
import * as screenAuthSelectors from "../auth/selectors";

function* infoValidationSaga(action) {
  yield delay(1000);
  try {
    const { step } = action;
    const contactFields = yield select(selectors.getContactFields);
    const updatedContactFields = { ...contactFields };
    let hasError = false;

    // const validateEmail = (email) => {
    //   let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    //   if (reg.test(email) === false) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // };

    Object.keys(updatedContactFields).forEach((field) => {
      // Email Validation
      // if (field === 'email' && updatedContactFields[field].value) {
      //   let emailValid = validateEmail(updatedContactFields[field].value);
      //   if (!emailValid) {
      //     updatedContactFields[field].error = true;
      //     hasError = true;
      //   } else {
      //     updatedContactFields[field].error = false;
      //   }
      // }

      if (updatedContactFields[field].required) {
        if (!updatedContactFields[field].value) {
          updatedContactFields[field].error = true;
          hasError = true;
        } else {
          updatedContactFields[field].error = false;
        }
      }
    });

    if (hasError) {
      yield put({
        type: actionTypes.INFO_VALIDATION_FAILED,
        contactFields: updatedContactFields,
        errorMessage: "FILL IN REQUIRED FIELDS",
      });
    } else {
      yield put({
        type: actionTypes.INFO_VALIDATION_SUCCESS,
        contactFields: updatedContactFields,
        nextStep: step + 1,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.INFO_VALIDATION_FAILED,
    });
  }
}

function* paymentValidationSaga(action) {
  yield delay(1000);
  try {
    // Source from payment field
    const { payload } = action;

    // Source from payment field
    const { source, promoCode, history } = payload;

    // Cart selector
    const cart = yield select(screenOrderSelectors.getCart);
    const contactFields = yield select(selectors.getContactFields);
    // const pickUpTime = yield select(selectors.getPickUpTime);
    const user = yield select(screenAuthSelectors.getUser);

    // console.log('cart', cart);
    // console.log('contactFields', contactFields);
    // console.log('pickUpTime', pickUpTime);
    // console.log('source', source);
    // console.log('promoCode', promoCode);
    // console.log('user', user);

    // Set Pick Up Time

    //   Current time
    const currentTime = moment();

    const pickUpTime = currentTime.add(19, "minutes").format("h:mmA");

    // Handle Process Payment

    // request body requirements

    // Store information (destination info)
    // * menu ref
    // * orders ref

    // Order information
    // * cart
    // * pickUpTime
    // * contact information
    // * promo code if any

    // Payment information
    // * source

    // Validate payment on backend

    const response = yield axios({
      method: "POST",
      url: "https://us-central1-lumos-webapp-mongo.cloudfunctions.net/develop_processOrderWeb_v2",
      data: {
        cart,
        source,
        contactFields,
        pickUpTime,
        promoCode,
        user,
      },
    });

    // If error with payment, put FAILED and error message.

    if (response.data && response.data.hasError) {
      if (response.data.error.type) {
        if (response.data.error.type === "StripeCardError") {
          throw new Error("There was an issue with the payment method.");
        } else if (response.data.error.type === "api_connection_error") {
          throw new Error("There was an issue trying to access network.");
        } else if (response.data.error.type === "validation_error") {
          throw new Error(
            "There was a problem validating the payment provided."
          );
        }
      } else {
        throw new Error(
          response.data.error || "There was an error. Please Try Again."
        );
      }
    }

    yield put({
      type: screenMyOrdersActions.HANDLE_TAB_CHANGE,
      value: 0,
    });

    // If success payment, put SUCCESS and clear neccesary data.
    history.push("/myorders");

    yield put({
      type: actionTypes.PAYMENT_VALIDATION_SUCCESS,
    });

    yield put({
      type: screenOrderActions.RESET_ORDER_STATE_TO_INITIAL_STATE,
    });
  } catch (error) {
    yield put({
      type: actionTypes.PAYMENT_VALIDATION_FAILED,
      errorMessage: error.message,
    });
  }
}

function* mySaga() {
  yield takeLatest(actionTypes.INFO_VALIDATION, infoValidationSaga);
  yield takeLatest(actionTypes.PAYMENT_VALIDATION, paymentValidationSaga);
}

export default mySaga;
