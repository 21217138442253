import React, { useContext } from 'react';
// Material UI Imports
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Typography, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OrderContext from '../../../screens/order/context';

// Mongo Logo
import mongologo from '../../../assets/img/mongologo.png';

// FirebaseUI
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../../../firebase';

// SCSS Import
import './modal.scss';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 25,
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const dialogStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    height: '100%',
  },
  contentRoot: {
    padding: 15,
  },
  iconButton: {
    padding: 5,
    position: 'relative',
    left: 5,
  },
  captionText: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'Source Sans Pro',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  textFieldCaption: {
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  textFieldSubText: {
    textAlign: 'right',
    fontStyle: 'italic',
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

const LoginDialog = (props) => {
  const classes = dialogStyles();

  const fullScreen = useMediaQuery('(max-width:500px)');

  // Redux state from context provider.
  const {
    authModalIsOpen,
    cancelInitCheckout,
    handleAuthStart,
    handleAuthLogOut,
    isSignedIn,
  } = useContext(OrderContext);

  // Handle close dialog. Cancels auth task.
  const handleClose = () => {
    cancelInitCheckout();
  };

  const handleSignOut = () => {
    firebase.auth().signOut();
    handleAuthLogOut();
  };

  // Auth Config
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      'apple.com',
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (e) => {
        handleAuthStart(e);
        return true;
      },
      signInFailure: (e) => {
        console.log('signInFailure error', e);
        cancelInitCheckout();
      },
    },
    tosUrl: '/termsofservice',
    // Privacy policy url/callback.
    privacyPolicyUrl: '/privacypolicy',
  };

  if (!isSignedIn) {
    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        open={authModalIsOpen}
        id="loginDialog"
        maxWidth={'xs'}
        fullWidth
      >
        <DialogTitle onClose={handleClose} />
        <Grid
          container
          direction="column"
          className={classes.root}
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <img style={{ height: 130 }} src={mongologo} alt="Mongo" />
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.contentRoot}
          >
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </Grid>
        </Grid>
      </Dialog>
    );
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={authModalIsOpen}
      id="loginDialog"
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle onClose={handleClose} />
      <div>
        <h1>My App</h1>
        <p>
          Welcome {firebase.auth().currentUser.displayName}! You are now
          signed-in!
        </p>
        <button onClick={handleSignOut}>Sign-out</button>
      </div>
    </Dialog>
  );
};

export default LoginDialog;
