import { all, put, select, takeEvery } from 'redux-saga/effects';

// Actions, Selectors
import * as actions from './actions';
import * as selectors from './selectors';

// Store Data
import screenGlobal from '../global';
import screenOrder from '../order';

// Utilities
import affectedCartItemsInspector from '../../utilities/affectedCartItemsInspector';

function* handleModifiedMenu(action) {
  try {
    // Action Payload
    const { menu: modifiedMenu } = action;

    // Menu Selector
    const menu = yield select(selectors.getMenu);

    // Get Moodifed Ids
    const modifiedIds = [...new Set(modifiedMenu.map((obj) => obj.id))];

    // Update Menu
    const updatedMenu = menu.filter((obj) => !modifiedIds.includes(obj.id));

    // Update Menu
    yield put({
      type: actions.SET_MENU,
      menu: [...updatedMenu, ...modifiedMenu]
    });

    // Check Cart for modified items.

    // Cart Selector
    const cart = yield select(screenOrder.selectors.getCart);

    // return if cart is emtpy.
    if (!cart || (cart && cart.length === 0)) {
      return;
    }

    // Affected Items Array
    // eslint-disable-next-line array-callback-return
    const affectedItems = [];

    cart.forEach((cartItem) => {
      const { menuItem } = cartItem;

      if (menuItem && menuItem.id && modifiedIds.includes(menuItem.id)) {
        affectedItems.push({
          affectedItem: menuItem,
          updatedItem: modifiedMenu.filter((obj) => obj.id === menuItem.id)[0],
          cartItem
        });
      }

      return;
    });

    // Utility to check if affected items need to be alerted for user input.
    const { itemsToRemove, itemsToAlert } = yield affectedCartItemsInspector(
      affectedItems
    );

    // If both arrays are empty, return
    if (itemsToRemove.length === 0 && itemsToAlert.length === 0) {
      return;
    }

    if (itemsToRemove.length > 0) {
      yield put({
        type: screenGlobal.actions.ENQUEUE_SNACKBAR,
        notification: {
          message: 'These item(s) in your cart are no longer available.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'default',
            autoHideDuration: 7000,
            persit: true
          },
          expanded: true,
          contentKey: 'inactive-items-removed',
          payload: itemsToRemove
        }
      });
    }

    // Put items to remove and items to alert into the state
    yield all(
      // eslint-disable-next-line array-callback-return
      itemsToRemove.map((item) => {
        if (item) {
          return put({ type: screenOrder.actions.REMOVE_FROM_CART, item });
        }
      })
    );

    // If arrays is empty, return
    if (itemsToAlert.length === 0) {
      return;
    }

    // Update Cart with affected items.
    const updatedCartItems = itemsToAlert.map((obj) => {
      const { updated, cartItem } = obj;
      const updatedItem = cart.filter(
        (item) => item.cartId === cartItem.cartId
      )[0];
      updatedItem['menuItem'] = updated;

      return updatedItem;
    });

    // Get Affected Ids
    const affectedIds = [...new Set(updatedCartItems.map((obj) => obj.cartId))];

    const updatedCart = cart.filter((obj) => !affectedIds.includes(obj.cartId));

    yield put({
      type: screenGlobal.actions.ENQUEUE_SNACKBAR,
      notification: {
        message: 'These item(s) in your cart were modified.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'default',
          autoHideDuration: 7000
        },
        expanded: true,
        contentKey: 'items-modified',
        payload: itemsToAlert
      }
    });

    yield put({
      type: screenOrder.actions.SET_CART,
      cart: [...updatedCart, ...updatedCartItems]
    });
  } catch (e) {
    console.log('handleModifiedMenu error', e);

    // #TODO143 Test all scenarios and then if at ultimate, just clear out the cart
    // yield put({
    //   type: screenOrder.actions.SET_CART,
    //   cart: []
    // });
    // yield put({
    //   type: screenGlobal.actions.OPEN_SNACKBAR,
    //   message: 'Failed to update menu Please try again.'
    // });
  }
}

function* handleRemoveInactiveItems(action) {
  // Payload
  const { items } = action;

  // Put items to remove and items to alert into the state
  yield all(
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      if (item) {
        return put({ type: screenOrder.actions.REMOVE_FROM_CART, item });
      }
    })
  );

  yield put({
    type: screenGlobal.actions.ENQUEUE_SNACKBAR,
    notification: {
      message: 'These item(s) in your cart are no longer available.',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'default',
        autoHideDuration: 7000,
        persit: true
      },
      expanded: true,
      contentKey: 'inactive-items-removed',
      payload: items
    }
  });
}

function* mySaga() {
  yield takeEvery(actions.HANDLE_MODIFIED_MENU, handleModifiedMenu);
  yield takeEvery(
    actions.HANDLE_REMOVE_INACTIVE_ITEMS,
    handleRemoveInactiveItems
  );
}

export default mySaga;
