import React, { useRef, useEffect } from 'react';

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Parallax, Background } from 'react-parallax';
import { Grid, Typography, Button } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';

// Component Imports
import HomeNav from '../../components/home/nav';

// SCSS Imports
import './home.scss';

// Assets Imports
import xsEffectsBg from '../../assets/img/xsEffectsBg.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Animations from './animations';
import Section from '../../components/home/foodSection';
import frame from '../../assets/img/home/bamboo-frame.jpg';
import veggies from '../../assets/img/home/veggies.jpg';
import mongomango from '../../assets/img/home/mongomango.jpg';
import martini from '../../assets/img/home/martini.jpg';
import bowl1 from '../../assets/img/home/bowl1.jpg';
import bowl2 from '../../assets/img/home/bowl2.jpg';
import bowl3 from '../../assets/img/home/bowl3.jpg';
import bowl4 from '../../assets/img/home/bowl4.jpg';
import wings from '../../assets/img/home/wings.jpg';
import jalapeno from '../../assets/img/home/jalapeno.jpg';
import csr from '../../assets/img/home/csr.jpg';
import greenbeans from '../../assets/img/home/greenbeans.jpg';
import gyoza from '../../assets/img/home/gyoza.jpg';
import shrimptempura from '../../assets/img/home/shrimtempura.jpg';
import blackahi from '../../assets/img/home/blackahi.jpg';
import love from '../../assets/img/home/love.jpg';
import sushi from '../../assets/img/home/sushi.jpg';
import cajun from '../../assets/img/home/cajun.jpg';
import nemo from '../../assets/img/home/nemo.jpg';
import sunset from '../../assets/img/home/sunset.jpg';
import reddragon from '../../assets/img/home/reddragon.jpg';
import habSalmon from '../../assets/img/food/habsalmon.jpg';
import yt from '../../assets/img/home/yt.jpg';
import habMignon from '../../assets/img/food/habMignon.jpg';
import HabLobster from '../../assets/img/food/HabLobster.jpg';
import lobster from '../../assets/img/home/lobstertail.jpg';
import mignon from '../../assets/img/home/mignon.jpg';

const sushi_images = [sushi, cajun, nemo, sunset, reddragon, yt];
const hibachi_images = [habSalmon, habMignon, HabLobster];
const bowl_images = [bowl1, bowl3, bowl2, bowl4];
const happyhour_images = [
  wings,
  jalapeno,
  csr,
  greenbeans,
  gyoza,
  shrimptempura
];

// Color Button
const ColorButton = withStyles((theme) => ({
  root: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fontWeight: '600',
    color: '#F4C347',
    marginBottom: 25
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  heroTitle: {
    color: 'black',
    fontFamily: 'Lato',
    textAlign: 'center',
    letterSpacing: 5,
    fontWeight: 'bold',
    fontSize: 100,
    '@media (max-width: 1920px)': {
      fontSize: 80
    },
    '@media (max-width: 600px)': {
      fontSize: 40
    },
    '@media (max-width: 360px)': {
      fontSize: 30
    }
  },
  bgImage: {
    height: '100%',
    objectFit: 'cover',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    zIndex: -1
  },
  heroImage: {
    height: '100vh',
    width: '100%'
  },
  panel: {
    height: '100vh',
    width: '100%',
    backgroundColor: 'black'
  },
  heroImagePanel: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    zIndex: 99
  },
  footer: {
    maxHeight: '25vh',
    width: '100%',
    position: 'relative',
    backgroundColor: 'red'
  },
  locationsPanel: {
    height: '100vh',
    width: '100%',
    backgroundColor: 'blue'
  },
  fab: {
    backgroundColor: '#F4C347',
    color: 'black',
    bottom: 0,
    right: 0
  }
}));

const Nav = (props) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        position: 'absolute',
        width: '100%',
        top: 0,
        zIndex: 99999
      }}
    >
      <HomeNav
        navItems={[
          { to: '/menu', name: 'MENU' },
          { to: '/happyhour', name: 'HAPPY HOUR' },
          { to: '/gallery', name: 'GALLERY' },
          { to: '/about', name: 'ABOUT' }
        ]}
      />
    </Grid>
  );
};

const MobileHome = () => {
  const classes = useStyles();

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Media Query for mobile Devices
  const mobileDevice = useMediaQuery('(max-width:600px)');

  // Scroll to Content Ref
  const scrollToContent = useRef(null);

  // Execute scroll to content ref
  const executeScrollContent = () =>
    scrollToContent.current.scrollIntoView({ behavior: 'smooth' });

  // Scroll to Top Ref
  const scrollToTop = useRef(null);

  // Execute scroll to top ref
  const executeScrollTop = () =>
    scrollToTop.current.scrollIntoView({ behavior: 'smooth' });

  // Conditional Background Image
  const xsImage =
    'https://images.unsplash.com/photo-1547448271-fde9cf7d6afc?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=668&q=80';
  const xlImage =
    'https://images.unsplash.com/photo-1547448161-c56e75b54317?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2800&q=80';

  // Parralax Background Variables

  const innerWidth = window.innerWidth;

  return (
    <>
      <Nav mobileDevice={mobileDevice} />
      <Grid
        ref={scrollToTop}
        style={{
          height: '100%',
          backgroundColor: 'black'
        }}
      >
        <Parallax strength={200} blur={{ min: -1, max: 3 }}>
          <Background>
            <img
              src={mobileDevice ? xsImage : xlImage}
              alt="Mongolian Plains"
              id={mobileDevice ? 'xsImage' : 'xlImage'}
            />
          </Background>
          <Grid
            container
            direction="column"
            style={{ width: '100%', height: '100vh' }}
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.heroTitle}>
              MONGOLIAN FIRE PIT & BAR
            </Typography>
            <div>
              <div onClick={() => executeScrollContent()} className="arrow">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </Grid>
        </Parallax>
        <div style={{ height: '25px', width: '100%' }} />
        <Parallax strength={-800}>
          <Background>
            <div
              style={{
                height: 7000,
                width: innerWidth,
                backgroundImage: `url(${xsEffectsBg})`
              }}
            />
          </Background>

          <Animations.FadeInAnimation>
            <div style={{ height: 0, width: 0 }} ref={scrollToContent} />
            <Section
              title="KHRAM YOUR BOWL"
              description="Create Your Own Personalized Mongolian Stir-Fry With Our Vast
       Selection Of Vegetables and Meats."
              subdesc="(Dine-In ONLY)"
              images={bowl_images}
              buttonTitle="Show full gallery"
              image1={frame}
              image2={veggies}
              btnLink="/gallery"
            />
          </Animations.FadeInAnimation>

          <Section
            title="HIBACHI SPECIAL"
            description="Fine Cut Meat Grilled In Hibachi Style Accompanied With Side Of Rice And Vegetables."
            images={hibachi_images}
            buttonTitle="Show full menu"
            image1={lobster}
            image2={mignon}
            btnLink="/menu"
          />
          <Section
            title="HAPPY HOUR"
            description="Dont Miss Out On Our Happy Hour All Day Menu That Includes All
            Favorite Appetizers and Drinks."
            images={happyhour_images}
            buttonTitle="Show full Happy Hour"
            image1={mongomango}
            image2={martini}
            btnLink="/happyhour"
          />
          <Section
            title="SUSHI & ROLLS"
            description="Try Our Fresh Assortment Of Sushi and Sashimi Well Accompanied With Our Exquisite Selection Of Rolls."
            images={sushi_images}
            buttonTitle="Show full menu"
            image1={blackahi}
            image2={love}
            btnLink="/menu#Rolls"
            topButton={true}
          />
          <Grid container justify="center" alignItems="center">
            <ColorButton
              variant="text"
              startIcon={<ExpandLess />}
              onClick={() => executeScrollTop()}
            >
              Back To Top
            </ColorButton>
          </Grid>
        </Parallax>
      </Grid>
    </>
  );
};

const HomeContainer = () => {
  return <MobileHome />;
};

export default HomeContainer;
