import { db } from '../firebase';
import moment from 'moment';
import axios from 'axios';

// Menu
export function* getMenu() {
  try {
    const menuItems = yield db
      .collection('menu')
      .doc('data')
      .collection('items')
      .get();

    const menuData = menuItems.docs.map((obj) => obj.data());
    return menuData;
  } catch (error) {
    throw new Error('Could not fetch menu.', error);
  }
}

// Calendar
export function* getCalendar() {
  try {
    // Now
    const timeStamp = moment();

    // Reponse
    const response = yield axios({
      method: 'POST',
      url:
        'https://us-central1-lumos-webapp-mongo.cloudfunctions.net/fetchCalendar2',
      data: {
        timeStamp
      }
    });

    // If error with payment, put FAILED and error message.

    if (response.data.hasError) {
      if (response.data.error.type === 'StripeCardError') {
        throw new Error('There was an issue with the payment method.');
      } else if (response.data.error.type === 'api_connection_error') {
        throw new Error('There was an issue trying to access network.');
      } else if (response.data.error.type === 'validation_error') {
        throw new Error('There was a problem validating the payment provided.');
      } else {
        throw new Error(response.data.error);
      }
    }
  } catch (error) {
    throw new Error('Could not fetch menu.', error);
  }
}

// My Orders
export function* getOrders(uid) {
  try {
    const orders = yield db
      .collection('orders')
      .where('userUid', '==', uid)
      .get();

    const ordersData = orders.docs.map((obj) => obj.data());
    return ordersData;
  } catch (error) {
    throw new Error('Could not fetch orders.', error);
  }
}
