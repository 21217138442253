import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  labelContainer: {
    padding: '5px 0px'
  },
  label: {
    color: 'black',
    fontSize: 17,
    fontWeight: '600',
    fontFamily: 'Source Sans Pro'
  },
  labelSub: {
    color: 'grey',
    fontSize: 15,
    fontFamily: 'Source Sans Pro'
  },
  formControlRadio: {
    fontSize: 15,
    fontFamily: 'Source Sans Pro',
    padding: '5px 0px'
  },
  errorSub: {
    color: 'red',
    fontSize: 15,
    fontFamily: 'Source Sans Pro'
  }
}));

const radioStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#F34213',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#F34213'
    }
  }
});

function StyledRadio(props) {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const SingleOptionRadio = ({ onChange, isRequired, label, options, error }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const inputRef = React.createRef(null);
  const handleInputChange = (value) => {
    setValue(value);
    // Set into array for future purporses.. CHANGE?
    onChange([value]);
  };

  // Focus on form due to error.
  // useEffect(() => {
  //   if (error) {
  //     inputRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start'
  //     });
  //   }
  // }, [error, inputRef]);

  return (
    <Grid container direction='column' ref={inputRef}>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={classes.labelContainer}
      >
        <Typography className={classes.label}>{label}</Typography>
        {error ? (
          <Typography className={classes.errorSub}>Select an option</Typography>
        ) : (
          <Typography className={classes.labelSub}>
            {isRequired ? 'Required' : 'Optional'}
          </Typography>
        )}
      </Grid>
      <Grid container direction='row' justify='flex-start' alignItems='center'>
        <RadioGroup name='selectOption' value={value} style={{ width: '100%' }}>
          {options.map((option) => {
            return (
              <Grid
                item
                xs={12}
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                style={{ borderBottom: '1px solid #E7E7E7', cursor: 'pointer' }}
              >
                <FormControlLabel
                  className={classes.formControlRadio}
                  value={option.name}
                  control={
                    <StyledRadio onClick={() => handleInputChange(option)} />
                  }
                  label={option.name}
                  disabled={option.disabled}
                  checked={option.name === value.name}
                />
                <Typography className={classes.labelSub}>
                  ${option.priceMonetaryFields.displayString}
                </Typography>
              </Grid>
            );
          })}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default SingleOptionRadio;
