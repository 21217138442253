import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  labelContainer: {
    padding: '5px 0px'
  },
  label: {
    color: 'black',
    fontSize: 17,
    fontWeight: '600',
    fontFamily: 'Source Sans Pro'
  },
  labelSub: {
    color: 'grey',
    fontSize: 15,
    fontFamily: 'Source Sans Pro'
  },
  formControlCheckbox: {
    fontSize: 15,
    fontFamily: 'Source Sans Pro',
    padding: '5px 0px'
  }
}));

const checkboxStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#F34213',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#F34213'
    }
  }
});

function StyledCheckbox(props) {
  const classes = checkboxStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const MultiSelect = ({ onChange, isRequired, label, options, maxValue }) => {
  const classes = useStyles();

  const [checked, setChecked] = useState({});

  const handleInputChange = (value) => {
    if (Object.keys(checked).includes(value.name)) {
      // If key already in checked. Delete it
      const updatedChecked = { ...checked };
      delete updatedChecked[value.name];

      // Convert form into array of objects.
      const optionsKeys = Object.keys(updatedChecked);
      const optionsArray = optionsKeys.map((key) => {
        return {
          name: key,
          ...updatedChecked[key]
        };
      });
      onChange(optionsArray);

      return setChecked(updatedChecked);
    }

    if (Object.keys(checked).length >= maxValue) {
      return;
    }

    const updatedChecked = { ...checked };
    updatedChecked[value.name] = value;

    setChecked(updatedChecked);

    // Convert form into array of objects.
    const optionsKeys = Object.keys(updatedChecked);
    const optionsArray = optionsKeys.map((key) => {
      return {
        name: key,
        ...updatedChecked[key]
      };
    });

    onChange(optionsArray);
  };

  return (
    <Grid container direction='column'>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={classes.labelContainer}
      >
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.labelSub}>
          {isRequired ? `Required` : `Optional`}
        </Typography>
      </Grid>
      <Grid container direction='row' justify='flex-start' alignItems='center'>
        {options.map((option) => {
          return (
            <Grid
              item
              xs={12}
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              style={{ borderBottom: '1px solid #E7E7E7' }}
            >
              <FormControlLabel
                className={classes.formControlCheckbox}
                value={option.name}
                control={
                  <StyledCheckbox onClick={() => handleInputChange(option)} />
                }
                label={option.name}
                disabled={option.disabled}
                checked={Object.keys(checked).includes(option.name)}
              />
              <Typography className={classes.labelSub}>
                ${option.priceMonetaryFields.displayString}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        direction='row'
        justify='flex-end'
        alignItems='center'
        className={classes.labelContainer}
      >
        <Typography
          className={classes.labelSub}
          style={{ fontStyle: 'italic' }}
        >
          {isRequired ? `Select ${maxValue}` : `Select up to ${maxValue}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MultiSelect;
