import moment from 'moment';

export default function getIsStoreOpen(schedule) {
  /* 
    0-6 Moment days
    0 - Sunday
    6 - Saturday

    All times are exported in h:mmA format (12 HOUR FORMAT)
  */

  if (!schedule) {
    return false;
  }

  //   Current time
  const currentTime = moment();

  //   Current day in moment number.
  const currentDay = moment().day();

  //   Current date in moment number.
  const currentDate = moment().date();

  //   First call time for restuarant. 30 Minutes before
  // TODO: Change this to only be able to order after we are open
  const openingTime = moment(
    `${schedule[currentDay].open},${currentDate}`,
    'h:mmA,DD'
  );

  //   Last Call time for restuarant. 15 Minutes before close
  const closingTime = moment(
    `${schedule[currentDay].close},${currentDate}`,
    'h:mmA,DD'
  ).subtract(30, 'minutes');

  const isBefore = moment(currentTime).isBefore(closingTime);

  const isAfter = moment(currentTime).isAfter(openingTime);

  const isOpen = isBefore && isAfter;

  return isOpen;
}
