import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: 2,
    cursor: 'pointer',
    borderBottom: 'solid 1px rgb(43, 43, 43)'
  },
  title: {
    color: 'white',
    fontFamily: 'Inconsolata',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 25
    }
  },
  price: {
    fontFamily: 'Inconsolata',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#F4C347',
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 25
    }
  },
  description: {
    fontFamily: 'Inconsolata',
    fontSize: 16,
    color: 'grey',
    [theme.breakpoints.up('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 23
    }
  },
  cardContent: {
    padding: 15
    // minHeight: 100
  }
}));

const CardItem = ({ menuItem }) => {
  const classes = useStyles();

  const { item, isChoice } = menuItem;

  const { description, priceMonetaryFields, name } = item;

  return (
    <Grid item container className={classes.root}>
      <Grid
        item
        container
        direction='column'
        justify='space-between'
        className={classes.cardContent}
      >
        <Grid item container direction='column' justify='space-between'>
          <Grid item container direction='row' justify='space-between'>
            <Typography className={classes.title}>{name}</Typography>
            {priceMonetaryFields && !isChoice && (
              <Typography className={classes.price}>
                ${priceMonetaryFields.displayString}
              </Typography>
            )}
          </Grid>
          {description && (
            <Typography className={classes.description}>
              {description}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardItem;
