import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import MongoLogo from "../../../assets/img/mongologo.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  root: {
    height: 75,
    background: "linear-gradient(to top, #f2f7f7 0%, #eeeeee 100%);",
    boxShadow: "0 3px 20px rgba(0,0,0,0.1)",
  },
  logo: {
    height: 250,
    "@media (max-width:1000px)": {
      height: 200,
    },
    "@media (max-width:768px)": {
      height: 175,
    },
    "@media (max-width:600px)": {
      height: 100,
    },
  },
  navLink: {
    flex: 1,
    textAlign: "center",
    height: 35,
    fontFamily: "Alata",
    fontWeight: "bold",
    fontSize: 20,
    textDecoration: "none",
    color: "black",
    "@media (max-width:1000px)": {
      fontSize: 17,
    },
    "@media (max-width:768px)": {
      fontSize: 15,
    },
    "@media (max-width:600px)": {
      fontSize: 14,
    },
    "@media (max-width:350px)": {
      fontSize: 13,
    },
  },
  orderButton: {
    paddingRight: 16,
    color: "black",
    backgroundColor: "#F4C346",
    fontWeight: 600,
    fontSize: 22,
    fontFamily: "Alata",
    "&:hover": {
      backgroundColor: "#D9B048",
    },
    "@media (max-width:1000px)": {
      fontSize: 18,
    },
    "@media (max-width:768px)": {
      fontSize: 15,
    },
    "@media (max-width:600px)": {
      fontSize: 14,
    },
  },
  orderLink: {
    textDecoration: "none",
    margin: "0px 5px",
  },
});

const HomeNav = (props) => {
  const classes = useStyles();
  const mobileDevice = useMediaQuery("(max-width:600px)");
  const { navItems } = props;

  if (mobileDevice) {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ padding: "15px 15px" }}
        >
          <Grid
            item
            xs
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <img
              src={MongoLogo}
              className={classes.logo}
              alt="Sapporo Houston"
            />
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <a
              href="https://order.toasttab.com/online/mongopearland"
              className={classes.orderLink}
            >
              <Button
                variant="contained"
                size="medium"
                className={classes.orderButton}
              >
                ORDER ONLINE
              </Button>
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {navItems.map((navItem) => (
            <Grid item xs className={classes.navLinkRoot}>
              <Link
                key={navItem.to}
                to={navItem.to}
                className={classes.navLink}
              >
                <p>{navItem.name}</p>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      xs={12}
      xl={8}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ padding: "15px 15px" }}
      >
        <Grid
          item
          xs={3}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <img src={MongoLogo} className={classes.logo} alt="Sapporo Houston" />
        </Grid>
        {navItems.map((navItem) => (
          <Grid item xs className={classes.navLinkRoot}>
            <Link
              id="nav-item"
              key={navItem.to}
              to={navItem.to}
              className={classes.navLink}
            >
              <p>{navItem.name}</p>
            </Link>
          </Grid>
        ))}
        <Grid
          item
          xs={3}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <a
            href="https://order.toasttab.com/online/mongopearland"
            className={classes.orderLink}
          >
            <Button
              variant="contained"
              size="medium"
              className={classes.orderButton}
            >
              ORDER ONLINE
            </Button>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeNav;
