export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const RESET_ORDER_STATE_TO_INITIAL_STATE =
  'RESET_ORDER_STATE_TO_INITIAL_STATE';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const HANDLE_MODIFIED_PRODUCTS = 'HANDLE_MODIFIED_PRODUCTS';
export const RESET_PRODUCT_INFO = 'RESET_PRODUCT_INFO';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_CART_DRAWER = 'TOGGLE_CART_DRAWER';
export const SET_CART = 'SET_CART';

// INIT CHECKOUT
export const INIT_CHECKOUT = 'INIT_CHECKOUT';
export const INIT_CHECKOUT_FAILED = 'INIT_CHECKOUT_FAILED';
export const CANCEL_INIT_CHECKOUT = 'CANCEL_INIT_CHECKOUT';
