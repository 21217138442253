import React from 'react';
import { makeStyles } from '@material-ui/styles';

// Component Imports
import Home from './Home';

const useStyles = makeStyles({
  root: { height: '100%', backgroundColor: 'black' }
});

const Wrapper = ({ ...other }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Home />
    </div>
  );
};

export default Wrapper;
