import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slide, useScrollTrigger } from '@material-ui/core';
import Nav from '../../components/global/Nav';

// Context
import Context from './context';

// UI Components
import MenuSection from '../../components/menu/MenuSection';
import MenuItems from '../../components/menu/MenuItems';

// Utilities
import getIsMenuActive from '../../utilities/getIsMenuActive';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000500',
    height: '100vh'
  },
  rootContainer: {
    marginBottom: 25
  },
  activeClassName: {
    color: 'blue',
    fontSize: 25
  },
  logo: {
    height: 65,
    width: 65,
    backgroundColor: 'red'
  },
  sectionRoot: {
    width: '100%',
    paddingTop: 85,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50
    }
  }
}));

// Hide on scroll
const HideOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

const Menu = (props) => {
  // Styles
  const classes = useStyles();

  // Scroll To Top on Mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Props
  const { menu, calendar, storeHours } = useContext(Context);

  // Calendar Data
  const { dailySchedule } = calendar;

  // Store Hours
  const { weeklySchedule } = storeHours;

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <div style={{ position: 'sticky', top: 0 }}>
          <Nav
            navItems={[
              { to: '/menu', name: 'MENU' },
              { to: '/happyhour', name: 'HAPPY HOUR' },
              { to: '/gallery', name: 'GALLERY' },
              { to: '/about', name: 'ABOUT' }
            ]}
          />
        </div>
      </HideOnScroll>
      <Grid container direction='row' justify='center'>
        <Grid
          container
          direction='row'
          xs={12}
          sm={12}
          md={10}
          lg={8}
          className={classes.rootContainer}
        >
          {dailySchedule &&
            dailySchedule.map((menuObj) => {
              // Desconstruct dailySchedule
              const {
                name: menuName,
                categories,
                timeFrame,
                isAllDay
              } = menuObj;

              const { isActive } = getIsMenuActive(
                timeFrame,
                weeklySchedule,
                isAllDay
              );

              if (!isActive) {
                return null;
              }

              return categories.map((categoryObj) => {
                // Desconstruct category
                const {
                  value: categoryValue,
                  name: categoryName,
                  description: categoryDescription
                } = categoryObj;

                // Filter menu items based on category
                const items =
                  menu &&
                  menu.filter((obj) => obj.type.value === categoryValue);

                // Sorting items by price.
                const sortedItems = items.sort((a, b) => {
                  return (
                    a.item.priceMonetaryFields.unitAmount -
                    b.item.priceMonetaryFields.unitAmount
                  );
                });

                return (
                  <section id={categoryValue} className={classes.sectionRoot}>
                    <MenuSection
                      key={categoryValue}
                      categoryName={categoryName}
                      menuName={menuName}
                      categoryDescription={categoryDescription}
                    >
                      <MenuItems items={sortedItems} />
                    </MenuSection>
                  </section>
                );
              });
            })}
        </Grid>
      </Grid>
      {/* <div className={classes.root}>
        {sampleItems.map((obj) => {
          return <MenuItem menuItem={obj.menuItem} />;
        })}
      </div> */}
    </React.Fragment>
  );
};

export default Menu;
