import { createSelector } from 'reselect';

const authState = (state) => state.auth;

export const getStoreData = createSelector(
  authState,
  (state) => state.storeData
);

export const getIsSignedIn = createSelector(
  authState,
  (state) => state.isSignedIn
);

export const getUser = createSelector(authState, (state) => state.user);
